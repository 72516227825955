import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    language: '',
    category: '',
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        changeLanguage: (state, action) => {
            state.language = action.payload;
        },
        changeCategoryState: (state, action) => {
            state.category = action.payload;
        },
        resetLanguage: (state) => {
            state.language = '';
        },
        resetCategoryState: (state) => {
            state.category = '';
        },
    },
});

// Action creators are generated for each case reducer function
export const { resetLanguage, changeLanguage, changeCategoryState, resetCategoryState } =
    appSlice.actions;

export const getLanguage = (state) => state.app.language;
export const getCategoryState = (state) => state.app.category;

export default appSlice.reducer;
