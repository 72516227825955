import React, { Suspense, useEffect, useState } from 'react';
import Drawer from '../Mobile/DrawerAdmin';
import AdminHeader from '../administrator/HeaderAdmin';
import FooterLearn from '../Helper/FooterLearn';
import AdministratorLogin from '../administrator/pages/Login';
import BackToTop from '../BackToTop';
import useToggle from '../../Hooks/useToggle';
import { BASEAPIURL } from '../../config';
import axios from 'axios';
import AdminProfileMenu from '../administrator/pages/Dashboard/ProfileMenu';

const AdminLayout = ({ children }) => {
    const [IsLoaded, setIsLoaded] = useState(false);
    const [IsLogin, setIsLogin] = useState(false);
    const noPermission =
        "<h3 class='alert text-center pt-5 pb-5 alert-danger'>You don't have permission for this Page.</h3>";
    const [drawer, drawerAction] = useToggle(false);

    const checkLogin = async () => {
        try {
            const res = await axios(`${BASEAPIURL}/admin/account/CheckLogin`, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json', // multipart/form-dat
                    'ry-token1': localStorage.getItem('aid'),
                    'jwt-token': localStorage.getItem('token'),
                    'ry-token2': localStorage.getItem('usertype'),
                },
                body: JSON.stringify({ token: localStorage.getItem('token') }),
            });
            const resJson = res.data;
            setIsLoaded(true);
            if (res.status === 200) {
                if (resJson.status === 1) {
                    setIsLogin(true);
                    if (resJson.data) {
                        localStorage.setItem('amissionper', resJson.data.permission);
                        setTimeout(() => {
                            //   setAMissionPer(resJson.data.permission);
                        }, 786);
                    }
                }
            }
        } catch (err) {
            setIsLoaded(true);
            console.error(err);
            setIsLogin(false);
        }
    };

    useEffect(() => {
        setIsLogin(false);
        checkLogin();
        // checked
        localStorage.setItem('RYSJAIN', Date());
        const interval = setInterval(() => {
            checkLogin();
        }, 60000);
        return () => clearInterval(interval);
    }, []);
    return (
        <div style={{ display: IsLoaded ? '' : 'none' }}>
            {IsLogin ? (
                <>
                    <Drawer drawer={drawer} action={drawerAction.toggle} />
                    <AdminHeader drawer={drawer} action={drawerAction.toggle} />

                    <div className="container-fluid pt-4 pb-4">
                        <div className="row">
                            <div className="hideonmobile col-md-3">
                                <Suspense fallback={<div>Loading...</div>}>
                                    <AdminProfileMenu
                                        isDrawer={0}
                                        // forpage={PageName}
                                    />
                                </Suspense>
                            </div>
                            <div className="col-md-9">{children}</div>
                        </div>
                    </div>
                    <FooterLearn className="appie-footer-about-area" />
                    <BackToTop />
                </>
            ) : (
                <AdministratorLogin />
            )}
        </div>
    );
};

export default AdminLayout;
