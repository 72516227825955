import React from 'react';
import { Link } from 'react-router-dom';
import { IMGBASEAPIURL } from '../../config';

function TrainingBox() {
    return (
        <>
            #
            {Number(localStorage.getItem('screenwidth')) > 768
                ? `url(${IMGBASEAPIURL}institute/bg/training-bg.jpg)`
                : '#f5f5f5'}
            #
            <section
                className="appie-training-area"
                style={{
                    background:
                        Number(localStorage.getItem('screenwidth')) > 768
                            ? `url(${IMGBASEAPIURL}institute/bg/training-bg.jpg)`
                            : '#f5f5f5',
                }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="appie-training-content">
                                <h3>Need Customized Training for your Team?</h3>
                                <h4>Train your team together at discounted rates</h4>
                                <Link to="/request-as-team" className="main-btn">
                                    Request Team Learning
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default TrainingBox;
