import React, { Suspense, useEffect, useState } from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/DrawerInstitute';
import Header from '../Helper/HeaderInstitute';
import Footer from '../Helper/FooterInstitute';
// import AllCoursesMain from './AllCoursesMain';
import BigBannerBox from '../Institute/BigBannerBox2';
import CalendarBox from '../Institute/CalendarBox';
// import PopularCoursesBox from '../PopularCoursesBox';

import { useParams } from 'react-router-dom';
import { BASEAPIURL, BASEURL } from '../../config';

const AllCoursesMain = React.lazy(() => import('../Learn/AllCourses/AllCoursesMain'));
const PopularCoursesBox = React.lazy(() => import('../Institute/PopularCoursesBox'));
const AllCourseBanner = React.lazy(() => import('../Learn/AllCourses/AllCourseBanner'));

function AllCourses() {
    const [Loaded, setLoaded] = useState(false);
    const [CategaryData, setCategaryData] = useState([]);
    const { slug } = useParams();
    const queryParameters = new URLSearchParams(window.location.search);
    const [drawer, drawerAction] = useToggle(false);

    const GetCategoryData = async (slug) => {
        try {
            const res = await fetch(`${BASEAPIURL}/admin/coursecategory/getCatiDataSlug`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ slug }),
            });
            const resJson = await res.json();
            setLoaded(true);
            if (res.status === 200) {
                if (resJson.data && resJson.data.length > 0) {
                    setCategaryData(resJson.data[0]);
                }
            } else {
                console.log(resJson.message);
            }
        } catch (err) {
            setLoaded(true);
            console.log(err);
        }
    };

    useEffect(() => {
        setLoaded(false);
        // const queryParameters2 = new URLSearchParams(window.location.search);
        // setGetCategory(queryParameters2.get('category'));
        // setGetSubCategory(queryParameters2.get('subcategory'));
        GetCategoryData(slug);
    }, [slug]);

    return (
        <>
            {/* {console.error(CategaryData)} */}
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            {!Loaded && (
                <div className="ShowLoaderPage">
                    <div className="LoadingAnimaCourse">
                        <img src={`${BASEURL}assets/courses/CourseLoader.png`} alt="" />
                        <br />
                        <b>Just a moment</b>
                        <p>Getting the information...</p>
                    </div>
                </div>
            )}
            <Suspense fallback={<div>Loading...</div>}>
                <>
                    {Loaded && (
                        <AllCourseBanner
                            MainCatID={CategaryData.maincatid}
                            PassCategory={CategaryData.catid}
                            PassSubCategory={queryParameters.get('subcategory')}
                        />
                    )}
                </>
            </Suspense>
            {/* <AllCoursesMain className="pt-70" /> */}
            <Suspense fallback={<div>Loading...</div>}>
                <div className="">
                    {Loaded && CategaryData.catid && (
                        <AllCoursesMain
                            hideCategoryFilter={true}
                            MainCatID={CategaryData.maincatid}
                            PassCategory={CategaryData.catid}
                            PassSubCategory={queryParameters.get('subcategory')}
                            className="pt-3"
                        />
                    )}
                </div>
            </Suspense>
            <BigBannerBox />
            <CalendarBox />
            <Suspense fallback={<div>Loading...</div>}>
                {Loaded && <PopularCoursesBox MainCatID={CategaryData.maincatid} />}
            </Suspense>
            <Footer />
            <BackToTop />
        </>
    );
}

export default AllCourses;
