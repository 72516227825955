import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { BASEAPIURL, showdatetimein } from '../../../../../config';
import Pagination from '../MyBlogPagination';
import CreateForm from '../CreateBlog';
import ViewOrder from './view';

let SortStatus = '';

function ListOrders({ forpage }) {
    // for pagination
    const [ForMainID, setForMainID] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);
    const [TotalRecords, setTotalRecords] = useState(5);
    const nPages = Math.ceil(TotalRecords / recordsPerPage);
    // for pagination
    const [Blogs, setBlogs] = useState([]);
    const [orders, setOrders] = useState([]);
    const [Keyword, setKeyword] = useState('');
    const [SortBy, setSortBy] = useState('');
    const [selectedOrder, setSelectedOrder] = useState(null);

    // const [drawer, drawerAction] = useToggle(false);
    // const [lfVisibility, setlfVisibility] = useState('block');

    const viewOrder = (id) => {
        setSelectedOrder(id);
    };

    const getOrders = async () => {
        try {
            const res = await fetch(`${BASEAPIURL}/orders`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    recordsPerPage: 10,
                    SortStatus,
                }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                setOrders(resJson.data);
                setTotalRecords(resJson.count);
            } else {
                console.log(resJson.message);
            }
        } catch (err) {
            console.log(err);
        }
    };
    console.log(orders, 'List of orders!');
    const getFilteredOrders = async (e) => {
        console.log(e);
        try {
            const res = await fetch(`${BASEAPIURL}/orders`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    sortby: SortBy,
                    PageNumber: e.selected + 1,
                    recordsPerPage,
                    SortStatus,
                }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                setOrders(resJson.data);
                setTotalRecords(resJson.count);
            } else {
                console.log(resJson.message);
            }
        } catch (err) {
            console.log(err);
        }
        return false;
    };
    const FetchFilterBlogs = async (e, keywordhere, sortbyhere, PageNumber) => {
        // Keyword SortBy
        e.preventDefault();
        try {
            const res = await fetch(`${BASEAPIURL}/admin/blog/getAll`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    sortby: sortbyhere,
                    PageNumber,
                    recordsPerPage,
                    SortStatus,
                }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                setBlogs(resJson.data);
                setTotalRecords(resJson.count);
            } else {
                console.log(resJson.message);
            }
        } catch (err) {
            console.log(err);
        }
        return false;
    };
    useEffect(() => {
        getOrders();
        SortStatus = '';
        setSelectedOrder(null);
        // document.getElementById('blog_filter_frm').submit();
    }, []);

    return (
        <>
            {selectedOrder ? (
                <ViewOrder id={selectedOrder} goBack={viewOrder} />
            ) : (
                <div
                    className="ry_instr_shadow p-4"
                    forpage={forpage}
                    style={{ display: ForMainID !== 0 ? 'none' : '' }}
                >
                    <h4>
                        Orders
                        {/* <div style={{ float: 'right' }}>
            <Link
              className="btn btn-docsta-instructor"
              to="/create-blog"
              target="_CREATE"
              onClick={() => {
                setForMainID(0);
                localStorage.setItem('edit_blog_id', 0);
              }}
              style={{ borderRadius: '10px' }}
            >
              Add New
            </Link>
          </div> */}
                        <div style={{ clear: 'both' }}></div>
                    </h4>
                    <form id="blog_filter_frm" className="row mt-3">
                        <div className="col-md-4">
                            <input
                                name="keyword"
                                type="text"
                                className="form-control"
                                placeholder="Search Anything"
                                value={Keyword}
                                onChange={(e) => {
                                    setKeyword(e.target.value);
                                    FetchFilterBlogs(e, e.target.value, SortBy, 1);
                                }}
                            />
                        </div>
                        <div className="col-md-4">
                            <select
                                name="SortStatus"
                                defaultValue={SortStatus}
                                className="form-control"
                                onChangeCapture={(e) => {
                                    SortStatus = e.target.value;
                                    FetchFilterBlogs(e, Keyword, SortBy, 1);
                                }}
                            >
                                <option value="">All</option>
                                <option value="1">Active Only</option>
                                <option value="2">On Review</option>
                                <option value="0">Inactive Only</option>
                            </select>
                        </div>
                        <div className="col-md-4">
                            <select
                                name="sortby"
                                defaultValue={SortBy}
                                className="form-control"
                                onChangeCapture={(e) => {
                                    setSortBy(e.target.value);
                                    FetchFilterBlogs(e, Keyword, e.target.value, 1);
                                }}
                            >
                                <option value="0">Sort By</option>
                                <option value="a2z">Name A2Z</option>
                                <option value="z2a">Name Z2A</option>
                                <option value="latest">Latest</option>
                                <option value="old">Oldest</option>
                            </select>
                        </div>
                    </form>
                    <div className="mt-3 table-responsive">
                        <table className="table table-hover table-borddered table-stripped">
                            <thead className="ry-table-header">
                                <tr>
                                    <th>#</th>
                                    <th>Processed Amount</th>
                                    <th>Status</th>
                                    <th>Created on</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orders.map((order, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>
                                            <div style={{ display: 'inline-block' }} className="">
                                                <b>
                                                    {order.paymentCurrency == 'INR' ? '₹' : '$'}{' '}
                                                    {order.ProcessAmount}
                                                </b>
                                                <br />
                                                <small style={{ fontSize: '70%' }}></small>
                                            </div>
                                        </td>
                                        <td>{order.status}</td>
                                        <td>{showdatetimein(order.createdAt)}</td>
                                        <td>
                                            <button
                                                onClick={() => viewOrder(order._id)}
                                                type="button"
                                                className="btn"
                                            >
                                                <i className="fa fa-eye"></i>
                                            </button>
                                            <div style={{ float: 'right' }}></div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            {/* <tbody>
              {Blogs.map((singi) => (
                <tr key={singi.blogid}>
                  <td>{singi.blogid}</td>
                  <td>
                    <div
                      style={{ display: 'inline-block' }}
                      className=""
                    >
                      <b>{singi.title}</b>
                      <br />
                      <small style={{ fontSize: '70%' }}></small>
                    </div>
                  </td>
                  <td>
                    <select
                      name="status"
                      className={`form-control text-white ${
                        singi.status === 1 ? 'bg-success' : ''
                      } ${singi.status === 0 ? 'bg-danger' : ''} ${
                        singi.status === 2 ? 'bg-warning' : ''
                      }`}
                      value={singi.status}
                      onChangeCapture={(e) => {
                        UpdateStatus(e, singi.blogid, e.target.value);
                      }}
                    >
                      <option
                        value="1"
                        className="text-success"
                      >
                        Active
                      </option>
                      <option
                        value="2"
                        className="text-warning"
                      >
                        On Review
                      </option>
                      <option
                        value="0"
                        className="text-danger"
                      >
                        Inactive
                      </option>
                    </select>
                  </td>
                  <td>{showdatetimein(singi.createdAt)}</td>
                  <td>
                    {showdatetimein(singi.updatedAt)}
                    <Link
                      to="/create-blog"
                      target="_CREATE"
                      onClick={() => {
                        setForMainID(singi.blogid);
                        setForMainID(0);
                        localStorage.setItem('edit_blog_id', singi.blogid);
                      }}
                      type="button"
                      className="btn"
                      style={{ float: 'right' }}
                    >
                      <i className="fa fa-edit"></i>
                    </Link>
                    <div style={{ float: 'right' }}></div>
                  </td>
                </tr>
              ))}
            </tbody> */}
                        </table>
                    </div>
                    <ReactPaginate
                        containerClassName="d-flex justify-content-center w-100 align-items-center"
                        pageClassName="p-2 px-3"
                        breakLabel="..."
                        nextLabel={<i class="fa fa-arrow-right px-5"></i>}
                        onPageChange={getFilteredOrders}
                        pageRangeDisplayed={3}
                        pageCount={nPages}
                        previousLabel={<i class="fa fa-arrow-left px-5"></i>}
                        renderOnZeroPageCount={null}
                        activeClassName="bg-primary text-white rounded"
                        breakClassName="p-2 px-3"
                    />
                    {/* <small className="text-center d-block mb-2">
          Showing Page {currentPage} of Total page {nPages} from {TotalRecords}{' '}
          Records
        </small> */}
                    {/* <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          FetchFilterBlogs={FetchFilterBlogs}
          SortBy={SortBy}
          Keyword={Keyword}
        /> */}
                </div>
            )}
        </>
    );
}

export default ListOrders;
