import React, { useRef, useState, useEffect } from 'react';
import Slider from 'react-slick';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { BASEURL } from '../../config';
import DataService from '../../lib/AxiosInstance';
import { getCategoryState, getLanguage } from '../../reduxFunction/appSlice';
import { useSelector } from 'react-redux';

const HomeCourseSlider = React.lazy(() => import('../Learn/HomeCourseSlider'));

const settings = {
    infinite: false, // Disable infinite looping
    autoplay: false,
    arrows: true,
    dots: false,
    slidesToShow: 5,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 3,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
            },
        },
    ],
};

// for next and pre
let PageNumber = 1;
let catID = 0;
let recordsPerPage = 10;
if (localStorage.getItem('screenwidth') > 767) {
    recordsPerPage = 20;
}
// for next and pre

function HomeSliderSkillsCourse() {
    const language = useSelector(getLanguage);
    const categoryState = useSelector(getCategoryState);
    const [IsLoaded, setIsLoaded] = useState(false);
    const [categories, setCategories] = useState([]);
    const [Sliders, setSliders] = useState([]);
    const sliderRef = useRef();
    const fetchCatiData = async () => {
        try {
            const payload = JSON.stringify({
                maincatid: 'skill',
                recordsPerPage: 50,
                sortby: 'a2z',
                SortStatus: 1,
            });
            const res = await DataService.post(`/admin/coursecategory/getAll`, payload);
            if (res.status === 200) {
                // alert(resJson.data.length);
                if (Object.keys(res.data.data).length > 0) {
                    setCategories(res.data.data);
                }
            } else {
                console.log(res.data.message);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const fetchCourseData = async () => {
        try {
            const payload = JSON.stringify({
                MainCatID: 'skill',
                category: categoryState !== '' ? categoryState : catID,
                recordsPerPage,
                status: 1,
                PageNumber,
                sortby: 'popular',
                rahul: 'Yadav',
                language,
            });
            const res = await DataService.post(`/course/getAll`, payload);
            setIsLoaded(true);
            if (res.status === 200) {
                if (Object.keys(res.data.data).length > 0) {
                    setSliders(res.data.data);
                } else {
                    setSliders([]);
                }
            } else {
                console.log(res.data.message);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchCatiData();
        fetchCourseData();
    }, []);

    useEffect(() => {
        fetchCatiData();
        fetchCourseData();
    }, [language, categoryState]);

    return (
        <>
            <section className="appie-hero-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-left">
                                <h3 className="appie-title mb-3 mt-0">Highest Rated Courses</h3>
                                <p className="mb-2 d-none">
                                    {Number(localStorage.getItem('screenwidth')) > 550 ? (
                                        <>Magnify your skills with our most demanded courses</>
                                    ) : (
                                        ''
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3 d-block d-sm-none">
                        <div className="col-lg-12">
                            <div className="form-group">
                                <select
                                    className="form-control"
                                    onChangeCapture={(e) => {
                                        catID = e.target.value;
                                        PageNumber = 1;
                                        fetchCourseData();
                                    }}
                                >
                                    <option value="0">Select Category</option>
                                    {categories.map((category) => (
                                        <option value={category.catid} key={category.catid}>
                                            {category.title}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3 d-none d-sm-block FeaturedCategoriesSlider">
                        <div className="col-lg-12">
                            <div className="appie-hero-thumb HomeCourseCategorySlider">
                                <Slider
                                    key="category"
                                    className="pl-3 pr-3"
                                    ref={sliderRef}
                                    {...settings}
                                >
                                    {categories.map((category) => (
                                        <div key={category.catid}>
                                            <span
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    if (catID === category.catid) {
                                                        catID = 0;
                                                    } else {
                                                        catID = category.catid;
                                                    }
                                                    PageNumber = 1;
                                                    fetchCourseData();
                                                }}
                                                className={catID === category.catid ? 'active' : ''}
                                            >
                                                {category.title}
                                            </span>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: IsLoaded ? '' : 'none' }}>
                        <HomeCourseSlider
                            Sliders={Sliders}
                            key={Sliders.length}
                            ViewAllUrl={`${BASEURL}courses/skills-based-courses`}
                        />
                    </div>
                </div>
            </section>
        </>
    );
}

export default HomeSliderSkillsCourse;
