import React, { Suspense, useEffect } from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/DrawerInstitute';
import Header from '../Helper/HeaderInstitute';
import Footer from '../Helper/FooterInstitute';
// import AllCoursesMain from './AllCoursesMain';
import BigBannerBox from '../Institute/BigBannerBox2';
import CalendarBox from '../Institute/CalendarBox';
// import PopularCoursesBox from '../PopularCoursesBox';

const AllCoursesMain = React.lazy(() => import('../Learn/AllCourses/AllCoursesMain'));
const PopularCoursesBox = React.lazy(() => import('../Institute/PopularCoursesBox'));
const AllCourseBanner = React.lazy(() => import('../Learn/AllCourses/AllCourseBanner'));

function AllCourses() {
    const MainCatID = 'exam-oriented-courses';
    const queryParameters = new URLSearchParams(window.location.search);
    const [drawer, drawerAction] = useToggle(false);

    useEffect(() => {
        // const queryParameters2 = new URLSearchParams(window.location.search);
        // setGetCategory(queryParameters2.get('category'));
        // setGetSubCategory(queryParameters2.get('subcategory'));
    }, []);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <Suspense fallback={<div>Loading...</div>}>
                <AllCourseBanner
                    MainCatID={MainCatID}
                    GetCategory={queryParameters.get('category')}
                    GetSubCategory={queryParameters.get('subcategory')}
                />
            </Suspense>
            {/* <AllCoursesMain className="pt-70" /> */}
            <Suspense fallback={<div>Loading...</div>}>
                <div className="">
                    <AllCoursesMain MainCatID={MainCatID} className="pt-3" />
                </div>
            </Suspense>
            <BigBannerBox />
            <CalendarBox />
            <Suspense fallback={<div>Loading...</div>}>
                <PopularCoursesBox MainCatID={MainCatID} />
            </Suspense>
            <Footer />
            <BackToTop />
        </>
    );
}

export default AllCourses;
