// src/App.js

import React from 'react';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import PDF from './pdf';
import { FaDownload } from 'react-icons/fa6';

const CourseInvoice = ({ courses, location, subtotal }) => {
    console.log(courses, 'Course Details Link');
    return (
        <div className="d-flex justify-content-end">
            {/* <PDFViewer
        width="100"
        height="600"
      >
        <MyDocument />
      </PDFViewer> */}
            <PDFDownloadLink
                document={
                    <PDF
                        courses={courses}
                        subtotal={subtotal}
                        location={location ?? localStorage.getItem('country_name')}
                    />
                }
                fileName="invoice.pdf"
                className="d-flex align-items-center"
            >
                <FaDownload className="mr-2" />
                Download Invoice
            </PDFDownloadLink>
        </div>
    );
};

export default CourseInvoice;
