import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-number-input';
import { BASEAPIURL } from '../../config';
import './student.css';

const SocialLoginDetails = () => {
    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [city, setCity] = useState('');
    const [user, setUser] = useState(null);
    const closeModal = () => {
        setOpen(false);
    };
    const handleSubmit = async () => {
        if (mobile === '' || name === '') {
            toast.error('Name and Phone cannot be empty!');
            return;
        }
        try {
            const res = await fetch(`${BASEAPIURL}/student/update/social`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    email: user.email,
                    name: name,
                    mobile: mobile,
                    city: city,
                }),
            });
            const resJson = await res.json();
            if (resJson.status == 1) {
                let prevData = JSON.parse(localStorage.getItem('userdata'));
                const newData = { ...prevData, name: name, mobile: mobile };
                localStorage.setItem('userdata', JSON.stringify(newData));
                closeModal();
            }
            console.log(resJson);
        } catch (error) {
            toast.error('Hello');
            console.log(error);
        }
    };
    useEffect(() => {
        callApi();
    }, []);
    const callApi = async () => {
        const localStorageData = localStorage.getItem('userdata');
        const userData = JSON.parse(localStorageData);
        try {
            const res = await fetch(`${BASEAPIURL}/students/${userData.mine}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    email: userData.email,
                }),
            });
            const resJson = await res.json();
            console.log(resJson, 'Response Json Object');
            if (
                resJson?.data?.length > 0 &&
                (resJson?.data?.mobile == '' || resJson?.data?.name == '')
            ) {
                setOpen(true);
            }
            console.log(resJson, 'Api Response');
            setName(resJson?.data?.name);
            setMobile(resJson?.data?.mobile);
            setCity(resJson?.data?.city);
            setUser(resJson?.data);
        } catch (error) {
            console.log(error);
        }
    };
    console.log(user);
    return (
        <div className="position-absolute">
            {' '}
            {open && (
                <div>
                    <div className="student_sociallogin_overlay" onClick={closeModal}></div>
                    <div
                        className="student_sociallogin_popup d-flex flex-column"
                        style={{ gap: '0.3rem' }}
                    >
                        <h3>Please fill out the details</h3>
                        <label htmlFor="">
                            Name <span className="text-danger">*</span>
                        </label>
                        <input
                            className="form-control"
                            type="text"
                            name=""
                            onChange={(e) => setName(e.target.value)}
                            id=""
                            required
                            value={name}
                        />
                        <label htmlFor="">
                            Contact <span className="text-danger">*</span>
                        </label>
                        <PhoneInput
                            international
                            className="form-control"
                            name="phonenumber"
                            value={mobile}
                            onChange={setMobile}
                            required="required"
                            defaultCountry="AE"
                            style={{ position: 'relative' }}
                        />
                        <span className="text-danger">Phone cannot be empty</span>
                        <label htmlFor="">City</label>
                        <input
                            name=""
                            id=""
                            className="form-control"
                            onChange={(e) => setCity(e.target.value)}
                            value={city}
                        ></input>
                        <br />
                        <button className="btn primary" onClick={handleSubmit}>
                            Save
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SocialLoginDetails;
