import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MakeFavoriteStd, CheckFavoriteStd } from '../../Students/functions';

let loggedToken = localStorage.getItem('loggedToken');
let usertype = localStorage.getItem('usertype');
const PrimaryIndex = '_id';

const CourseListAudioTips = ({ s }) => {
    const [isFavoriteStd, setisFavoriteStd] = useState(false);

    useEffect(() => {
        loggedToken = localStorage.getItem('loggedToken');
        usertype = localStorage.getItem('usertype');
        const CheckFavorite = async (s) => {
            const temp = await CheckFavoriteStd(
                loggedToken,
                s[PrimaryIndex],
                'audioTips',
                usertype
            );
            if (temp === 1) {
                setisFavoriteStd(true);
            } else {
                setisFavoriteStd(false);
            }
        };
        CheckFavorite(s);
        // setAudioTipsURL(`/audioTips/${s.slug}`)
    }, [s]);

    return (
        <>
            {/* https://bbbootstrap.com/snippets/bootstrap-ecommerce-category-product-list-page-93685579 */}

            <div>
                {/* <Link className="buynow-btn" to={CourseURL}>
                            {Number(window.GetCurrencyValue(singi.cost, singi.costinr).cost) === 0
                                ? 'Enroll Nows'
                                : 'Buy Now'}
                        </Link> */}

                <i
                    className={`${
                        isFavoriteStd ? 'fas mt-2 text-danger fa-heart' : 'far mt-2 fa-heart'
                    }`}
                    onClick={() => {
                        setisFavoriteStd(!isFavoriteStd);
                        MakeFavoriteStd(
                            localStorage.getItem('loggedToken'),
                            s[PrimaryIndex],
                            'audioTips',
                            localStorage.getItem('usertype')
                        );
                    }}
                >
                    {isFavoriteStd ? '' : ''}
                </i>
            </div>
        </>
    );
};
CourseListAudioTips.propTypes = {
    UPLOADNODEURL: PropTypes.string.isRequired,
};

export default CourseListAudioTips;
