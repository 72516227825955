import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';
import { getToken } from 'firebase/messaging';
import { BASEAPIURL } from './config';
import axios from 'axios';

const firebaseConfig = {
    apiKey: 'AIzaSyBl8IpUqDlw2Db8pqP1YWZ4SoVduEhx8eo',
    authDomain: 'docsta-learn.firebaseapp.com',
    projectId: 'docsta-learn',
    storageBucket: 'docsta-learn.appspot.com',
    messagingSenderId: '58750645601',
    appId: '1:58750645601:web:06fd5ba5ecaf7ce639c120',
    measurementId: 'G-79TD69DK8L',
};

initializeApp(firebaseConfig);

export const askForPermissionToReceiveNotifications = () => {
    const messaging = getMessaging();
    let device = 'Website';
    return getToken(messaging, {
        vapidKey:
            'BITeRqjG5Uy73Zw0RrH8ePkcxFt8m9j6m7Tf_18XRrAN8J3cmH5IPAZ2Y3lufYWm7bovcAO7nRtVPYUB5R2mlDE',
    })
        .then((token) => {
            if (token) {
                const loggedToken = localStorage.getItem('loggedToken');
                if (loggedToken) {
                    try {
                        const url = `${BASEAPIURL}/student/SaveFirebase`;
                        const config = {
                            headers: {
                                'content-type': 'application/json', // multipart/form-data
                                Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
                            },
                        };
                        const DeviceDetails = localStorage.getItem('DeviceDetails');
                        if (DeviceDetails && DeviceDetails !== '') {
                            device = DeviceDetails;
                        }
                        axios.post(url, { token, device }, config).then((response) => {
                            console.log(response.data);
                        });
                    } catch (err) {
                        console.log(err);
                    }
                }
                // Perform any other neccessary action with the token
            } else {
                // Show permission request UI
                console.log('No registration token available. Request permission to generate one.');
            }
        })
        .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
        });
};
