import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    IMGBASEAPIURL,
    BASEAPIURL,
    BASEURL,
    ContactMobile,
    // ContactEmailID,
    ContactWhatsapp,
    // getCategoryURL,
} from '../../config';
import './FooterInstitute.css';

const today = new Date();
function Footer({ className }) {
    const [CatiData, setCatiData] = useState([]);

    const FetchCatiData = async () => {
        try {
            const res = await fetch(`${BASEAPIURL}/admin/coursecategory/getAll`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    SortStatus: 1,
                    parent: 0,
                    featured: 0,
                    recordsPerPage: 12,
                    sortby: 'a2z',
                }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                if (Object.keys(resJson.data).length > 0) {
                    setCatiData(resJson.data);
                }
            } else {
                console.log(resJson.message);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        FetchCatiData();
    }, []);

    return (
        <>
            <section className={`appie-footer-area ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="footer-about-widget">
                                <div className="logo">
                                    <Link to="/institute">
                                        <img
                                            src={`${IMGBASEAPIURL}logo/logo-learn-footer.png`}
                                            alt=""
                                        />
                                    </Link>
                                </div>
                                <h4 className="title mt-4">Social Media</h4>
                                <div className="social">
                                    <ul>
                                        <li>
                                            <a
                                                className="you"
                                                href="https://www.youtube.com/@docsta"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <i className="fab fa-youtube" />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="fac"
                                                href="https://www.facebook.com/docstalearn"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="lin"
                                                href="https://www.linkedin.com/company/docsta/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <i className="fab fa-linkedin-in" />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="inst"
                                                href="https://www.instagram.com/docstalearn/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <i className="fab fa-instagram" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <h4 className="title mt-4 d-none">Download Mobile App</h4>
                                <div className="social d-none">
                                    <ul>
                                        <li>
                                            <a
                                                className="you"
                                                href="https://play.google.com"
                                                target="_blank"
                                                title="Download Android App"
                                                rel="noopener noreferrer"
                                                style={{ lineHeight: '28px' }}
                                            >
                                                <img
                                                    src="https://play.google.com/favicon.ico"
                                                    alt=""
                                                    style={{ width: '19px' }}
                                                />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="you"
                                                href="https://apps.apple.com/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ lineHeight: '28px' }}
                                            >
                                                <img
                                                    src="https://www.apple.com/favicon.ico"
                                                    alt=""
                                                    style={{ width: '30px' }}
                                                />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-6 col-12">
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-6 footer-navigation">
                                    <ul>
                                        <li>
                                            <Link to="/courses/skills-based-courses">
                                                Skills Based Courses
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/instructor">Be our Instructor </Link>
                                        </li>
                                        <li>
                                            <Link to="/request-as-team">Learn as a Team</Link>
                                        </li>
                                        {/* <li>
                                            <Link to="/questionBanks">
                                                Question Banks
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/courses/exam-oriented-courses">
                                                Exam Oriented Courses
                                            </Link>
                                        </li> */}
                                        <li>
                                            <Link to="/blogs">Blogs</Link>
                                        </li>
                                        <li>
                                            <Link to="/mentors">Mentors</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-3 col-md-3 col-6 footer-navigation">
                                    <ul>
                                        <li>
                                            <Link to="/about-us">About Us</Link>
                                        </li>
                                        <li>
                                            <Link to="/faq">FAQ's</Link>
                                        </li>
                                        <li>
                                            <Link to="/contact-us">Help</Link>
                                        </li>
                                        <li>
                                            <Link to="/contact-us">Contact Us</Link>
                                        </li>
                                        {/* <li>
                                            <Link to="/contact-us">Careers</Link>
                                        </li> */}
                                        <li>
                                            <Link to="/gallery">Gallery</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-6 col-md-6 col-12">
                                    <div className="footer-navigation">
                                        <h4 className="title mt-30">Browse Category</h4>
                                        <div className="row">
                                            {CatiData.map((cti) => (
                                                <div
                                                    className="col-6"
                                                    key={cti.catid}
                                                    style={{
                                                        aaa: '8px',
                                                    }}
                                                >
                                                    <ul>
                                                        <li>
                                                            <Link
                                                                // to={getCategoryURL(cti.maincatid, cti.catid, cti.parent)}
                                                                to={`${BASEURL}category/${cti.slug}`}
                                                                className="p-s2"
                                                            >
                                                                {cti.title}
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* 
                        <div className="col-12 d-done">
                            <div className="hLine">
                                <hr />
                            </div>
                        </div> */}

                        <div className="col-lg-3 d-none col-md-6 col-12">
                            <div className="footer-about-widget">
                                <h4 className="title">Download Apps</h4>
                                <div className="row apps-download-btn">
                                    <div className="col-md-6">
                                        <ul>
                                            <li>
                                                <a
                                                    href="https://www.apple.com/in/search/docsta?src=globalnav"
                                                    target="_BLANK"
                                                    rel="noopener noreferrer"
                                                    className="pt-2 pb-2 mb-3"
                                                >
                                                    <img
                                                        src={`${BASEURL}/images/apple.png`}
                                                        alt=""
                                                        style={{
                                                            width: '35px',
                                                            marginRight: '10px',
                                                        }}
                                                    />
                                                    iPhone
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-md-6">
                                        <ul>
                                            <li>
                                                <a
                                                    href="https://play.google.com/store/search?q=docsta&c=apps"
                                                    target="_BLANK"
                                                    rel="noopener noreferrer"
                                                    className="pt-2 pb-2 mb-3"
                                                >
                                                    <img
                                                        src="https://play.google.com/favicon.ico"
                                                        alt=""
                                                        style={{
                                                            width: '35px',
                                                            marginRight: '10px',
                                                        }}
                                                    />
                                                    Android
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        className="col-md-12"
                        style={{ borderTop: '1px solid #383838', marginTop: '30px' }}
                    ></div>

                    <div className="row">
                        <div className="col-md-6">
                            <div
                                className="footer-copyright mt-0 d-flex"
                                style={{
                                    justifyContent: 'left',
                                    borderTop: '0px solid #383838',
                                }}
                            >
                                <div className="copyright-text">
                                    <p>DocstaLearn &copy;{today.getFullYear()}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div
                                className="footer-copyright mt-0 d-flex"
                                style={{
                                    justifyContent: 'right',
                                    borderTop: '0px solid #383838',
                                }}
                            >
                                <div className="copyright-text">
                                    <Link to="/terms-and-conditions">Terms of Service</Link>
                                    <Link to="/privacy-policy">Copyright Policy</Link>
                                    <Link to="/refunds-and-cancellations">
                                        Refund and Cancellation
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="developed-text d-none">
                <p>
                    Design & Developed by
                    <a href="//sjain.io/" target="_blank" rel="noopener noreferrer">
                        <img
                            src={`${IMGBASEAPIURL}logo/sjain-logo-white.png`}
                            alt="Sjain Ventures"
                        />
                    </a>
                </p>
            </div>

            <div className="d-none">
                <a
                    target="_WHATAPP"
                    href={`https://api.whatsapp.com/send?phone=${ContactWhatsapp}&text=Hello,%20Sending%20you%20message%20from%20https://docstainstitute.com/`}
                    className="whatsapp_us"
                >
                    <img src={`${IMGBASEAPIURL}whatsappus.png`} alt="" />
                </a>
            </div>
            <div className="d-none">
                <a href={`tel:${ContactMobile}`} className="call_us">
                    <img src={`${IMGBASEAPIURL}callus.png?v=0.1`} alt="" />
                </a>
            </div>
        </>
    );
}

export default Footer;
