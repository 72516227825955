import instructorData from './reduxFunction/instructorSlice';
import commonData from './reduxFunction/commonSlice';
import CourseCategory from './reduxFunction/CourseCategorySlice';
import Courses from './reduxFunction/CoursesSlice';
import appSlice from './reduxFunction/appSlice';

import { configureStore } from '@reduxjs/toolkit';

export default configureStore({
    reducer: {
        app: appSlice,
        instructorData,
        commonData,
        CourseCategory,
        Courses,
    },
});
