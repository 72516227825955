import React, { useEffect, useState } from 'react';
import Parser from 'html-react-parser';
import { Link } from 'react-router-dom';
import useToggle from '../../../Hooks/useToggle';
import BackToTop from '../../BackToTop';
import Footer from '../../Helper/FooterLearn';
import Drawer from '../../Mobile/DrawerLearn';
import HeaderInstructor from '../../Helper/HeaderInstitute';
import PopularCoursesBox from '../../Institute/PopularCoursesBox';
import {
    BASEAPIURL,
    UPLOADNODEURL,
    ShowPriceDisplayCart,
    BASEURL,
    ShowPriceDisplaySmall,
    PaymentURL,
    getCurrency,
    getCourseURL,
} from '../../../config';
import {
    FetchCoursetoBuy,
    MakeFavoriteStd,
    RemoveCourseFromCart,
    RemoveCoursetoBuy,
} from '../../Students/functions';
import '../checkOut.css';

const primaryIndex = '_id';
let RefreshCart = 1;
function OrderSummary() {
    // for the Order Confimration of Gift
    // for the Order Confimration
    const queryString = window.location.search;
    // Parse the query string into an object
    const params = new URLSearchParams(queryString);
    const order_id = params.get('order_id');
    const status = params.get('status');
    const [ShowGiftMsg, setShowGiftMsg] = useState(order_id ? true : false);
    // for the Order Confimration of Gift
    const [orderID, setorderID] = useState(0);
    const [ProcessAmount, setProcessAmount] = useState(0);
    const [ProcessedAmount, setProcessedAmount] = useState(0);
    const [isLogin, setisLogin] = useState(false);
    const [ProfileData, setProfileData] = useState({
        name: '',
        email: '',
        mobile: '',
        address: '',
    });
    // const [Redirect2Payment] = useState(localStorage.getItem('Redirect2Payment'));

    const [isLoaded, setisLoaded] = useState(false);
    const [drawer, drawerAction] = useToggle(false);
    const [CourseCartData, setCourseCartData] = useState([]);

    const FetchProfile = async () => {
        try {
            const res = await fetch(`${BASEAPIURL}/student/checkLogin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
                },
                body: JSON.stringify({
                    loggedToken: localStorage.getItem('loggedToken'),
                    usertype: localStorage.getItem('usertype'),
                }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                setisLoaded(true);
                if (resJson.status === 1 && localStorage.getItem('usertype') === 'student') {
                    setisLogin(true);
                    if (resJson.userdata.name) {
                        setProfileData(resJson.userdata);
                    }
                } else {
                    const t = localStorage.getItem('usertype');
                    if (t && t === 'student') {
                        localStorage.setItem('usertype', '');
                    }
                    setisLogin(false);
                }
            } else {
                setisLogin(false);
                console.log(resJson.message);
            }
        } catch (err) {
            setisLogin(false);
            console.log(err);
        }
    };

    const RemoveFromCartList = async (targetType, targetID) => {
        await RemoveCoursetoBuy(targetID, targetType);
        FetchCartData(1);
    };

    const FetchCartData = async (type) => {
        if (type === 1) {
            setisLoaded(false);
        }
        try {
            const res = await fetch(`${BASEAPIURL}/student/GetCartList`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
                },
                body: JSON.stringify({
                    cartData: FetchCoursetoBuy(),
                }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                setTimeout(() => {
                    setisLoaded(true);
                }, 786);
                let MCourseData = [];
                let QBankData = [];
                if (resJson.data && resJson.data.MainCourse && resJson.data.MainCourse.length > 0) {
                    MCourseData = resJson.data.MainCourse;
                }
                if (
                    resJson.data &&
                    resJson.data.questionBank &&
                    resJson.data.questionBank.length > 0
                ) {
                    QBankData = resJson.data.questionBank;
                }
                const QBankDataWithType = QBankData.map((item) => ({
                    ...item,
                    courseType: 'questionBank',
                }));
                const MCourseDataWithType = MCourseData.map((item) => ({
                    ...item,
                    courseType: 'MainCourse',
                }));
                setCourseCartData(MCourseDataWithType.concat(QBankDataWithType));
            }
        } catch (err) {
            console.log(err);
        }
    };

    const showTotalFBTDiscount = () => {
        const country_name = localStorage.getItem('country_name');
        let costinr = 0;
        let cost = 0;
        let FullValue = 0;
        let DiscountedValue = 0;
        // let DiscountedPer = 0;
        CourseCartData.map((single) => {
            costinr = Number(costinr) + Number(single.costinr);
            cost = Number(cost) + Number(single.cost);
            let ForValue = single.cost;
            if (country_name === 'India') {
                ForValue = single.costinr;
            }
            FullValue = Number(FullValue) + Number(ForValue);
            if (single.campaign && Number(single.campaign) > 0) {
                let theAmount = Number((Number(ForValue) * (100 - Number(single.campaign))) / 100);
                // theAmount = Number(theAmount.toFixed(0));
                theAmount = Number(theAmount);
                DiscountedValue = Number(DiscountedValue) + Number(theAmount);
            } else {
                DiscountedValue = Number(DiscountedValue) + Number(ForValue);
            }
            return '';
        });
        const tempAmount =
            Number(DiscountedValue) - (Number(DiscountedValue) / 100) * Number(CouponDiscount);
        const tempDiscount = (Number(DiscountedValue) / 100) * Number(CouponDiscount);
        const a = (
            <>
                <div className="row mt-3 d-none">
                    <div className="col-6">Coupon Discount</div>
                    <div className="col-6 text-right">
                        {window.showCurrencyValue(tempDiscount, tempDiscount)}
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-6 text-dark">
                        <b>Payable Amount</b>
                    </div>
                    <div className="col-6  text-dark text-right">
                        <b>{window.showCurrencyValue(tempAmount, tempAmount)}</b>
                    </div>
                </div>
            </>
        );
        return a;
    };

    const showTotalFBT = () => {
        const country_name = localStorage.getItem('country_name');
        let costinr = 0;
        let cost = 0;
        let FullValue = 0;
        let DiscountedValue = 0;
        // let DiscountedPer = 0;
        CourseCartData.map((single) => {
            costinr = Number(costinr) + Number(single.costinr);
            cost = Number(cost) + Number(single.cost);
            let ForValue = single.cost;
            if (country_name === 'India') {
                ForValue = single.costinr;
            }
            FullValue = Number(FullValue) + Number(ForValue);
            if (single.campaign && Number(single.campaign) > 0) {
                let theAmount = Number((Number(ForValue) * (100 - Number(single.campaign))) / 100);
                // theAmount = Number(theAmount.toFixed(0));
                theAmount = Number(theAmount);
                DiscountedValue = Number(DiscountedValue) + Number(theAmount);
            } else {
                DiscountedValue = Number(DiscountedValue) + Number(ForValue);
            }
            return '';
        });
        const a = Parser(ShowPriceDisplayCart(DiscountedValue, FullValue));
        return a;
    };

    let FullValue = 0;
    let DiscountedValue = 0;
    // alert(window.USDAED);
    const setProcessAmountHere = async () => {
        const country_name = localStorage.getItem('country_name');
        FullValue = 0;
        DiscountedValue = 0;
        CourseCartData.map((single) => {
            let ForValue = single.cost;
            if (country_name === 'India') {
                ForValue = single.costinr;
            }
            if (country_name === 'United Arab Emirates') {
                // ForValue = 3.67 * single.cost;
                ForValue = window.GetCurrencyValue(single.cost, single.cost);
                ForValue = ForValue.cost ? ForValue.cost : 0;
            }
            FullValue = Number(FullValue) + Number(ForValue);
            if (single.campaign && Number(single.campaign) > 0) {
                let theAmount = Number((Number(ForValue) * (100 - Number(single.campaign))) / 100);
                theAmount = Number(theAmount.toFixed(0));
                DiscountedValue = Number(DiscountedValue) + Number(theAmount);
            } else {
                DiscountedValue = Number(DiscountedValue) + Number(ForValue);
            }
            return '';
        });
        setProcessAmount(
            btoa(Number(DiscountedValue) - (Number(DiscountedValue) / 100) * Number(CouponDiscount))
        );
        setProcessedAmount(DiscountedValue);
    };

    const [code, setCode] = useState('');
    const [CouponDiscount, setCouponDiscount] = useState('');
    const [CouponMessage, setCouponMessage] = useState('');
    async function fetchCouponCodeValidaction(code) {
        if (code && code !== '') {
            setisLoaded(false);
            try {
                const res = await fetch(`${BASEAPIURL}/student/fetchCouponCodeValidaction`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ targetID: code, targetType: 'mobile' }),
                });
                const resJson = await res.json();
                setisLoaded(true);
                if (res.status === 200) {
                    setCouponMessage(resJson.message);
                    if (
                        resJson.data &&
                        resJson.data.length > 0 &&
                        resJson.discount &&
                        resJson.discount > 0
                    ) {
                        setCouponDiscount(resJson.discount);
                        setCode(resJson.data[0].mobile);
                    } else {
                        setCouponDiscount(0);
                    }
                } else {
                    console.error(resJson.message);
                }
            } catch (err) {
                setisLoaded(true);
                console.error(err);
            }
        }
    }

    async function MakeRedirect2Payment() {
        localStorage.removeItem('Redirect2Payment');
        setisLoaded(false);
        try {
            const res = await fetch(`${BASEAPIURL}/student/CartMakePayment`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
                },
                body: JSON.stringify({
                    cartData: FetchCoursetoBuy(),
                    paymentFrom: 'ccavenue',
                    ProcessAmount:
                        Number(ProcessedAmount) -
                        (Number(ProcessedAmount) / 100) * Number(CouponDiscount),
                    CouponPercentage: CouponDiscount,
                    CouponCode: code,
                }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                setorderID(resJson.orderID);
                setTimeout(() => {
                    setisLoaded(false);
                    const temp = document.getElementById('ProceedTpPayment');
                    if (temp && temp !== null) {
                        temp.click();
                    }
                }, 786);
                if (resJson.data && resJson.data.MainCourse && resJson.data.MainCourse.length > 0) {
                    setCourseCartData(resJson.data.MainCourse);
                } else {
                    setCourseCartData([]);
                }
            }
        } catch (err) {
            console.log(err);
        }
        // window.location.href =  `${BASEURL}checkout/payment`;
    }

    useEffect(() => {
        if (order_id && order_id !== '' && status && Number(status) === 1) {
            RemoveCourseFromCart(
                localStorage.getItem('giftCourseType'),
                localStorage.getItem('giftCourse')
            );
        }
        const mobile = localStorage.getItem('couponCodeValue');
        if (mobile && mobile !== '') {
            fetchCouponCodeValidaction(mobile);
        }
        // setCourseCartData([]);
        RefreshCart = 1;
        FetchCartData(1);
        FetchProfile();
        const interval1 = setInterval(() => {
            FetchProfile(0);
            // startTransition(() => {
            //     FetchProfile(0);
            // });
        }, 15000);
        const interval2 = setInterval(() => {
            FetchCartData(0);
            // startTransition(() => {
            //     FetchCartData(0);
            // });
        }, 5000);
        setProcessAmountHere();
        // if (Number(Redirect2Payment) === 1) {
        //     // setisLoaded(false);
        //     // setProcessAmountHere();
        // }
        // setTimeout(() => {
        //     if (Number(Redirect2Payment) === 1) {
        //         // setisLoaded(false);
        //         // MakeRedirect2Payment();
        //     }
        // }, 3000);
        // Return a cleanup function to clear all intervals on unmount
        return () => {
            clearInterval(interval1);
            clearInterval(interval2);
            // RefreshCart = RefreshCart + 1;
        };
    }, [RefreshCart]);

    return (
        <>
            {/* for Giving the Review for the Course */}
            {ShowGiftMsg && (
                <>
                    <div className="ryPopUpBack"></div>
                    <div
                        className="ryPopUp"
                        style={{
                            position: 'fixed',
                            height: 'fit-content',
                        }}
                    >
                        <a
                            href={BASEURL}
                            className="ryPopUpAct"
                            onClick={() => {
                                // e.preventDefault();
                                setShowGiftMsg(false);
                            }}
                        >
                            X
                        </a>
                        <div style={{ margin: '30px 50px' }}>
                            {ShowGiftMsg ? (
                                <>
                                    {order_id &&
                                    order_id !== '' &&
                                    status &&
                                    Number(status) === 1 ? (
                                        <center>
                                            <img src={`${BASEURL}assets/qb/passed.png`} alt="" />
                                            <h5 className="mt-3 text-dark">
                                                Celebrate the significant achievement of those
                                                you've gifted <br />
                                                <br /> as they embark on their journey toward
                                                success.
                                                <br />
                                                <br />
                                                The email will be sent on your choosen Date.
                                            </h5>
                                            <a
                                                href={BASEURL}
                                                className="btn btn-success mt-3"
                                                onClick={() => {
                                                    setShowGiftMsg(false);
                                                }}
                                            >
                                                Thank You
                                            </a>
                                        </center>
                                    ) : (
                                        ''
                                    )}
                                    {order_id &&
                                    order_id !== '' &&
                                    status &&
                                    Number(status) === 2 ? (
                                        <center>
                                            <img src={`${BASEURL}assets/qb/failed.png`} alt="" />
                                            <h5 className="mt-3 text-dark">
                                                Uh-oh! Payment unsuccessful. <b>Try again</b> to
                                                unleash success.
                                            </h5>
                                            <a
                                                href={`${BASEURL}checkout/giftCourse`}
                                                className="btn btn-secondary mt-3"
                                                onClick={() => {
                                                    setShowGiftMsg(false);
                                                }}
                                            >
                                                Let me try again
                                            </a>
                                        </center>
                                    ) : (
                                        ''
                                    )}
                                </>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </>
            )}
            {/* for Giving the Review for the Course */}
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderInstructor
                drawer={drawer}
                action={drawerAction.toggle}
                pageName="courseDetail"
            />
            <>
                <div
                    className="container text-center pt-4 pb-4"
                    style={{ display: isLoaded ? 'none' : '' }}
                >
                    <img
                        src={`${BASEURL}loader.gif`}
                        alt=""
                        className="pt-4 pb-4"
                        style={{ maxWidth: '75px' }}
                    />
                </div>
                <div className="container pt-4 pb-4" style={{ display: isLoaded ? '' : 'none' }}>
                    <div className="row">
                        <div className=" col-md-9">
                            <h3>Order Summary</h3>
                        </div>
                    </div>
                    <div style={{ display: isLoaded ? '' : 'none' }}>
                        {CourseCartData.length}
                        {CourseCartData.length > 1 ? ' Courses in the Cart' : ' Course in the Cart'}
                    </div>
                    <div className="row">
                        <div className="col-lg-8 col-sm-8">
                            <div
                                className="ry_instr_shadow p-3 mt-4 mb-4"
                                style={{ display: isLoaded ? '' : 'none' }}
                            >
                                {CourseCartData.length === 0 ? (
                                    <div className="text-center">
                                        <br />
                                        <br />
                                        Your cart is empty. Keep shopping to find a course!
                                        <br />
                                        <br />
                                        <Link to="/" className="main-btn">
                                            Browse Courses
                                        </Link>
                                        <br />
                                        <br />
                                    </div>
                                ) : (
                                    ''
                                )}
                                {CourseCartData.map((single) => (
                                    <div
                                        className="row"
                                        id={single[primaryIndex]}
                                        key={single[primaryIndex]}
                                    >
                                        {console.log(single)}
                                        <div className="col-3 col-lg-2 col-sm-2 text-center">
                                            <Link
                                                to={getCourseURL(single.courseType, single.slug)}
                                                target="_CART"
                                            >
                                                <img
                                                    src={`${UPLOADNODEURL}${single.image}`}
                                                    alt={single.image}
                                                    style={{ maxWidth: '250px', width: '100%' }}
                                                />
                                            </Link>
                                            <div className="CPPrdPriceMobile mt-2">
                                                {Parser(
                                                    ShowPriceDisplaySmall(
                                                        single.campaign,
                                                        single.cost,
                                                        single.costinr
                                                    )
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-9 col-lg-10 col-sm-10">
                                            <div className="CPPrdPrice">
                                                {Parser(
                                                    ShowPriceDisplaySmall(
                                                        single.campaign,
                                                        single.cost,
                                                        single.costinr
                                                    )
                                                )}
                                            </div>

                                            <div className="row">
                                                <div
                                                    className="col-lg-12 col-sm-12"
                                                    style={{ width: '18rem' }}
                                                >
                                                    <div className="body">
                                                        <Link
                                                            to={getCourseURL(
                                                                single.courseType,
                                                                single.slug
                                                            )}
                                                            target="_CART"
                                                        >
                                                            <h6 className="title">
                                                                {single.title}
                                                            </h6>
                                                        </Link>
                                                        <p
                                                            className="text"
                                                            style={{ lineHeight: '30px' }}
                                                        >
                                                            By {single.InstructorDisplay}
                                                        </p>
                                                    </div>
                                                    <div className="body hideOnMobile">
                                                        <a
                                                            href={BASEURL}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                RemoveFromCartList(
                                                                    single.courseType,
                                                                    single[primaryIndex]
                                                                );
                                                            }}
                                                        >
                                                            <span className="text-ry mr-3">
                                                                Remove
                                                            </span>
                                                        </a>
                                                        <a
                                                            href={BASEURL}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                RemoveFromCartList(
                                                                    single.courseType,
                                                                    single[primaryIndex]
                                                                );
                                                                MakeFavoriteStd(
                                                                    localStorage.getItem(
                                                                        'loggedToken'
                                                                    ),
                                                                    single[primaryIndex],
                                                                    single.courseType,
                                                                    localStorage.getItem('usertype')
                                                                );
                                                            }}
                                                        >
                                                            <span className="text-ry mr-3">
                                                                Move to Favourites
                                                            </span>
                                                        </a>
                                                        <Link
                                                            to="/checkout/giftCourse"
                                                            onClick={() => {
                                                                localStorage.setItem(
                                                                    'giftCourse',
                                                                    single[primaryIndex]
                                                                );
                                                                localStorage.setItem(
                                                                    'giftCourseType',
                                                                    single.courseType
                                                                );
                                                            }}
                                                        >
                                                            <span className="text-ry">
                                                                Gift this Course
                                                            </span>
                                                        </Link>
                                                    </div>
                                                    <div className="hideOnDesktop text-left">
                                                        <a
                                                            href={BASEURL}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                RemoveFromCartList(
                                                                    single.courseType,
                                                                    single[primaryIndex]
                                                                );
                                                            }}
                                                        >
                                                            <span className="text-ry text-dark mr-3">
                                                                <i className="far fa-trash"></i>
                                                            </span>
                                                        </a>
                                                        <a
                                                            href={BASEURL}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                RemoveFromCartList(
                                                                    single.courseType,
                                                                    single[primaryIndex]
                                                                );
                                                                MakeFavoriteStd(
                                                                    localStorage.getItem(
                                                                        'loggedToken'
                                                                    ),
                                                                    single[primaryIndex],
                                                                    single.courseType,
                                                                    localStorage.getItem('usertype')
                                                                );
                                                            }}
                                                        >
                                                            <span className="text-ry mr-3">
                                                                <i className="far fa-heart"></i>
                                                            </span>
                                                        </a>
                                                        <Link
                                                            to="/checkout/giftCourse"
                                                            onClick={() => {
                                                                localStorage.setItem(
                                                                    'giftCourse',
                                                                    single[primaryIndex]
                                                                );
                                                                localStorage.setItem(
                                                                    'giftCourseType',
                                                                    single.courseType
                                                                );
                                                            }}
                                                        >
                                                            <span className="text-ry">
                                                                <i className="far fa-gift mr-1"></i>
                                                                Gift this Course
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="clearBoth"></div>
                                        </div>
                                        <div className="col-lg-12">
                                            <hr className="m-3" />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-4">
                            <div
                                className="mt-4 mb-4"
                                style={{ display: CourseCartData.length > 0 ? '' : 'none' }}
                            >
                                {showTotalFBT()}
                                <div className="mt-2"></div>
                                {/* <h6 className='mb-2'>Promotions</h6> */}
                                <div className="d-flex">
                                    <input
                                        type="text"
                                        placeholder="Enter Coupon"
                                        defaultValue={code}
                                        className="form-control"
                                        onChange={(e) => {
                                            e.preventDefault();
                                            setCode(e.target.value);
                                            setCouponDiscount(0);
                                            setCouponMessage('');
                                        }}
                                    />
                                    <button
                                        className="btn btn-sm btn-docsta-instructor"
                                        type="button"
                                        style={{
                                            border: '0px',
                                            borderRadius: ' 0px',
                                            maxWidth: '90px',
                                        }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            fetchCouponCodeValidaction(code);
                                        }}
                                    >
                                        Apply
                                    </button>
                                </div>
                                <div className="mt-2"></div>
                                <div className="text-danger">
                                    {CouponMessage ? CouponMessage : ''}
                                </div>
                                {CouponDiscount !== '' && Number(CouponDiscount) !== 0 && (
                                    <>{showTotalFBTDiscount()}</>
                                )}
                                <div className="mt-2"></div>
                                <div className="mobile_footer_action p-2 p-md-1">
                                    <p className="text-center" style={{ fontSize: '12px' }}>
                                        By completing the purchase, I agree to the&nbsp;
                                        <a
                                            href={`${BASEURL}information/termandconditions`}
                                            target="_BLANK"
                                            rel="noopener noreferrer"
                                        >
                                            <b>Terms of Service</b>.
                                        </a>
                                    </p>
                                    {isLogin ? (
                                        <>
                                            <a
                                                href={`${BASEURL}stripe/index.html`}
                                                onClick={(e) => {
                                                    setProcessAmountHere();
                                                    e.preventDefault();
                                                    MakeRedirect2Payment();
                                                }}
                                                className="btn btn-lg btn-docsta-instructor mt-1 pt-2 pb-2 d-block"
                                            >
                                                <i className="fa fa-lock"></i>
                                                &nbsp; Proceed to Secure Checkout
                                            </a>
                                        </>
                                    ) : (
                                        <a
                                            onClick={(e) => {
                                                e.preventDefault();
                                                // localStorage.setItem('Redirect2Payment', 1);
                                                localStorage.setItem(
                                                    'RedirectAfterLogin',
                                                    `${BASEURL}checkout/cart`
                                                );
                                                localStorage.setItem(
                                                    'MsgBeforeLogin',
                                                    'Please Login to Make Payment.'
                                                );
                                                window.location.href = `${BASEURL}user/login`;
                                            }}
                                            className="btn btn-lg btn-docsta-instructor mt-1 pt-2 pb-2 d-block"
                                            href="/"
                                        >
                                            <i className="fa fa-lock"></i>
                                            &nbsp; Login to Secure Checkout
                                        </a>
                                    )}
                                    <p>
                                        <small className="d-block text-dark text-center">
                                            30 day Refund Guarantee
                                        </small>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            <div className="container pt-4 pb-4" style={{ display: isLoaded ? '' : 'none' }}>
                <PopularCoursesBox />
            </div>

            <Footer className="appie-footer-about-area" />
            {isLogin ? (
                <>
                    <form action={PaymentURL} method="POST" style={{ display: 'none' }}>
                        <input type="hidden" name="token" value={ProfileData.mine} />
                        <input type="hidden" name="email" value={ProfileData.email} />
                        <input type="hidden" name="mobile" value={ProfileData.mobile} />
                        <input type="hidden1" name="process" value={ProcessAmount} />
                        <input type="hidden" name="address" value={ProfileData.address} />
                        <input type="hidden" name="mine" value={btoa(ProfileData.mine)} />
                        <input type="hidden" name="currency" value={getCurrency()} />
                        <input type="hidden" name="orderID" value={orderID} />
                        <input
                            type="hidden"
                            name="country"
                            value={localStorage.getItem('country_name')}
                        />
                        <input type="submit" id="ProceedTpPayment" name="proceed" value="proceed" />
                    </form>
                </>
            ) : (
                ''
            )}
            <BackToTop />
        </>
    );
}

export default OrderSummary;
