import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import PhoneInput from 'react-phone-number-input';
import { BASEAPIURL, BASEURL, UPLOADNODEURL } from '../../../../config';

function StudentProfileUpdate({ forpage }) {
    const navigate = useNavigate();
    const [defaultProfilePicture] = useState('defaultProfilePicture.png');
    const [Profile, setProfile] = useState({
        name: '',
        email: '',
        mobile: '',
        lastLogin: '',
        photo: defaultProfilePicture,
        usertype: localStorage.getItem('usertype'),
    });
    const UpdateProfile = (name, value) => {
        setProfile({ ...Profile, [name]: value });
    };
    const [MsgClass, setMsgClass] = useState('alert-danger');
    const [APIMessage, setMessage] = useState();
    const [IsAPIAction, setIsAPIAction] = useState(false);

    const [ShowCVfile, setShowCVfile] = useState('Upload Profile Picture');
    const [PhoneNumber, setPhoneNumber] = useState('');
    const [MobileAready, setMobileAready] = useState('');
    const [EmailAready, setEmailAready] = useState('');

    // for file uploade will be here
    async function uploadFile(file, fileName) {
        // e.preventDefault();
        setIsAPIAction(true);
        setShowCVfile(fileName);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('fileType', 'image');
        formData.append('fileName', fileName);
        const url = `${BASEAPIURL}/student/uploadFile`;
        try {
            setIsAPIAction(false);
            const res = await axios.post(url, formData);
            if (res.data.status === 1) {
                UpdateProfile('photo', res.data.filename);
            } else {
                alert(res.data.message);
            }
        } catch (ex) {
            setIsAPIAction(false);
            alert(ex);
        }
    }
    const UploadInstrProfiePics = () => {
        document.getElementById('instr_profie_pics').click();
    };
    // for file uploade will be here
    async function checkEmailAready(emailid) {
        let returntype = false;
        setEmailAready('');
        setMsgClass('alert-danger');
        try {
            const url = `${BASEAPIURL}/student/checkifalreadyemail`;
            const formData = new FormData();
            formData.append('emailid', emailid);
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
                },
            };
            axios.post(url, formData, config).then((response) => {
                if (response.data.status === 1) {
                    setEmailAready(response.data.message.toString());
                    returntype = true;
                    setIsAPIAction(true);
                } else {
                    setEmailAready('');
                }
            });
        } catch (err) {
            console.log(err);
            setEmailAready('Something went wrong, Please try again later.');
        }
        return returntype;
    }

    async function checkMobileAready(mobileno) {
        setEmailAready('');
        let returntype = false;
        try {
            const url = `${BASEAPIURL}/student/checkifalreadymobile`;
            const formData = new FormData();
            formData.append('mobileno', mobileno);
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
                },
            };
            axios.post(url, formData, config).then((response) => {
                if (response.data.status === 1) {
                    setMobileAready(response.data.message.toString());
                    setIsAPIAction(true);
                    returntype = true;
                } else {
                    setMobileAready('');
                }
            });
        } catch (err) {
            console.log(err);
            setMobileAready('Something went wrong, Please try again later.');
        }
        return returntype;
    }
    async function UpdateStudentProfile() {
        try {
            setMsgClass('alert-danger');
            setIsAPIAction(true);
            const url = `${BASEAPIURL}/student/UpdateStudentProfile`;
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
                },
            };
            Profile.usertype = localStorage.getItem('usertype');
            axios
                .post(url, Profile, config)
                .then((response) => {
                    setIsAPIAction(false);
                    if (response.data.status === 1) {
                        setMsgClass('alert-success');
                        setTimeout(() => {
                            setMessage('');
                            navigate('/user/page/profilesetting');
                        }, 2786);
                    }
                    setMessage(response.data.message);
                })
                .catch((er) => {
                    setIsAPIAction(false);
                    console.log(er);
                    setMessage('Something went wrong, Please try again later.');
                });
        } catch (err) {
            setIsAPIAction(false);
            setMsgClass('alert-danger');
            setTimeout(() => {
                setMessage('');
            }, 2786);
            console.log(err);
            setMessage('Something went wrong, Please try again later.');
        }
    }

    useEffect(() => {
        const FetchProfile = async () => {
            try {
                const res = await fetch(`${BASEAPIURL}/student/myprofile`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
                    },
                    body: JSON.stringify({
                        loggedToken: localStorage.getItem('loggedToken'),
                        usertype: localStorage.getItem('usertype'),
                    }),
                });
                const resJson = await res.json();
                if (res.status === 200) {
                    setProfile(resJson.userdata ? resJson.userdata : {});
                    setPhoneNumber(
                        resJson.userdata && resJson.userdata.mobile ? resJson.userdata.mobile : ''
                    );
                } else {
                    console.log(resJson.message);
                }
            } catch (err) {
                console.log(err);
            }
        };
        FetchProfile();
    }, []);

    function GetShowData(forData, Index, defaultValue) {
        let returnValue = defaultValue;
        if (forData && forData[Index] && forData[Index] !== null && forData[Index] !== '') {
            returnValue = forData[Index];
        }
        return returnValue;
    }

    return (
        <>
            <div className="pb-3" forpage={forpage}>
                <div className="row">
                    <div className="col-md-7">
                        <div className="ry_instr_shadow p-3">
                            <h5 className="mb-3 text-center">Profile Details</h5>
                            <form
                                id="Inst-Regi-Form"
                                encType="multipart/form-data"
                                className="p-1"
                                style={{ display: 'block' }}
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    if (!IsAPIAction && EmailAready === '' && MobileAready === '') {
                                        UpdateStudentProfile();
                                    }
                                }}
                            >
                                <div className="form-group mb-1">
                                    <label className="ry_label">Your Name</label>
                                    <input
                                        type="text"
                                        name="name"
                                        value={GetShowData(Profile, 'name', '')}
                                        onChange={(e) => {
                                            UpdateProfile('name', e.target.value);
                                        }}
                                        className="form-control"
                                        placeholder="Enter Your Name"
                                    />
                                </div>

                                <div className="form-group mb-1">
                                    <label className="ry_label">Phone Number</label>
                                    <PhoneInput
                                        international
                                        className="form-control"
                                        name="phonenumber"
                                        value={PhoneNumber}
                                        onChange={setPhoneNumber}
                                        onKeyUpCapture={(e) => {
                                            setIsAPIAction(false);
                                            UpdateProfile('mobile', e.target.value);
                                            checkMobileAready(e.target.value);
                                        }}
                                        defaultCountry="IN"
                                        style={{ position: 'relative' }}
                                    />
                                    <div
                                        className={`message alert hide_empty_div mb-3 ${MsgClass}`}
                                    >
                                        {MobileAready ? <span>{MobileAready}</span> : ''}
                                    </div>
                                </div>

                                <div className="form-group mb-1">
                                    <label className="ry_label">Email ID</label>
                                    <input
                                        type="email"
                                        name="emailid"
                                        className="form-control"
                                        placeholder="Enter Email ID"
                                        value={GetShowData(Profile, 'email', '')}
                                        onChange={(e) => {
                                            setIsAPIAction(false);
                                            UpdateProfile('email', e.target.value);
                                            checkEmailAready(e.target.value);
                                        }}
                                    />
                                    <div
                                        className={`message alert hide_empty_div mb-3 ${MsgClass}`}
                                    >
                                        {EmailAready ? <span>{EmailAready}</span> : ''}
                                    </div>
                                </div>

                                <div className="form-group mb-1">
                                    <label className="ry_label">Gender</label>
                                    <br />
                                    <input
                                        type="radio"
                                        name="name"
                                        checked={
                                            // eslint-disable-next-line no-unneeded-ternary
                                            GetShowData(Profile, 'gender', '') === 'Male'
                                                ? true
                                                : false
                                            // eslint-enable-next-line no-unneeded-ternary
                                        }
                                        onChange={() => {
                                            UpdateProfile('gender', 'Male');
                                        }}
                                    />
                                    &nbsp;Male &nbsp; &nbsp;
                                    <input
                                        type="radio"
                                        name="name"
                                        checked={
                                            // eslint-disable-next-line no-unneeded-ternary
                                            GetShowData(Profile, 'gender', '') === 'Female'
                                                ? true
                                                : false
                                            // eslint-enable-next-line no-unneeded-ternary
                                        }
                                        onChange={() => {
                                            UpdateProfile('gender', 'Female');
                                        }}
                                    />
                                    &nbsp;Female
                                </div>

                                <div className="form-group mb-1">
                                    <label className="ry_label">Address</label>
                                    <textarea
                                        name="name"
                                        value={GetShowData(Profile, 'address', '')}
                                        onChange={(e) => {
                                            UpdateProfile('address', e.target.value);
                                        }}
                                        className="form-control"
                                        placeholder="Enter Your Name"
                                    ></textarea>
                                </div>

                                <div
                                    className="text-right"
                                    style={{ cursor: 'pointer' }}
                                    onClick={UploadInstrProfiePics}
                                >
                                    <input
                                        type="file"
                                        name="photo"
                                        accept="image/*"
                                        className="d-none"
                                        id="instr_profie_pics"
                                        onChange={(e) => {
                                            if (e.target.files.length > 0) {
                                                uploadFile(
                                                    e.target.files[0],
                                                    e.target.files[0].name
                                                );
                                            }
                                        }}
                                    />
                                    {ShowCVfile} &nbsp;
                                    <i className="fa fa-upload"></i>
                                </div>
                                <div className={`message alert hide_empty_div mb-3 ${MsgClass}`}>
                                    {APIMessage ? <span>{APIMessage}</span> : ''}
                                </div>
                                <div className={`mt-3 text-center ${IsAPIAction ? '' : 'd-none'}`}>
                                    <img
                                        style={{ maxWidth: '32px' }}
                                        src={`${BASEURL}loader.gif`}
                                        alt=""
                                    />
                                </div>
                                <div className={`row mt-3 ${IsAPIAction ? 'd-none' : ''}`}>
                                    <div className="col-md-6">
                                        <Link
                                            to="/user/page/profilesetting"
                                            className="btn d-block btn-outline-danger"
                                        >
                                            Cancel
                                        </Link>
                                    </div>
                                    <div className="col-md-6">
                                        <button type="submit" className="btn btn-docsta-instructor">
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="col-md-5">
                        <div className="ry_instr_shadow p-3">
                            <img
                                src={`${UPLOADNODEURL}${GetShowData(
                                    Profile,
                                    'photo',
                                    defaultProfilePicture
                                )}`}
                                alt={GetShowData(Profile, 'name', 'NA')}
                                className="p-3"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default StudentProfileUpdate;
