import React, { useState, useEffect } from 'react';
import { UPLOADNODEURL, BASEAPIURL, IMGBASEAPIURL } from '../../../config';
import '../AdvisoryBoard.css';

function AdvisoryBoardList({ className }) {
    const [AdviData, setAdviData] = useState([]);

    const FetchAdvisoryBoard = async () => {
        try {
            const res = await fetch(`${BASEAPIURL}/admin/advisoryboard/getAll`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    token: 'hello',
                    recordsPerPage: 10,
                    SortStatus: 1,
                }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                setAdviData(resJson.data);
            } else {
                console.log(resJson.message);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        FetchAdvisoryBoard();
    }, []);

    return (
        <>
            <section
                className="corporate-learning"
                data-overlay="6"
                style={{
                    backgroundImage: `url(${IMGBASEAPIURL}institute/about/banner.jpeg)`,
                    padding: '0px 10px',
                    backgroundPosition: 'center',
                }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="headBlock">
                                {/* <h3 className="mt-3 mb-1">Mentors</h3> */}
                                <h1 className="mt-3 text-white mb-1">Mentors</h1>
                                <p className="mb-3">Unlock Brilliance, Unlock Life</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={`appie-features-area  pt-4 advisoryBoardList ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="advisoryBoardBox">
                                <div style={{ clear: 'both' }}></div>

                                {AdviData.map((singi) => (
                                    <div
                                        key={singi.advibrdid}
                                        className="appie-features-content mentor_div"
                                    >
                                        <div className="imgBox">
                                            <img src={`${UPLOADNODEURL}${singi.image}`} alt="" />
                                        </div>
                                        <div className="p-2">
                                            {' '}
                                            {/* contentBox */}
                                            <h4 className="CLB_course_title">{singi.title}</h4>
                                            <p className="mt-0 AB_course_description">
                                                {singi.subtitle}
                                            </p>
                                        </div>
                                    </div>
                                ))}

                                <div style={{ clear: 'both' }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AdvisoryBoardList;
