import React, { useState, useEffect } from 'react';
import { BASEAPIURL, BASEURL, showLiveClassDateTime, showfulldatetime } from '../../../../config';
import Pagination from './MyWebinarPagination';
import PopupVideo from '../../../videoPlayer/player';

function ProfileMenu({ forpage }) {
    // for pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(5);
    const [TotalRecords, setTotalRecords] = useState(0);
    const nPages = Math.ceil(TotalRecords / recordsPerPage);
    // for pagination
    const [LiveClasses, setLiveClasses] = useState([]);

    // Get the current date
    const currentDate = new Date();
    const formattedTodayDate = currentDate.toISOString().split('T')[0];
    const dateAfter30DaysObj = new Date(currentDate);
    dateAfter30DaysObj.setDate(dateAfter30DaysObj.getDate() + 30);
    const formattedDateAfter30Days = dateAfter30DaysObj.toISOString().split('T')[0];

    const [Filters, SetFilters] = useState({
        page: currentPage,
        fromDate: formattedTodayDate,
        toDate: formattedDateAfter30Days,
        keyword: '',
        recordsPerPage,
        SortBy: 'old',
    });

    const [StartValue, SetStartValue] = useState({ status: 0 });
    const [ShowClass, SetShowClass] = useState(false);
    const [ShowChat, SetShowChat] = useState(false);
    const [VideoID, SetVideoID] = useState('');

    const StartLiveClasses = async (videoID) => {
        SetStartValue({ status: 1 });
        try {
            const res = await fetch(`${BASEAPIURL}/mainCourse/StartLiveClasses`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Basic ${localStorage.getItem('jwttoken')}`,
                },
                body: JSON.stringify({ videoID }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                SetStartValue(resJson);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const FetchLiveClasses = async (filterObj) => {
        try {
            const res = await fetch(`${BASEAPIURL}/mainCourse/InstructorLiveClasses`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Basic ${localStorage.getItem('jwttoken')}`,
                },
                body: JSON.stringify(filterObj),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                setLiveClasses(resJson.data);
                setTotalRecords(resJson.count);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const FetchFilterLiveClasses = async (e, keywordhere, sortbyhere, PageNumber) => {
        // Keyword SortBy
        e.preventDefault();
        FetchLiveClasses({
            ...Filters,
            keyword: keywordhere,
            SortBy: sortbyhere,
            page: PageNumber,
        });
        return false;
    };
    useEffect(() => {
        FetchLiveClasses(Filters);
        // document.getElementById('blog_filter_frm').submit();
    }, []);

    return (
        <>
            {ShowClass && VideoID && (
                <>
                    <div className="ryPopUpBack" style={{ zIndex: 99999999 }}></div>
                    <div className="ryPopUp" style={{ zIndex: 99999999, minWidth: '50%' }}>
                        <a
                            href="/"
                            className="text-white ryPopUpAct"
                            onClick={(e) => {
                                e.preventDefault();
                                SetShowClass(false);
                            }}
                        >
                            X
                        </a>
                        <div className="p-4">
                            <div className="mt-2">
                                <PopupVideo
                                    DefaultVideoTitle=""
                                    DefaultVideoId={VideoID}
                                    VIDPlaying={true}
                                    responsive={true}
                                    Runningon={0}
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
            {ShowChat && VideoID && (
                <>
                    <div className="ryPopUpBack" style={{ zIndex: 99999999 }}></div>
                    <div className="ryPopUp" style={{ zIndex: 99999999, minWidth: '50%' }}>
                        <a
                            href="/"
                            className="text-white ryPopUpAct"
                            onClick={(e) => {
                                e.preventDefault();
                                SetShowChat(false);
                            }}
                        >
                            X
                        </a>
                        <div className="p-4">
                            <div className="mt-2">
                                <iframe
                                    title="Docsta Learn Live Chat on Course Live Stream"
                                    src={`https://app.tpstreams.com/live-chat/69uh4g/${VideoID}/`}
                                    style={{
                                        width: '100%',
                                        minHeight:
                                            Number(localStorage.getItem('screenwidth')) < 800
                                                ? '70vh'
                                                : '70vh',
                                    }}
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {StartValue && StartValue.status === 1 && (
                <>
                    <div className="ryPopUpBack" style={{ zIndex: 99999999 }}></div>
                    <div className="ryPopUp" style={{ zIndex: 99999999 }}>
                        <a
                            href="/"
                            className="text-white ryPopUpAct"
                            onClick={(e) => {
                                e.preventDefault();
                                SetStartValue({ status: 0 });
                            }}
                        >
                            X
                        </a>
                        <div className="p-4">
                            <div className="mt-2">
                                {StartValue && StartValue.live_stream ? (
                                    <>
                                        <table className="table table-bordered table-hover">
                                            <tr>
                                                <td>Title</td>
                                                <td>
                                                    <b>{StartValue && StartValue.title}</b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>RTMP URL</td>
                                                <td>
                                                    <b>
                                                        {StartValue && StartValue.live_stream
                                                            ? StartValue.live_stream.rtmp_url
                                                            : 'NA'}
                                                    </b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Stream Key</td>
                                                <td>
                                                    <b>
                                                        {StartValue && StartValue.live_stream
                                                            ? StartValue.live_stream.stream_key
                                                            : 'NA'}
                                                    </b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>HLS URL</td>
                                                <td>
                                                    <b>
                                                        {StartValue && StartValue.live_stream
                                                            ? StartValue.live_stream.hls_url
                                                            : 'NA'}
                                                    </b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>RTMP URL</td>
                                                <td>
                                                    <b>
                                                        {StartValue && StartValue.live_stream
                                                            ? StartValue.live_stream.status
                                                            : 'NA'}
                                                    </b>
                                                </td>
                                            </tr>
                                        </table>
                                    </>
                                ) : (
                                    <img
                                        src={`${BASEURL}loader.gif`}
                                        alt=""
                                        style={{ maxWidth: '60px' }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}
            <div className="ry_instr_shadow p-4" forpage={forpage}>
                <h4>
                    My Live Courses
                    <div style={{ clear: 'both' }}></div>
                </h4>
                <form id="blog_filter_frm" className="row mt-3">
                    <div className="col-md-3">
                        <input
                            name="keyword"
                            type="text"
                            className="form-control"
                            placeholder="Search Anything"
                            value={Filters.keyword}
                            onChange={(e) => {
                                SetFilters({
                                    ...Filters,
                                    [e.target.name]: e.target.value,
                                    page: 1,
                                });
                                FetchLiveClasses({
                                    ...Filters,
                                    [e.target.name]: e.target.value,
                                    page: 1,
                                });
                            }}
                        />
                    </div>
                    <div className="col-md-3" title="From Date">
                        <input
                            name="fromDate"
                            type="date"
                            className="form-control"
                            placeholder="From Date"
                            value={Filters.fromDate}
                            onChange={(e) => {
                                SetFilters({
                                    ...Filters,
                                    [e.target.name]: e.target.value,
                                    page: 1,
                                });
                                FetchLiveClasses({
                                    ...Filters,
                                    [e.target.name]: e.target.value,
                                    page: 1,
                                });
                            }}
                        />
                    </div>

                    <div className="col-md-3" title="To Date">
                        <input
                            name="toDate"
                            type="date"
                            className="form-control"
                            placeholder="to Date"
                            value={Filters.toDate}
                            onChange={(e) => {
                                SetFilters({
                                    ...Filters,
                                    [e.target.name]: e.target.value,
                                    page: 1,
                                });
                                FetchLiveClasses({
                                    ...Filters,
                                    [e.target.name]: e.target.value,
                                    page: 1,
                                });
                            }}
                        />
                    </div>
                    <div className="col-md-3">
                        <select
                            name="SortBy"
                            className="form-control"
                            value={Filters.SortBy}
                            onChange={(e) => {
                                SetFilters({
                                    ...Filters,
                                    [e.target.name]: e.target.value,
                                    page: 1,
                                });
                                FetchLiveClasses({
                                    ...Filters,
                                    [e.target.name]: e.target.value,
                                    page: 1,
                                });
                            }}
                        >
                            <option value="0">Sort By</option>
                            <option value="a2z">Name A2Z</option>
                            <option value="z2a">Name Z2A</option>
                            <option value="latest">Latest</option>
                            <option value="old">Oldest</option>
                        </select>
                    </div>
                </form>
                <div className="mt-3 table-responsive">
                    <table className="table table-hover table-borddered table-stripped">
                        <thead className="ry-table-header">
                            <tr>
                                <th>Session Name</th>
                                <th>Time for Class</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {LiveClasses.map((singi) => (
                                <tr key={singi.blogid}>
                                    <td>
                                        <b
                                            title={`Created on ${showfulldatetime(singi.createdAt)}`}
                                        >
                                            {singi.title}
                                        </b>
                                    </td>
                                    <td>
                                        <span title={showfulldatetime(Number(singi.dateTimeClass))}>
                                            {showLiveClassDateTime(Number(singi.dateTimeClass))}
                                        </span>
                                    </td>
                                    <td>
                                        <a
                                            href="/"
                                            className="alert p-1 mr-2 alert-success"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                StartLiveClasses(singi.videoID);
                                            }}
                                        >
                                            Get RTMP URL
                                        </a>
                                        <a
                                            href="/"
                                            className="alert p-1 mr-2 alert-info"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                SetVideoID(singi.videoID);
                                                SetShowClass(true);
                                            }}
                                        >
                                            View Class
                                        </a>
                                        <a
                                            href="/"
                                            className="alert p-1 alert-warning"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                SetVideoID(singi.videoID);
                                                SetShowChat(true);
                                            }}
                                        >
                                            View Chat
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <small className="text-center d-block mb-2">
                    Showing Page {currentPage} of Total page {nPages} from {TotalRecords} Records
                </small>
                <Pagination
                    nPages={nPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    FetchFilterBlogs={FetchFilterLiveClasses}
                    SortBy={Filters.SortBy}
                    Keyword={Filters.keyword}
                />
            </div>
        </>
    );
}

export default ProfileMenu;
