import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// import { useParams } from 'react-router-dom';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
// import { GoogleOAuthProvider , useGoogleLogin } from '@react-oauth/google';
import {
    IMGBASEAPIURL,
    BASEAPIURL,
    LoginRedirect,
    getDefaultCountry,
    BASEURL,
} from '../../../../config';
import FacebookLogin from '@greatsumini/react-facebook-login';
import './login.css';
import LoginWithOTP from './loginWithOTP';
import PhoneInput from 'react-phone-number-input';
// import AppFirebase from './firebase';

// for the Social Login
let SocialUsername = 'Google';
let SocialPassword = 'Pace2023#';
// for the Social Login

// { setisLogin }
function UserLogin() {
    // for the Gift Parameter
    // Extracting parameters from the query string
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    const action = params.get('action');
    // for the Gift Parameter token=${data._id}&action=gift

    // const history = useHistory();
    const [OTPLogin, setOTPLogin] = useState(false);
    const [ShowOTPInp, setShowOTPInp] = useState(false);
    const [OTP, setOTP] = useState('');
    const [ForgotType, setForgotType] = useState('email');
    const [isAction, setisAction] = useState(false);
    const [lfVisibility, setlfVisibility] = useState('block');
    const [fpVisibility, setfpVisibility] = useState('none');
    const [msgClass, setmsgClass] = useState('alert-danger');
    const [Username, setUsername] = useState('');
    const [Password, setPassword] = useState('');
    const [PasswordType, setPasswordType] = useState('password');
    const [message, setMessage] = useState('');
    const [MsgBeforeLogin] = useState(localStorage.getItem('MsgBeforeLogin'));

    const UserSocialLogin = async (type, response) => {
        setisAction(true);
        setmsgClass('alert-warning');
        setMessage('Processing');
        // console.log(SocialUsername);
        // Create a base64-encoded token from your credentials
        const base64Token = btoa(`${SocialUsername}:${SocialPassword}`);
        try {
            const res = await fetch(`${BASEAPIURL}/student/UserSocialLogin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Basic ${base64Token}`,
                },
                body: JSON.stringify({ type, response }),
            });
            const resJson = await res.json();
            if (resJson.data.name === '' || resJson.data.phone === '') {
                localStorage.setItem('incompleteUserDetails', true);
            }
            setisAction(false);
            if (res.status === 200) {
                setmsgClass('alert-danger');
                if (resJson.status === 1) {
                    setmsgClass('alert-success');
                    localStorage.setItem('usertype', 'student');
                    localStorage.setItem(
                        'loggedToken',
                        resJson.loggedToken ? resJson.loggedToken : 0
                    );
                    localStorage.setItem(
                        'userdata',
                        resJson.data ? JSON.stringify(resJson.data) : JSON.stringify({})
                    );
                    setTimeout(() => {
                        LoginRedirect();
                    }, 300);
                }
                setMessage(resJson.message);
            } else {
                setMessage(resJson.message);
            }
        } catch (err) {
            setisAction(false);
            console.log(err);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setisAction(true);
        setmsgClass('alert-warning');
        setMessage('Processing');
        try {
            const res = await fetch(`${BASEAPIURL}/student/login`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    email: Username,
                    password: Password,
                }),
            });
            const resJson = await res.json();
            setisAction(false);
            if (res.status === 200) {
                setmsgClass('alert-danger');
                if (resJson.status === 1) {
                    setmsgClass('alert-success');
                    localStorage.setItem('usertype', 'student');
                    localStorage.setItem(
                        'loggedToken',
                        resJson.loggedToken ? resJson.loggedToken : 0
                    );
                    localStorage.setItem(
                        'userdata',
                        resJson.data ? JSON.stringify(resJson.data) : JSON.stringify({})
                    );
                    setTimeout(() => {
                        LoginRedirect();
                    }, 300);
                }
                setMessage(resJson.message);
            } else {
                setMessage(resJson.message);
            }
        } catch (err) {
            setisAction(false);
            console.log(err);
        }
    };

    function FromPhoneInput(v) {
        setUsername(v);
        setShowOTPInp(false);
    }

    const handleSubmitOtp = async (e) => {
        e.preventDefault();
        setisAction(true);
        setmsgClass('alert-warning');
        setMessage('Processing');
        try {
            const res = await fetch(`${BASEAPIURL}/student/forgotPasswordOTPConfirm`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    mobile: Username,
                    password: Math.random(),
                    otp: OTP,
                }),
            });
            const resJson = await res.json();
            setisAction(false);
            if (res.status === 200) {
                setmsgClass('alert-danger');
                if (resJson.status === 1) {
                    setTimeout(() => {
                        if (resJson.verifyLink && resJson.verifyLink !== '') {
                            window.location.href = resJson.verifyLink;
                        }
                    }, 1500);
                    setmsgClass('alert-success');
                    setOTP('');
                }
                setMessage(resJson.message);
            } else {
                setMessage(resJson.message);
            }
        } catch (err) {
            setisAction(false);
            console.log(err);
        }
    };

    const handleSubmitFoggot = async (e) => {
        e.preventDefault();
        setisAction(true);
        setmsgClass('alert-warning');
        setMessage('Processing');
        let theURL = `${BASEAPIURL}/student/forgotPassword`;
        if (ForgotType === 'mobile') {
            theURL = `${BASEAPIURL}/student/forgotPasswordMobile`;
        }
        try {
            const res = await fetch(theURL, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    email: Username,
                    password: Password,
                }),
            });
            const resJson = await res.json();
            setisAction(false);
            if (res.status === 200) {
                setmsgClass('alert-danger');
                if (resJson.status === 1) {
                    if (ForgotType === 'mobile') {
                        setShowOTPInp(true);
                    } else {
                        setUsername('');
                    }
                    setmsgClass('alert-success');
                }
                setMessage(resJson.message);
            } else {
                setMessage(resJson.message);
            }
        } catch (err) {
            setisAction(false);
            console.log(err);
        }
    };

    useEffect(() => {
        if (token && token !== '' && action && action === 'gift') {
            localStorage.setItem('MsgBeforeLogin', 'Please Login to Grab the Gift Course.');
            localStorage.setItem(
                'RedirectAfterLogin',
                `${BASEURL}user/page/giftCourse?token=${token}&action=gift`
            );
        }
    }, [action]);

    return (
        <>
            <>
                {/* <AppFirebase /> */}
                {OTPLogin ? (
                    <LoginWithOTP setOTPLogin={setOTPLogin} UserSocialLogin={UserSocialLogin} />
                ) : (
                    ''
                )}
                <div className={`container pt-4 pb-4 ${OTPLogin ? 'd-none' : ''}`}>
                    <h4 className="text-center hide_empty_div text-dark">{MsgBeforeLogin}</h4>
                    <div className="row">
                        <div className="col-md-1"></div>

                        <div className="col-md-10">
                            <div className="mt-4 mb-4 ry_instr_shadow">
                                <div className="row">
                                    <div className="col-md-6 pt-4 d-none d-sm-block text-center">
                                        <img
                                            src={`${IMGBASEAPIURL}students/pages/registration.jpg`}
                                            alt="Docsta Learn Insinstructor Login"
                                            style={{ maxWidth: '250px' }}
                                            className="mt-4 mb-4"
                                        />
                                    </div>

                                    <div className="col-md-6">
                                        <form
                                            className="m-4 p-4 fpform"
                                            style={{ display: fpVisibility }}
                                            onSubmit={(e) => {
                                                if (ShowOTPInp) {
                                                    handleSubmitOtp(e);
                                                } else {
                                                    handleSubmitFoggot(e);
                                                }
                                            }}
                                        >
                                            <div className="form-group">
                                                <input
                                                    defaultChecked
                                                    type="radio"
                                                    id="fpemail"
                                                    name="fpType"
                                                    value="email"
                                                    onChange={(e) => {
                                                        setUsername('');
                                                        setForgotType('email');
                                                    }}
                                                />
                                                <label htmlFor="fpemail">&nbsp;Email ID </label>
                                                {localStorage.getItem('country') === 'India' ? (
                                                    <>
                                                        &nbsp;&nbsp;
                                                        <input
                                                            type="radio"
                                                            id="fpmobile"
                                                            name="fpType"
                                                            value="mobile"
                                                            onChange={(e) => {
                                                                setUsername('');
                                                                setForgotType('mobile');
                                                            }}
                                                        />
                                                        <label htmlFor="fpmobile">
                                                            &nbsp;Mobile
                                                        </label>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                                <div style={{ clear: 'both' }}></div>
                                            </div>

                                            <div
                                                className="mb-3 phoneNumberInpCont"
                                                style={{
                                                    position: 'relative',
                                                    display: ForgotType === 'mobile' ? '' : 'none',
                                                }}
                                            >
                                                <PhoneInput
                                                    defaultCountry={getDefaultCountry()}
                                                    international
                                                    required={ForgotType === 'mobile'}
                                                    placeholder="Enter Mobile No."
                                                    onChange={FromPhoneInput}
                                                />
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        left: '10px',
                                                        top: '14px',
                                                    }}
                                                >
                                                    <i className="fa fa-envelope"></i>
                                                </div>
                                            </div>
                                            {ShowOTPInp ? (
                                                <div
                                                    className="form-group"
                                                    style={{
                                                        position: 'relative',
                                                        display:
                                                            ForgotType === 'mobile' ? '' : 'none',
                                                    }}
                                                >
                                                    <input
                                                        type="number"
                                                        name="otp"
                                                        value={OTP}
                                                        required={setShowOTPInp}
                                                        onChange={(e) => setOTP(e.target.value)}
                                                        placeholder="Enter OTP"
                                                        className="form-control form-control-lg"
                                                    />
                                                </div>
                                            ) : (
                                                ''
                                            )}

                                            <div
                                                className="form-group"
                                                style={{
                                                    position: 'relative',
                                                    display: ForgotType === 'email' ? '' : 'none',
                                                }}
                                            >
                                                <input
                                                    type="email"
                                                    name="email"
                                                    required={ForgotType === 'email'}
                                                    onChange={(e) => setUsername(e.target.value)}
                                                    placeholder="Enter Email ID"
                                                    className="form-control form-control-lg"
                                                />
                                            </div>

                                            <div
                                                className={`${msgClass} alert message hide_empty_div mb-3`}
                                            >
                                                {message ? <span>{message}</span> : ''}
                                            </div>
                                            <div style={{ display: isAction ? 'none' : '' }}>
                                                <button
                                                    type="submit"
                                                    className="btn btn btn-docsta-instructor"
                                                    style={{ width: '100%' }}
                                                >
                                                    {ShowOTPInp ? 'Confirm OTP' : 'Reset Password'}
                                                </button>
                                            </div>
                                            <div className="text-right mt-2">
                                                <a
                                                    href="/"
                                                    className="text-dark"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setlfVisibility('block');
                                                        setfpVisibility('none');
                                                        setMessage('');
                                                    }}
                                                >
                                                    <b>Back to Login</b>
                                                </a>
                                            </div>
                                        </form>
                                        <form
                                            className="mt-4 ml-4 mr-4 p-0 p-4"
                                            onSubmit={handleSubmit}
                                            style={{ display: lfVisibility }}
                                        >
                                            <h6 className="d-block mb-2 d-sm-none">Login</h6>
                                            <div
                                                className="form-group"
                                                style={{ position: 'relative' }}
                                            >
                                                <input
                                                    type="email"
                                                    name="email"
                                                    required
                                                    placeholder="Enter Email ID"
                                                    className="form-control form-control-lg"
                                                    onChange={(e) => setUsername(e.target.value)}
                                                    value={Username}
                                                    style={{
                                                        paddingLeft: '35px',
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        left: '10px',
                                                        top: '14px',
                                                    }}
                                                >
                                                    <i className="fa fa-envelope"></i>
                                                </div>
                                            </div>
                                            <div
                                                className="form-group"
                                                style={{ position: 'relative' }}
                                            >
                                                <input
                                                    required
                                                    type={PasswordType}
                                                    name="password"
                                                    placeholder="Enter Password"
                                                    className="form-control form-control-lg"
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    value={Password}
                                                    style={{
                                                        paddingLeft: '35px',
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        left: '10px',
                                                        top: '14px',
                                                    }}
                                                >
                                                    <i className="fa fa-lock"></i>
                                                </div>
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        right: '10px',
                                                        bottom: '14px',
                                                    }}
                                                >
                                                    <i
                                                        className="fa fa-eye"
                                                        onClick={() => setPasswordType('text')}
                                                        style={{
                                                            display:
                                                                PasswordType === 'text'
                                                                    ? 'none'
                                                                    : '',
                                                        }}
                                                    ></i>
                                                    <i
                                                        className="fa fa-eye-slash"
                                                        onClick={() => setPasswordType('password')}
                                                        style={{
                                                            display:
                                                                PasswordType === 'password'
                                                                    ? 'none'
                                                                    : '',
                                                        }}
                                                    ></i>
                                                </div>
                                            </div>
                                            <div
                                                className={`${msgClass} alert message hide_empty_div mb-3`}
                                            >
                                                {message ? <span>{message}</span> : ''}
                                            </div>
                                            <div style={{ display: isAction ? 'none' : '' }}>
                                                <button
                                                    type="submit"
                                                    className="btn btn-lg btn-docsta-instructor"
                                                >
                                                    Login
                                                </button>
                                            </div>
                                            <div className="text-right mt-2">
                                                <a
                                                    href="/"
                                                    className="text-dark"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setlfVisibility('none');
                                                        setfpVisibility('block');
                                                    }}
                                                >
                                                    <b>Forgot Password?</b>
                                                </a>
                                            </div>
                                        </form>
                                        <div className="p-0 LoginSocialButtons">
                                            <div className="MobileLoginBtn d-none">
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        setOTPLogin(true);
                                                    }}
                                                >
                                                    <i className="far fa-mobile"></i>
                                                    Login with OTP
                                                </button>
                                            </div>

                                            <div className="GoogleLoginBtn mt-3">
                                                <div style={{ position: 'relative' }}>
                                                    <div className="cont">
                                                        <button type="button">
                                                            <img
                                                                src="https://google.com/favicon.ico"
                                                                alt="Sign in with Google"
                                                            />
                                                            Login with Google
                                                        </button>
                                                    </div>
                                                    <div style={{ opacity: 0 }}>
                                                        <GoogleOAuthProvider clientId="58750645601-7rufs34t6ii57vavv017v1qndec3pn27.apps.googleusercontent.com">
                                                            <GoogleLogin
                                                                logo_alignment="left"
                                                                width="190"
                                                                useOneTap={
                                                                    localStorage.getItem(
                                                                        'usertype'
                                                                    ) !== 'student'
                                                                }
                                                                onSuccess={(credentialResponse) => {
                                                                    SocialUsername = 'Google';
                                                                    SocialPassword = 'Pace2023#';
                                                                    UserSocialLogin(
                                                                        'Google',
                                                                        credentialResponse
                                                                    );
                                                                }}
                                                                onError={(error) => {
                                                                    alert(
                                                                        'Login Failded with Google, Try Another Methods.'
                                                                    );
                                                                    console.log(error);
                                                                }}
                                                            />
                                                        </GoogleOAuthProvider>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="FacebookLoginBtn mt-3">
                                                <FacebookLogin
                                                    appId="1748690968967851"
                                                    onSuccess={(response) => {
                                                        // console.log('Login Success!', response);
                                                        SocialUsername = response.userID;
                                                        SocialPassword = response.accessToken;
                                                    }}
                                                    onFail={(error) => {
                                                        // console.log('Login Failed!', error);
                                                    }}
                                                    onProfileSuccess={(response) => {
                                                        // console.log('Get Profile Success!', response);
                                                        UserSocialLogin('Facebook', response);
                                                    }}
                                                >
                                                    <img
                                                        src="https://facebook.com/favicon.ico"
                                                        alt="Sign in with Facebook"
                                                    />
                                                    Login with Facebook
                                                </FacebookLogin>
                                            </div>
                                        </div>
                                        <small
                                            className="mt-4 mb-4 d-block text-center"
                                            style={{ color: '#7b7b7b' }}
                                        >
                                            Don't have an Account?&nbsp;
                                            <Link to="/user/register" style={{ color: '#7f13ab' }}>
                                                Register Here
                                            </Link>
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                    </div>
                </div>
            </>
        </>
    );
}

export default UserLogin;
