import React, { useState } from 'react';
// import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import * as tus from 'tus-js-client';
import { BASEAPIURL } from '../../../../config';

function TusUpload({ t1, t2, t3, setVideoIDOnList }) {
    const [AddVideoID, setAddVideoID] = useState('');
    const [UploadFile, setUploadFile] = useState(false);
    const [file, setFile] = useState(null);
    const [DoneChunk, setDoneChunk] = useState(0);
    const [upload, setUpload] = useState(null);
    const [message, setMessage] = useState('');
    const [Msgcolor, setMsgcolor] = useState('');

    const onDrop = (acceptedFiles) => {
        // Assuming you want to upload a single file
        if (acceptedFiles && acceptedFiles[0]) {
            const extenstion = acceptedFiles[0].name.split('.').pop();
            if (extenstion !== 'mp4' && extenstion !== 'mov') {
                setMsgcolor('red');
                setMessage('Please uploaded only Video Files here, Supported format are mp4, mov');
            } else {
                setMsgcolor('#000');
                setMessage(acceptedFiles[0].name);
                setFile(acceptedFiles[0]);
            }
        } else {
            setMsgcolor('red');
            setMessage('The File is not uplodaed, Please try again.');
        }
    };
    // https://community.cloudflare.com/t/getting-cors-using-tus-direct-creator-uploads/230295
    // https://fueled.com/the-cache/posts/backend/using-tus-with-cloudflare/

    const handleUpload = () => {
        if (file) {
            setDoneChunk(1);
            const url = `${BASEAPIURL}/uploadChunk/generate-tus-upload-request?t1=${t1}&t2=${t2}&t3=${t3}`;
            const upload = new tus.Upload(file, {
                endpoint: url, // Replace with your Tus server URL
                chunkSize: 99 * 1024 * 1024,
                retryDelays: [0, 1000, 3000, 5000], // Optional retry delays
                onError: (error) => {
                    setMessage('Tus upload error');
                    console.log(error);
                    setMsgcolor('red');
                },
                onProgress: (bytesUploaded, bytesTotal) => {
                    const percentage = (bytesUploaded / bytesTotal) * 100;
                    setDoneChunk(percentage.toFixed(2));
                    setMsgcolor('blue');
                    setMessage(`Upload progress: ${percentage.toFixed(2)}%`);
                },
                onSuccess: (response) => {
                    FetchVideoID(response);
                    setMessage('Upload complete');
                    setMsgcolor('green');
                    console.log('##response Success##');
                    console.log(response);
                },
            });

            upload.start();
            setUpload(upload);
        } else {
            setMsgcolor('red');
            setMessage('Please Select a Video to Upload.');
        }
    };

    const FetchVideoID = async () => {
        // e.preventDefault();
        try {
            const res = await fetch(`${BASEAPIURL}/uploadChunk/FetchVideoID`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    t1,
                    t2,
                    t3,
                }),
            });
            const resJson = await res.json();
            if (res.status === 200 && resJson.status === 1) {
                setVideoIDOnList(resJson.VideoID);
            } else {
                console.log(resJson.message);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleCancel = () => {
        if (upload) {
            upload.abort();
            setMessage('Upload canceled');
        }
    };
    const { getRootProps, getInputProps } = useDropzone({ onDrop });
    return (
        <div>
            {/* <input type="file" onChange={handleFileChange} /> */}
            <div
                style={{
                    clear: 'both',
                    marginTop: '20px',
                }}
            >
                {/* <input
          type="radio"
          name="uploadType"
          value="upload"
          checked={UploadFile}
          onClick={() => {
            setUploadFile(true);
          }}
        />
        <span
          onClick={() => {
            setUploadFile(true);
          }}
        >
          &nbsp;Upload Video &nbsp;&nbsp;
        </span> */}
                <input
                    type="radio"
                    name="uploadType"
                    value="addVideoID"
                    checked={!UploadFile}
                    onClick={() => {
                        setUploadFile(false);
                    }}
                />
                <span
                    onClick={() => {
                        setUploadFile(true);
                    }}
                >
                    &nbsp;Add Video ID
                </span>
                <a
                    target="TPStreams"
                    rel="noopener noreferrer"
                    href="https://app.tpstreams.com"
                    style={{ float: 'right' }}
                >
                    <i className="fa fa-link"></i>
                </a>
                <div style={{ clear: 'both' }}></div>
            </div>
            <div
                className="form-group mt-1 text-center"
                style={{ display: UploadFile ? 'none' : '' }}
            >
                <input
                    className="form-control"
                    type="text"
                    name="videoID"
                    placeholder="Enter the Video ID of the Video (From TPStreams)"
                    onChange={(e) => {
                        setAddVideoID(e.target.value);
                    }}
                />
                <button
                    type="button"
                    className="btn btn-success mt-2"
                    onClick={(e) => {
                        setVideoIDOnList(AddVideoID);
                    }}
                >
                    Save
                </button>
            </div>
            <div
                {...getRootProps()}
                className="dropzone text-center"
                style={{
                    border: '2px dotted #dedede',
                    marginBottom: '20px',
                    marginTop: '20px',
                    padding: '30px 10px',
                    display: UploadFile ? '' : 'none',
                }}
            >
                <input type="file" {...getInputProps()} />
                <h5 className="text-center">
                    Drag & drop a file here, or click to select a file
                    <br />
                    <small style={{ color: Msgcolor }}>{message}</small>
                </h5>
            </div>

            <div
                style={{
                    position: 'relative',
                    lineHeight: '30px',
                    padding: '5px',
                    background: 'green',
                    display: DoneChunk === 0 ? 'none' : '',
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: '0px',
                        bottom: '0px',
                        left: `${DoneChunk}%`,
                        right: '0px',
                        background: 'white',
                    }}
                ></div>
                {DoneChunk}% / 100%
            </div>
            <div style={{ display: UploadFile ? '' : 'none' }}>
                <div
                    className="text-center"
                    style={{
                        display: DoneChunk === 0 || DoneChunk === 100 ? '' : 'none',
                    }}
                >
                    <button type="button" className="btn btn-success mr-2" onClick={handleUpload}>
                        Upload
                    </button>
                    <button type="button" className="btn btn-danger" onClick={handleCancel}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
}

export default TusUpload;
