import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import HTMLReactParser from 'html-react-parser';
// import Parser from 'html-react-parser';
import 'react-phone-number-input/style.css';
// import PhoneInput from 'react-phone-number-input';
import useToggle from '../../../../Hooks/useToggle';
import BackToTop from '../../../BackToTop';
import Footer from '../../../Helper/FooterInstitute';
import Drawer from '../../../Mobile/DrawerInstitute';
// import Header from '../Helper/Header';
import HeaderInstructor from '../../InstructorIndex_Insti';
import { IMGBASEAPIURL, BASEAPIURL, UPLOADNODEURL, BASEURL } from '../../../../config';
import '../../instructor.css';
import Editor from '../../../htmleditor/EditorTinyMCE';
import '../../../Helper/rypopup.css';
import LoginPage from '../Login';

const serialize = require('form-serialize');

function InstructorCreateBlog() {
    // const [file, setFile] = useState();
    const [SeeMore, SetSeeMore] = useState(0);
    // const [fileName, setFileName] = useState('');
    const [ImageUpload, setImageUpload] = useState(false);
    const [blogId, setblogId] = useState(localStorage.getItem('edit_blog_id'));
    const [BlogContent, setBlogContent] = useState(false);
    // for the Editors
    const [PageContent, setPageContent] = useState('');
    // for the Editors
    const [step1, setStep1] = useState('active');
    const [step2, setStep2] = useState('');
    const [step3, setStep3] = useState('');
    const [Category, setCategory] = useState([]);
    const [SubCategory, setSubCategory] = useState([]);
    const [drawer, drawerAction] = useToggle(false);
    const [PerDetDiv, setPerDetDiv] = useState('block');
    const [AddiDet1Div, setAddiDet1Div] = useState('None');
    const [AddiDet2Div, setAddiDet2Div] = useState('None');
    const [ConfirmationDiv, setConfirmationDiv] = useState('None');
    const [CVfile, setCVfile] = useState('');
    const [Msgcolor, setMsgcolor] = useState('');
    // const [Email, setEmail] = useState();
    const [APIMessage, setMessage] = useState();
    const [classforform, setclassforform] = useState('col-md-7');

    const form = document.querySelector('#Inst-Regi-Form');
    const obj = serialize(form, { hash: true, empty: true });
    const [regformdata, setForm] = useState({
        createdID: localStorage.getItem('tid'),
        createdBy: 'instructor',
        title: '',
        blogid: 0,
        image: '',
        description: '',
    });
    const handleForm = (name, value) => {
        setForm({ ...regformdata, [name]: value });
    };
    function ShowFormDiv(type) {
        let AllOkay = 1;
        // setclassforform('col-md-7');
        if (
            typeof regformdata.category === 'undefined' ||
            regformdata.category === 0 ||
            regformdata.category === '0'
        ) {
            AllOkay = 0;
            setMsgcolor('red');
            setMessage('Please Select the Category.');
        }

        if (
            typeof regformdata.title === 'undefined' ||
            regformdata.title === 0 ||
            regformdata.title === '0'
        ) {
            AllOkay = 0;
            setMsgcolor('red');
            setMessage('Please Enter the Blog Title.');
        }

        if (type === 'addi2' && regformdata.description.trim() === '') {
            AllOkay = 0;
            setMsgcolor('red');
            setMessage('Please Upload Blog Content.');
        }

        if (type === 'addi2' && CVfile.trim() === '') {
            AllOkay = 0;
            setMsgcolor('red');
            setMessage('Please Upload Blog Image.');
        }

        // console.log(regformdata);

        if (AllOkay === 1) {
            setPerDetDiv('none');
            setAddiDet1Div('none');
            setAddiDet2Div('none');
            setConfirmationDiv('none');
            setclassforform('col-md-7');
            setStep1(' ');
            setStep2(' ');
            setStep3(' ');
            switch (type) {
                case 'addi2':
                    setStep2('active');
                    setAddiDet2Div('block');
                    setclassforform('col-md-12');
                    break;
                case 'addi1':
                    setStep2('active');
                    setAddiDet1Div('block');
                    break;
                case 'confirm':
                    setStep3('active');
                    setclassforform('col-md-12 text-center');
                    setConfirmationDiv('block');
                    break;
                default:
                    setStep1('active');
                    setPerDetDiv('block');
                    break;
            }
        }
    }

    // for file uploade will be here
    async function uploadFile(file, fileName) {
        // e.preventDefault();
        const formData = new FormData();
        formData.append('file', file);
        formData.append('fileName', fileName);
        const url = `${BASEAPIURL}/admin/instructure/uploadcv`;
        // const url = 'https://ktuniexpo.com/women-directors/test.php';
        try {
            const res = await axios.post(url, formData);
            console.log(res);
            setCVfile(res.data.filename);
            handleForm('image', res.data.filename);
        } catch (ex) {
            console.log(ex);
        }
    }

    const saveFile = (e) => {
        setTimeout(() => {
            uploadFile(e.target.files[0], e.target.files[0].name);
        }, 500);
    };
    // for file uploade will be here

    const FetchCategory = async () => {
        // e.preventDefault();
        try {
            const res = await fetch(`${BASEAPIURL}/admin/blogcategory/getAll`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    SortStatus: 1,
                    parent: 0,
                    sortby: 'a2z',
                    recordsPerPage: 1000,
                    selectOnly: 1,
                }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                setCategory(resJson.data);
            } else {
                console.log(resJson.message);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const FetchSubCategory = async (e, parentid) => {
        // e.preventDefault();
        try {
            const res = await fetch(`${BASEAPIURL}/admin/blogcategory/getAll`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    parent: parentid,
                }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                setSubCategory(resJson.data);
            } else {
                console.log(resJson.message);
            }
        } catch (err) {
            console.log(err);
        }
    };

    async function RegistrerInstructor() {
        try {
            setMsgcolor('green');
            const url = `${BASEAPIURL}/blog/create`;
            // const url = 'https://ktuniexpo.com/women-directors/test.php';
            const formData = new FormData();
            formData.append('edit', 0);
            // get all the data and add there
            formData.append('image', CVfile);
            // Object.keys(obj).map(function(key) {
            Object.keys(obj).map((index) => {
                // formData.append(index, value);
                console.log(index);
                console.log(obj[index]);
                formData.append(index, obj[index]);
                return index;
            });
            const config = {
                headers: {
                    'content-type': 'application/json', // multipart/form-data
                },
            };
            axios.post(url, regformdata, config).then((response) => {
                console.log(response);
                setMessage(response.data.message);
            });
        } catch (err) {
            setMsgcolor('red');
            console.log(err);
            setMessage('Something went wrong, Please try again later.');
        }
    }

    // for login check here
    const [isLogin, setisLogin] = useState(false);
    // for login check here

    useEffect(() => {
        setblogId(localStorage.getItem('edit_blog_id'));
        FetchCategory();
        // get Blog Details
        const FetchBlogDetails = async (blogid) => {
            // e.preventDefault();
            try {
                const res = await fetch(`${BASEAPIURL}/admin/blog/GetBlogDetail`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        blogid,
                        createdID: localStorage.getItem('tid'),
                        createdBy: localStorage.getItem('usertype'),
                    }),
                });
                const resJson = await res.json();
                if (res.status === 200 && resJson.data && resJson.data.length > 0) {
                    setForm(resJson.data[0]);
                    console.log(resJson.data[0]);
                    setCVfile(resJson.data[0].image);
                    setPageContent(resJson.data[0].description);
                    FetchSubCategory(null, resJson.data[0].category);
                } else {
                    setForm({
                        createdID: localStorage.getItem('tid'),
                        createdBy: localStorage.getItem('usertype'),
                        title: '',
                        image: '',
                        description: '',
                    });
                }
            } catch (err) {
                console.log(err);
            }
        };
        FetchBlogDetails(blogId);
        // get Blog Details

        // for login check here
        const FetchProfile = async () => {
            try {
                const res = await fetch(`${BASEAPIURL}/admin/instructure/myprofile`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Basic ${localStorage.getItem('jwttoken')}`,
                    },
                    body: JSON.stringify({
                        token: localStorage.getItem('token'),
                    }),
                });
                const resJson = await res.json();
                if (res.status === 200) {
                    if (resJson.data !== null) {
                        if (
                            Object.keys(resJson.data).length > 0 &&
                            localStorage.getItem('usertype') === 'instructor'
                        ) {
                            setisLogin(true);
                            localStorage.setItem('tid', resJson.data.tid);
                        } else {
                            setisLogin(false);
                        }
                    } else {
                        setisLogin(false);
                    }
                } else {
                    setisLogin(false);
                    console.log(resJson.message);
                }
            } catch (err) {
                setisLogin(false);
                console.log(err);
            }
        };
        const isAdmin = localStorage.getItem('usertype');
        const isAdmintoken = localStorage.getItem('token');
        if (isAdmin && isAdmin === 'admin' && isAdmintoken) {
            setisLogin(true);
        } else {
            FetchProfile();
        }
        const interval = setInterval(() => {
            if (isAdmin && isAdmin === 'admin' && isAdmintoken) {
                setisLogin(true);
            } else {
                FetchProfile();
            }
        }, 5000);
        return () => clearInterval(interval);
        // for login check here
    }, [blogId]);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderInstructor drawer={drawer} action={drawerAction.toggle} />
            <div style={{ display: isLogin ? 'none' : '' }}>
                <LoginPage setisLogin={setisLogin} />
            </div>
            <div style={{ display: isLogin ? '' : 'none' }}>
                <div className="ryPopUpBack" style={{ display: ImageUpload ? '' : 'none' }}></div>
                <div className="ryPopUp" style={{ display: ImageUpload ? '' : 'none' }}>
                    <anew
                        href="#"
                        className="ryPopUpAct"
                        onClick={() => {
                            setImageUpload(false);
                        }}
                    >
                        X
                    </anew>
                    <div style={{ margin: '30px 50px' }}>
                        <h3>Blog Image</h3>
                        <div className="file_inp_cont">
                            {CVfile ? (
                                <img
                                    src={`${UPLOADNODEURL}${CVfile}`}
                                    alt=""
                                    style={{
                                        width: '150px',
                                        margin: '10px auto',
                                        display: 'block',
                                    }}
                                />
                            ) : (
                                ''
                            )}
                            <input
                                type="file"
                                name="cvfile"
                                className="ryfile"
                                onChange={(e) => {
                                    saveFile(e);
                                }}
                            />
                            <p>
                                Note: Only JPG, JPEG or PNG. Our suggested dimensions are 600px *
                                450px.
                                <br />
                                Larger Image will be cropped to 4:3 to fit out thumbnails/ Previews.
                            </p>
                        </div>
                        <anew
                            className="btn btn-docsta-instructor"
                            onClick={() => {
                                setImageUpload(false);
                            }}
                            style={{
                                width: '150px',
                                margin: '20px auto',
                            }}
                        >
                            Upload
                        </anew>
                    </div>
                </div>
                <div className="ryPopUpBack" style={{ display: BlogContent ? '' : 'none' }}></div>
                <div
                    className="ryPopUp"
                    style={{ display: BlogContent ? '' : 'none', maxWidth: '600px' }}
                >
                    <anew
                        href="#"
                        className="ryPopUpAct"
                        onClick={() => {
                            setBlogContent(false);
                        }}
                    >
                        X
                    </anew>
                    <div style={{ margin: '30px 50px' }}>
                        <h3 className="crearboth">Blog Content</h3>
                        <div style={{}}>
                            <Editor
                                PageContent={PageContent}
                                setPageContent={setPageContent}
                                HandleForm={handleForm}
                                ForAttribute="description"
                            />

                            <anew
                                className="btn btn-docsta-instructor"
                                onClick={() => {
                                    setBlogContent(false);
                                }}
                                style={{
                                    width: '150px',
                                    margin: '20px auto',
                                }}
                            >
                                Save
                            </anew>
                        </div>
                    </div>
                </div>
                <div className="container pt-4 pb-4">
                    <div
                        className="p-3 text-center"
                        style={{
                            background: `url(${IMGBASEAPIURL}instructor/headerbg.jpg)`,
                            backgroundRepeat: 'repeat',
                            backgroundSize: '100%',
                        }}
                    >
                        <h2 className="m-3 text-white">Submit A New Blog</h2>
                    </div>
                    <div className="row">
                        <div className="col-md-1"></div>

                        <div className="col-md-10">
                            <div className="mt-4 mb-4 ry_instr_shadow">
                                <div className="instr_signup_head d-none d-md-block">
                                    <div className="row">
                                        <div className="col-md-4 text-center">
                                            <h2 className={`mb-0 instr_signup_head_step ${step1}`}>
                                                1
                                            </h2>
                                            <b className="mb-2 d-block">Blog Details</b>
                                        </div>
                                        <div className="col-md-4 text-center">
                                            <h2 className={`mb-0 instr_signup_head_step ${step2}`}>
                                                2
                                            </h2>
                                            <b className="mb-2 d-block">Blog Content</b>
                                        </div>
                                        <div className="col-md-4 text-center">
                                            <h2 className={`mb-0 instr_signup_head_step ${step3}`}>
                                                3
                                            </h2>
                                            <b className="mb-2 d-block">Submit for Review</b>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className={classforform}>
                                        <form
                                            id="Inst-Regi-Form"
                                            encType="multipart/form-data"
                                            className="m-4 p-4"
                                            style={{ display: 'block' }}
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                RegistrerInstructor();
                                            }}
                                        >
                                            <div className="For_Form_title"></div>

                                            <div style={{ display: ConfirmationDiv }}>
                                                <img
                                                    src={`${IMGBASEAPIURL}instructor/inst_reg_done.jpg`}
                                                    alt="Docsta Learn Insinstructor"
                                                    style={{ maxWidth: '100%' }}
                                                    className="mt-1 mb-4"
                                                />
                                                <h3 className="mt-3">
                                                    Your request has been submitted successfully.
                                                </h3>
                                                <div
                                                    className="message mt-3"
                                                    style={{ color: Msgcolor }}
                                                >
                                                    {APIMessage ? <span>{APIMessage}</span> : ''}
                                                </div>
                                                <Link
                                                    to="instructor-dashboard-myblog"
                                                    className="btn btn-docsta-instructor d-inline-block m-3"
                                                    style={{ width: '150px' }}
                                                >
                                                    Back to My Blogs
                                                </Link>
                                            </div>

                                            <div style={{ display: PerDetDiv }}>
                                                <h4 className="mb-2">Create a new Blog</h4>
                                                <div className="form-group">
                                                    <label className="ry_label">Category</label>
                                                    <select
                                                        type="text"
                                                        name="category"
                                                        className="form-control"
                                                        value={regformdata.category}
                                                        onChange={(e) => {
                                                            handleForm(
                                                                e.target.name,
                                                                e.target.value
                                                            );
                                                        }}
                                                        onBlur={(e) => {
                                                            FetchSubCategory(e, e.target.value);
                                                        }}
                                                    >
                                                        <option key="0" value="0">
                                                            Select Category
                                                        </option>
                                                        {Category.map((singi) => (
                                                            <option
                                                                key={singi.blogcatid}
                                                                value={singi.blogcatid}
                                                            >
                                                                {singi.title}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>

                                                <div className="form-group">
                                                    <label className="ry_label">Sub Category</label>
                                                    <select
                                                        value={regformdata.subcategory}
                                                        type="text"
                                                        name="subcategory"
                                                        className="form-control"
                                                        onChangeCapture={(e) => {
                                                            handleForm(
                                                                e.target.name,
                                                                e.target.value
                                                            );
                                                        }}
                                                    >
                                                        <option key="0" value="0">
                                                            {SubCategory.length > 0
                                                                ? 'Select Sub Category'
                                                                : 'No Sub Category'}
                                                        </option>
                                                        {SubCategory.map((singi) => (
                                                            <option
                                                                key={singi.blogcatid}
                                                                value={singi.blogcatid}
                                                            >
                                                                {singi.title}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>

                                                <div className="form-group">
                                                    <label className="ry_label">Blog Title</label>
                                                    <input
                                                        value={regformdata.title}
                                                        type="text"
                                                        name="title"
                                                        className="form-control"
                                                        placeholder="Enter"
                                                        onChange={(e) => {
                                                            handleForm(
                                                                e.target.name,
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                </div>
                                                {localStorage.getItem('usertype') === 'admin' && (
                                                    <div className="form-group">
                                                        <label className="ry_label">Blog URL</label>
                                                        <input
                                                            value={regformdata.slug}
                                                            type="text"
                                                            name="slug"
                                                            className="form-control"
                                                            placeholder="Enter"
                                                            onChange={(e) => {
                                                                handleForm(
                                                                    e.target.name,
                                                                    e.target.value
                                                                );
                                                            }}
                                                        />
                                                        <small>
                                                            {BASEURL}blog/{regformdata.slug}
                                                        </small>
                                                    </div>
                                                )}

                                                <div
                                                    className="message mt-3"
                                                    style={{ color: Msgcolor }}
                                                >
                                                    {APIMessage ? <span>{APIMessage}</span> : ''}
                                                </div>

                                                <button
                                                    type="button"
                                                    className="btn btn-docsta-instructor"
                                                    onClick={() => {
                                                        ShowFormDiv('addi1');
                                                    }}
                                                >
                                                    Proceed
                                                </button>
                                            </div>

                                            <div style={{ display: AddiDet1Div }}>
                                                {/* <h4 className="mb-2">Additional Details</h4> */}
                                                <div className="form-group">
                                                    <label className="ry_label">Blog Content</label>
                                                    <anew
                                                        href="#"
                                                        onClick={() => {
                                                            setBlogContent(true);
                                                        }}
                                                        className="ryuploadAct"
                                                    >
                                                        Upload&nbsp;
                                                        <i className="fa fa-upload"></i>
                                                    </anew>
                                                </div>

                                                <div className="form-group">
                                                    <label className="ry_label">Blog Images</label>
                                                    <anew
                                                        href="#"
                                                        onClick={() => {
                                                            setImageUpload(true);
                                                        }}
                                                        className="ryuploadAct"
                                                    >
                                                        Upload&nbsp;
                                                        <i className="fa fa-upload"></i>
                                                    </anew>
                                                </div>
                                                <div
                                                    className="message mt-3"
                                                    style={{ color: Msgcolor }}
                                                >
                                                    {APIMessage ? <span>{APIMessage}</span> : ''}
                                                </div>

                                                <button
                                                    type="button"
                                                    className="btn btn-docsta-instructor"
                                                    onClick={() => {
                                                        ShowFormDiv('addi2');
                                                    }}
                                                >
                                                    Proceed
                                                </button>
                                            </div>

                                            <div style={{ display: AddiDet2Div }}>
                                                <h3>Blog Details</h3>
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <div className="mt-3 mb-3">
                                                            <b>Title</b>
                                                            <br />
                                                            <span>{regformdata.title}</span>
                                                        </div>
                                                        <div className="row">
                                                            {Category.map((singi) => (
                                                                <div
                                                                    key={singi.blogcatid}
                                                                    className="col-md-6 mt-3 mb-3"
                                                                    style={{
                                                                        display:
                                                                            Number(
                                                                                regformdata.category
                                                                            ) ===
                                                                            Number(singi.blogcatid)
                                                                                ? ''
                                                                                : 'none',
                                                                    }}
                                                                >
                                                                    <b>Category</b>
                                                                    <br />
                                                                    <span>{singi.title}</span>
                                                                </div>
                                                            ))}
                                                            {SubCategory.map((singi) => (
                                                                <div
                                                                    key={singi.blogcatid}
                                                                    className="col-md-6 mt-3 mb-3"
                                                                    style={{
                                                                        display:
                                                                            Number(
                                                                                regformdata.subcategory
                                                                            ) ===
                                                                            Number(singi.blogcatid)
                                                                                ? ''
                                                                                : 'none',
                                                                    }}
                                                                >
                                                                    <b>Category</b>
                                                                    <br />
                                                                    <span>{singi.title}</span>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <div className="mt-3 mb-3">
                                                            <b>Blog Content</b>
                                                            <div
                                                                style={{
                                                                    overflow:
                                                                        SeeMore === 1
                                                                            ? 'auto'
                                                                            : 'hidden',
                                                                    maxHeight:
                                                                        SeeMore === 1
                                                                            ? 'max-content'
                                                                            : '250px',
                                                                }}
                                                            >
                                                                {HTMLReactParser(
                                                                    regformdata.description
                                                                )}
                                                            </div>
                                                            <div className="text-right">
                                                                <anew
                                                                    href="#"
                                                                    style={{
                                                                        display:
                                                                            SeeMore === 1
                                                                                ? 'none'
                                                                                : '',
                                                                    }}
                                                                    onClick={() => {
                                                                        SetSeeMore(1);
                                                                    }}
                                                                >
                                                                    See More
                                                                </anew>
                                                                <anew
                                                                    href="#"
                                                                    style={{
                                                                        display:
                                                                            SeeMore === 1
                                                                                ? ''
                                                                                : 'none',
                                                                    }}
                                                                    onClick={() => {
                                                                        SetSeeMore(0);
                                                                    }}
                                                                >
                                                                    See Less
                                                                </anew>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        {CVfile ? (
                                                            <img
                                                                src={`${UPLOADNODEURL}${CVfile}`}
                                                                alt=""
                                                                style={{
                                                                    width: '100%',
                                                                    margin: '10px auto',
                                                                    display: 'block',
                                                                }}
                                                            />
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                </div>
                                                <div style={{ textAlign: 'left' }}>
                                                    <anew
                                                        href="#"
                                                        className="btn btn-info mr-3"
                                                        onClick={() => {
                                                            ShowFormDiv('initial');
                                                        }}
                                                        style={{
                                                            margin: '20px auto',
                                                            width: '150px',
                                                        }}
                                                    >
                                                        <i className="fa fa-edit"></i>
                                                        &nbsp;Edit
                                                    </anew>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-docsta-instructor d-inline-block"
                                                        onClick={() => {
                                                            ShowFormDiv('confirm');
                                                        }}
                                                        style={{
                                                            margin: '20px auto',
                                                            width: '150px',
                                                        }}
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="col-md-5 p-4 text-center">
                                        <img
                                            src={`${IMGBASEAPIURL}instructor/InstrSignStep01.png`}
                                            alt="Docsta Learn Insinstructor Login"
                                            style={{ display: PerDetDiv, marginTop: '60px' }}
                                            className="step_1_img"
                                        />
                                        <img
                                            src={`${IMGBASEAPIURL}instructor/InstrSignStep02.png`}
                                            alt="Docsta Learn Insinstructor Login"
                                            style={{ display: AddiDet1Div, marginTop: '40px' }}
                                            className="step_2_img"
                                        />
                                        {/* <img
                                            src={`${IMGBASEAPIURL}instructor/InstrSignStep03.png`}
                                            alt="Docsta Learn Insinstructor Login"
                                            style={{ display: AddiDet2Div }}
                                            className="step_3_img"
                                        /> */}
                                    </div>
                                </div>
                                <p className="text-center mt-1 d-none">
                                    Already have an Account ?
                                    <br />
                                    <Link to="/instructor/login" className="mb-4  mt-3">
                                        <button type="submit" className="btn btn-info">
                                            Login as Instructor
                                        </button>
                                    </Link>
                                </p>
                            </div>
                        </div>

                        <div className="col-md-1"></div>
                    </div>
                </div>
            </div>
            <Footer className="appie-footer-about-area" />
            <BackToTop />
        </>
    );
}

export default InstructorCreateBlog;
