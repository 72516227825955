import React, { Suspense } from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import homeBg1 from '../../assets/images/home-bg1.jpg';

import CounterUpCom from '../../lib/CounterUpCom';
import FeaturedCategoriesSlider from './FeaturedCategoriesSlider';
import { IMGBASEAPIURL } from '../../config';
// import HomeSlider from '../Institute/HomeSlider';

const TestimonialsBox = React.lazy(() => import('../Institute/TestimonialsBox'));
const SubscribeBox = React.lazy(() => import('../Institute/SubscribeBox'));
const HomeSlider = React.lazy(() => import('../Institute/HomeSlider'));
const TrustSlider = React.lazy(() => import('./TrustSlider'));
const TrustSliderFooter = React.lazy(() => import('./TrustSliderFooter'));
const HomeSliderBelowLearn = React.lazy(() => import('./HomeSliderBelowLearn'));
const HighlyPopular = React.lazy(() => import('./HighlyPopularCoursesSkills'));
const HighlyPopularCoursesQB = React.lazy(() => import('./HighlyPopularCoursesQB'));
const HighlyPopularCoursesExam = React.lazy(() => import('./HighlyPopularCoursesExam'));
const DownloadApp = React.lazy(() => import('./DownloadApp'));
// const ShowSearchedCourse = React.lazy(() => import('./ShowSearchedCourse'));
// const ShowSearchedKeyword = React.lazy(() => import('./ShowSearchedKeyword'));
const PopularCoursesBox = React.lazy(() => import('../Institute/PopularCoursesBox'));
// const TopQBCourses = React.lazy(() => import('./TopCoursesQB'));
const BeOurInstructor = React.lazy(() => import('./BeOurInstructor'));
const RequestTeamLearning = React.lazy(() => import('./RequestTeamLearning'));

function DocLearn() {
    return (
        <>
            {/* <Section01 /> */}
            <Suspense fallback={<div></div>}>
                <HomeSlider />
            </Suspense>
            <section className="appie-hero-area d-none">
                <div className="container-fluid p-0">
                    <div className="row align-items-center text-center">
                        <div className="col-lg-6 p-0">
                            <div className="appie-hero-content">
                                <span>Welcome To DocSta</span>
                                <h1 className="appie-title">
                                    Infinite
                                    <sup>+</sup>
                                </h1>
                                <p>
                                    Unlock all our Lifestyle Courses with a single Subscription.
                                    <br />
                                    Grab the Master Key to unlock your life potential.
                                </p>
                                <h3 className="appie-rate">
                                    $45
                                    <span>$90</span>
                                </h3>
                                <div className="appie-offer">
                                    <i className="fal fa-alarm-clock" />
                                    Special offer ends in<span>05h : 30m : 50s</span>
                                </div>
                                <ul>
                                    <li>
                                        <anew href="#">
                                            Join Now <i className="far fa-chevron-right" />
                                        </anew>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 p-0">
                            <div className="appie-hero-thumb">
                                <div>
                                    <img src={homeBg1} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <Section02 /> */}
            <FeaturedCategoriesSlider />
            {/* <Section03 /> */}
            <Suspense fallback={<div></div>}>
                <div className="mt-4 pt-4 pb-2 mb-3">
                    <TrustSlider />
                </div>
            </Suspense>
            <section className="appie-counter-area d-none d-sm-block" id="counter">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="appie-single-counter mt-30">
                                <div className="counter-content1">
                                    <div className="icon">
                                        <i className="fal fa-3x fa-chalkboard-teacher" />
                                    </div>
                                    <h3 className="title">
                                        <span className="counter-item">
                                            <CounterUpCom endValue={250} sectionSelect="counter" />
                                        </span>
                                        +
                                    </h3>
                                    <p style={{ fontWeight: 600 }}>Career Courses</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="appie-single-counter mt-30 item-2">
                                <div className="counter-content1">
                                    <div className="icon">
                                        <i className="fal fa-3x fa-user-tie" />
                                    </div>
                                    <h3 className="title">
                                        <span className="counter-item">
                                            <CounterUpCom endValue={120} sectionSelect="counter" />
                                        </span>
                                        +
                                    </h3>
                                    <p style={{ fontWeight: 600 }}>Expert Instructors</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="appie-single-counter mt-30 item-3">
                                <div className="counter-content1">
                                    <div className="icon">
                                        <i className="fal fa-3x fa-user-graduate" />
                                    </div>
                                    <h3 className="title">
                                        <span className="counter-item">
                                            <CounterUpCom
                                                endValue={12000}
                                                sectionSelect="counter"
                                            />
                                        </span>
                                        +
                                    </h3>
                                    <p style={{ fontWeight: 600 }}>Satisfied Students</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="appie-single-counter mt-30 item-4">
                                <div className="counter-content1">
                                    <div className="icon">
                                        <i className="fal fa-3x fa-badge-check" />
                                    </div>
                                    <h3 className="title">
                                        <span className="counter-item">
                                            <CounterUpCom endValue={100} sectionSelect="counter" />
                                        </span>
                                        +
                                    </h3>
                                    <p>Certified Courses</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <Section04 /> */}

            {Number(localStorage.getItem('screenwidth')) > 550 ? (
                <>
                    <div className="mt-4"></div>
                </>
            ) : (
                ''
            )}

            <section className="appie-traffic-area" style={{ paddingBottom: '0px' }}>
                <div className="">
                    <Suspense fallback={<div></div>}>
                        <HighlyPopular />
                    </Suspense>
                    <div className="mt-3 mb-3">
                        <div className="col-md-12">
                            <Suspense fallback={<div></div>}>
                                <HomeSliderBelowLearn />
                            </Suspense>
                        </div>
                    </div>
                </div>
            </section>
            {/* <Section05 /> */}
            <Suspense fallback={<div></div>}>
                <HighlyPopularCoursesExam />
            </Suspense>
            {/* <Section06 /> */}

            <Suspense fallback={<div></div>}>
                <RequestTeamLearning />
            </Suspense>
            {/* <Section07 /> */}
            <div className="">
                <Suspense fallback={<div></div>}>
                    <HighlyPopularCoursesQB />
                </Suspense>
            </div>
            {/* <Section09 /> */}
            {/* <Section08 /> */}
            <Suspense fallback={<div></div>}>
                <BeOurInstructor />
            </Suspense>
            {/* <Section10 /> */}
            <section
                className="appie-features-area examOriented"
                style={{ padding: '30px 0px 0px 0px' }}
            >
                <div className="container">
                    <Suspense fallback={<div></div>}>
                        <PopularCoursesBox MainCatID="skill" />
                    </Suspense>
                </div>
            </section>
            {/* <Section11 /> */}
            <Suspense fallback={<div></div>}>
                <div className="d-none">
                    <DownloadApp IMGBASEAPIURL={IMGBASEAPIURL} />
                </div>
            </Suspense>
            <Suspense fallback={<div></div>}>
                <div className="mt-5 mb-5">
                    <SubscribeBox />
                </div>
            </Suspense>
            {/* <Section12 /> */}
            {/* <section
                className="appie-features-area examOriented"
                style={{ padding: '10px 0px 0px' }}
            >
                <div className="container">
                    <Suspense fallback={<div></div>}>
                        <ShowSearchedCourse />
                    </Suspense>
                </div>
            </section> */}
            {/* <Section13 /> */}
            {/* <Section15 /> */}
            {/* <section
                className="d-none appie-features-area examOriented"
                style={{ padding: '10px 0px 30px' }}
            >
                <div className="container">
                    <Suspense fallback={<div></div>}>
                        <TopQBCourses />
                    </Suspense>
                </div>
            </section> */}
            <Suspense fallback={<div></div>}>
                <TestimonialsBox />
            </Suspense>
            <Suspense fallback={<div></div>}>
                <TrustSliderFooter />
            </Suspense>
            {/* <Section16 /> */}
        </>
    );
}

export default DocLearn;
