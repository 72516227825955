import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BASEAPIURL, BASEURL, UPLOADNODEURL, IMGBASEAPIURL } from '../../../../config';
import { RemoveCourseFromCart } from '../../functions';
import ReviewCourse from '../../../Learn/CourseStudent/ReviewCourse';

function ProfileMenu({ forpage }) {
    // for the Review Form
    const [CourseMongoID, setCourseMongoID] = useState(0);
    const [showReviewForm, setshowReviewForm] = useState(false);
    function FetchCoursesReview() {
        // console
    }
    // for the Review Form
    // for the Order Confimration
    const queryString = window.location.search;
    // Parse the query string into an object
    const params = new URLSearchParams(queryString);
    // Access individual parameters
    const order_id = params.get('order_id');
    const status = params.get('status');
    // for the Order Confimration
    const [BuyedCourse, SetBuyedCourse] = useState([]);
    const [DoneSessionBuyedCourse, SetDoneSessionBuyedCourse] = useState([]);
    const [BuyedQBank, SetBuyedQBank] = useState([]);
    const [DoneSessionBuyedQBank, SetDoneSessionBuyedQBank] = useState([]);
    const [userData, Setuserdata] = useState({ name: '' });
    const GerPurchasedCourseList = async (targetType) => {
        try {
            const res = await fetch(`${BASEAPIURL}/student/PurchasedCourseList`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
                },
                body: JSON.stringify({
                    targetType,
                    usertype: localStorage.getItem('usertype'),
                }),
            });
            const resJson = await res.json();
            if (resJson.data) {
                switch (targetType) {
                    case 'MainCourse':
                        SetBuyedCourse(resJson.data);
                        break;
                    case 'questionBank':
                        SetBuyedQBank(resJson.data);
                        break;
                    default:
                        break;
                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    const GerDoneSession = async (targetType) => {
        try {
            const res = await fetch(`${BASEAPIURL}/student/FetchDoneSessionSummary`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
                },
                body: JSON.stringify({
                    courseType: targetType,
                    usertype: localStorage.getItem('usertype'),
                }),
            });
            const resJson = await res.json();
            if (resJson.data) {
                switch (targetType) {
                    case 'MainCourse':
                        SetDoneSessionBuyedCourse(resJson.data);
                        break;
                    case 'questionBank':
                        SetDoneSessionBuyedQBank(resJson.data);
                        break;
                    default:
                        break;
                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    const DownloadCertificate = async (courseID, targetType, sessionCount) => {
        try {
            const res = await fetch(`${BASEAPIURL}/student/downloadCertificate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
                },
                body: JSON.stringify({
                    courseID: courseID,
                    courseType: targetType,
                    usertype: localStorage.getItem('usertype'),
                }),
            });
            const resJson = await res.json();
            if (resJson.status === 1) {
                setshowReviewForm(true);
                if (resJson && resJson.download && resJson.download !== '') {
                    window.location.href = resJson.download;
                }
            } else {
                alert(resJson.message);
            }
            console.clear();
        } catch (err) {
            console.log(err);
        }
    };

    function showTotalSessions(
        BuyedCourse,
        BuyedQBank,
        DoneSessionBuyedCourse,
        DoneSessionBuyedQBank
    ) {
        let send = 0;
        if (BuyedCourse && BuyedCourse.length > 0) {
            BuyedCourse.forEach((s) => {
                send = send + getDoneSession(DoneSessionBuyedCourse, s._id, s.sessionCount);
            });
        }
        if (BuyedQBank && BuyedQBank.length > 0) {
            BuyedQBank.forEach((s) => {
                send = send + getDoneSession(DoneSessionBuyedQBank, s._id, s.sessionCount);
            });
        }
        return send;
    }
    function getDoneSession(DoneSessionBuyedCourse, CourseID, totalSession) {
        let send = 0;
        if (DoneSessionBuyedCourse && DoneSessionBuyedCourse.length > 0) {
            DoneSessionBuyedCourse.map((s) => {
                if (s._id === CourseID) {
                    send = s.count;
                }
                return '';
            });
        }
        return send > totalSession ? totalSession : send;
    }

    useEffect(() => {
        const temp2 = localStorage.getItem('RedirectAfterLogin');
        if (temp2 && temp2 !== '') {
            // already
        } else {
            localStorage.setItem('RedirectAfterLogin', `${BASEURL}user/dashboard`);
            localStorage.setItem('MsgBeforeLogin', 'Please Login to Continue.');
        }
        const temp = localStorage.getItem('userdata');
        try {
            const resJson = JSON.parse(temp);
            Setuserdata(resJson);
        } catch (error) {
            console.error('Error parsing JSON:', error);
        }
        GerPurchasedCourseList('MainCourse');
        GerDoneSession('MainCourse');
        if (order_id && order_id !== '' && status && Number(status) === 1) {
            RemoveCourseFromCart();
        }
        GerPurchasedCourseList('questionBank');
        GerDoneSession('questionBank');
    }, []);
    return (
        <>
            {order_id && order_id !== '' && status && Number(status) === 1 ? (
                <>
                    <p className="alert alert-success">
                        Congratulations on your stepping stone to <b>SUCCESS.</b>
                    </p>
                </>
            ) : (
                ''
            )}
            {order_id && order_id !== '' && status && Number(status) === 2 ? (
                <>
                    <p className="alert alert-danger">
                        Uh-oh! Payment unsuccessful. <b>Try again</b> to unleash success.
                    </p>
                </>
            ) : (
                ''
            )}
            {/* for Giving the Review for the Course */}
            <div className="ryPopUpBack" style={{ display: showReviewForm ? '' : 'none' }}></div>
            <div
                className="ryPopUp"
                style={{
                    display: showReviewForm ? '' : 'none',
                    position: 'fixed',
                    height: 'fit-content',
                }}
            >
                <a
                    href="/"
                    className="ryPopUpAct"
                    onClick={(e) => {
                        e.preventDefault();
                        setshowReviewForm(false);
                    }}
                >
                    X
                </a>
                <div style={{ margin: '30px 50px' }}>
                    {showReviewForm ? (
                        <>
                            <ReviewCourse
                                TheCourseID={CourseMongoID}
                                setshowReviewForm={setshowReviewForm}
                                userdata={userData}
                                FetchCoursesReview={FetchCoursesReview}
                            />
                        </>
                    ) : (
                        ''
                    )}
                </div>
            </div>
            {/* for Giving the Review for the Course */}
            <div className="ry_instr_shadow p-4" forpage={forpage}>
                <div className="row std_db_counted">
                    <div className="col-6 mb-3 mb-md-0 col-md-3">
                        <div className="instr_tot_cours_updt d-flex">
                            <div className="icn">
                                <i className="fa fa-2x fa-books"></i>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginLeft: '8px',
                                }}
                            >
                                <span
                                    className="text-dark"
                                    style={{
                                        textAlign: 'initial',
                                        fontSize: '20px',
                                        fontWeight: '900',
                                    }}
                                >
                                    {BuyedCourse.length + BuyedQBank.length}
                                </span>
                                <b className="text-dark">Courses</b>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-md-3">
                        <div className="instr_tot_std d-flex">
                            <div className="icn2">
                                <i className="fal fa-2x fa-badge-check"></i>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginLeft: '8px',
                                }}
                            >
                                <span
                                    className="text-dark"
                                    style={{
                                        textAlign: 'initial',
                                        fontSize: '20px',
                                        fontWeight: '900',
                                    }}
                                >
                                    {showTotalSessions(
                                        BuyedCourse,
                                        BuyedQBank,
                                        DoneSessionBuyedCourse,
                                        DoneSessionBuyedQBank
                                    )}
                                    {/* {DoneSessionBuyedCourse.length} */}
                                </span>
                                <b className="text-dark">Sessions</b>
                            </div>
                        </div>
                    </div>
                    <div className="col-6  col-md-3">
                        <div className="instr_tot_webi d-flex">
                            <div className="icn3">
                                <i className="fal fa-2x fa-chalkboard-teacher"></i>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginLeft: '8px',
                                }}
                            >
                                <span
                                    className="text-dark"
                                    style={{
                                        textAlign: 'initial',
                                        fontSize: '20px',
                                        fontWeight: '900',
                                    }}
                                >
                                    0
                                </span>
                                <b className="text-dark">webinars</b>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-md-3">
                        <div className="instr_tot_blog d-flex">
                            <div className="icn4">
                                <i className="fa fa-2x fa-books"></i>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginLeft: '8px',
                                    fontWeight: '900',
                                }}
                            >
                                <span
                                    className="text-dark"
                                    style={{
                                        textAlign: 'initial',
                                        fontSize: '20px',
                                    }}
                                >
                                    0
                                </span>
                                <b className="text-dark">Certificates</b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ry_instr_shadow p-4 mt-4" forpage={forpage}>
                {/* <img
                    src={`${IMGBASEAPIURL}instructor/instr_graph.jpg`}
                    alt="Inc Profile"
                    className="m-2"
                /> */}
                <div className="row">
                    <div className="stu_dash_course col-lg-12 mb-3">
                        <h5>
                            {userData && userData.name ? userData.name : ''}, Let's continue
                            learning
                        </h5>
                    </div>
                </div>
                <div
                    className="col-md-12 std_header_on_page"
                    style={{
                        backgroundColor: '#f0eeee',
                        marginTop: '50px',
                        borderRadius: '5px',
                        padding: '20px',
                        textAlign: 'center',
                        display:
                            BuyedCourse &&
                            BuyedCourse.length === 0 &&
                            BuyedQBank &&
                            BuyedQBank.length === 0
                                ? ''
                                : 'none',
                    }}
                >
                    <p>No Courses Bought yet.</p>
                    <br />
                    <Link
                        to="/courses-all"
                        target="_BLANK"
                        className="btn btn btn-docsta-instructor"
                        style={{
                            width: 'max-content',
                            margin: '0px auto',
                        }}
                    >
                        Browse Courses now
                    </Link>
                </div>
                <div className="row">
                    <div className="stu_dash_course col-lg-12">
                        {BuyedCourse.map((s) => (
                            <div
                                key={s._id}
                                style={{
                                    border: '1px solid #f5f5f5',
                                    background: '#fff',
                                    marginBottom: '30px',
                                    padding: '10px',
                                }}
                            >
                                {console.error(s)}
                                <div className="row">
                                    <div className="col-md-4">
                                        <a
                                            href={`${BASEURL}courseStudent/${s.slug}`}
                                            target="_MainCourse"
                                            rel="noreferrer"
                                        >
                                            <img
                                                src={`${UPLOADNODEURL}${s.image}`}
                                                alt=""
                                                style={{
                                                    objectFit: 'cover',
                                                    height: '150px',
                                                    width: '100%',
                                                }}
                                            />
                                        </a>
                                    </div>
                                    <div className="col-md-8">
                                        <div>
                                            <a
                                                href={`${BASEURL}courseStudent/${s.slug}`}
                                                target="_MainCourse"
                                                rel="noreferrer"
                                            >
                                                <h4>{s.title}</h4>
                                                <p>{s.metaDescription}</p>
                                            </a>
                                            <br />
                                            <a
                                                href={`${BASEURL}courseStudent/${s.slug}`}
                                                target="_MainCourse"
                                                rel="noreferrer"
                                                className="btn mt-2 buynow-btn"
                                                style={{
                                                    width: 'max-content',
                                                    borderRadius: '0px',
                                                    background: '#7f13ab',
                                                    color: '#f5f5f5',
                                                }}
                                            >
                                                Resume (
                                                {getDoneSession(
                                                    DoneSessionBuyedCourse,
                                                    s._id,
                                                    s.sessionCount
                                                )}
                                                /{s.sessionCount} Sessions )
                                            </a>
                                            {userData && userData.name && s.section === 'skill' ? (
                                                <>
                                                    &nbsp;&nbsp;
                                                    <a
                                                        href="/"
                                                        download
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setCourseMongoID(s._id);
                                                            DownloadCertificate(
                                                                s._id,
                                                                'MainCourse',
                                                                s.sessionCount
                                                            );
                                                        }}
                                                        rel="noreferrer"
                                                        className="btn mt-2 buynow-btn"
                                                        style={{
                                                            width: 'max-content',
                                                            borderRadius: '0px',
                                                            cursor:
                                                                Number(
                                                                    getDoneSession(
                                                                        DoneSessionBuyedCourse,
                                                                        s._id,
                                                                        s.sessionCount
                                                                    )
                                                                ) >= s.sessionCount
                                                                    ? ''
                                                                    : 'not-allowed',
                                                        }}
                                                    >
                                                        Download Certificate
                                                    </a>
                                                </>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                        {BuyedQBank.map((s) => (
                            <div
                                key={s._id}
                                style={{
                                    border: '1px solid #f5f5f5',
                                    background: '#fff',
                                    marginBottom: '30px',
                                    padding: '10px',
                                }}
                            >
                                <div className="row">
                                    <div className="col-md-4">
                                        <a
                                            href={`${BASEURL}courseStudent/${s.slug}`}
                                            target="_MainCourse"
                                            rel="noreferrer"
                                        >
                                            <img
                                                src={`${UPLOADNODEURL}${s.image}`}
                                                alt=""
                                                style={{
                                                    objectFit: 'cover',
                                                    height: '150px',
                                                    width: '100%',
                                                }}
                                            />
                                        </a>
                                    </div>
                                    <div className="col-md-8">
                                        <div>
                                            <a
                                                href={`${BASEURL}courseQBStudent/${s.slug}`}
                                                target="_MainCourse"
                                                rel="noreferrer"
                                            >
                                                <h4>{s.title}</h4>
                                                <p>{s.metaDescription}</p>
                                            </a>
                                            <br />
                                            <a
                                                href={`${BASEURL}courseQBStudent/${s.slug}`}
                                                target="_MainCourse"
                                                rel="noreferrer"
                                                className="btn mt-2 buynow-btn"
                                                style={{
                                                    width: 'max-content',
                                                    borderRadius: '0px',
                                                    background: '#7f13ab',
                                                    color: '#f5f5f5',
                                                }}
                                            >
                                                Resume (
                                                {getDoneSession(
                                                    DoneSessionBuyedQBank,
                                                    s._id,
                                                    s.sessionCount
                                                )}
                                                /{s.sessionCount} Test )
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className="d-none student_dashCard col-lg-12">
                            <div className="col-lg-4 stu_dash_card d-flex">
                                <div className="col-lg-4">
                                    <img
                                        src={`${IMGBASEAPIURL}instructor/course.png`}
                                        alt="Inc Profile"
                                        className="mr-2 ry-pull-left"
                                    />
                                </div>
                                <div className="col-lg-8">
                                    <h4>Schedule Time to Learn</h4>
                                    <p>
                                        a Little each day adds up. Get <br />
                                        reminders from your calendar.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4 stu_dash_card">world</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProfileMenu;
