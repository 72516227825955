import React, { useEffect } from 'react';
// import { Helmet } from 'react-helmet';
import useToggle from '../../../Hooks/useToggle';
import BackToTop from '../../BackToTop';
import Drawer from '../../Mobile/DrawerInstitute';
import Header from '../../Helper/HeaderInstitute';
import Footer from '../../Helper/FooterInstitute';
import ContactIconFooter from '../../Helper/ContactIconFooter';
import ContactUs from './ContactUs';

function DocInstitute() {
    const [drawer, drawerAction] = useToggle(false);

    useEffect(() => {
        document.title = 'Contact Us';
    }, []);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <ContactUs />
            <Footer />
            <BackToTop />
            <ContactIconFooter />
        </>
    );
}

export default DocInstitute;
