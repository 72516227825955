import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// import Parser from 'html-react-parser';
// import useToggle from '../../../../Hooks/useToggle';
// import BackToTop from '../../../BackToTop';
// import Footer from '../../../Helper/FooterInstitute';
// import Drawer from '../../../Mobile/DrawerInstitute';
// // import Header from '../Helper/Header';
// import HeaderInstructor from '../../InstructorIndex_Insti';
import { IMGBASEAPIURL, BASEAPIURL, setCookie, BASEURL } from '../../../../config';
import { UpdateAccountData } from '../../../../reduxFunction/instructorSlice';
import '../../instructor.css';
import { useDispatch } from 'react-redux';

function InstructorLogin({ setisLogin }) {
    const dispatch = useDispatch();
    // const history = useHistory();
    // const [drawer, drawerAction] = useToggle(false);
    const [lfVisibility, setlfVisibility] = useState('block');
    const [fpVisibility, setfpVisibility] = useState('none');
    const [msgcolor, setMsgcolor] = useState('red');
    const [Username, setUsername] = useState('');
    const [Password, setPassword] = useState('');
    const [PasswordType, setPasswordType] = useState('password');
    const [message, setMessage] = useState('');
    const [Remembeme, setRemembeme] = useState(1);
    // console.log(getCookie('token'));
    const temp = 9999 + Math.random() * (9999999999 - 9999);
    const handleSubmit = async (e) => {
        e.preventDefault();
        let fordays = 1;
        try {
            const res = await fetch(`${BASEAPIURL}/admin/instructure/login?v=${temp}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    username: Username,
                    password: Password,
                }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                setMsgcolor('red');
                if (resJson.status === 1) {
                    setMsgcolor('green');
                    if (Remembeme === 1) {
                        fordays = 30;
                    } else {
                        fordays = 1;
                    }
                    setCookie('token', resJson.token, fordays);
                    setCookie('jwttoken', resJson.jwttoken, fordays);
                    setCookie('username', resJson.data.name, fordays);
                    setCookie('username', resJson.data.email, fordays);
                    localStorage.setItem('token', resJson.token);
                    localStorage.setItem('username', resJson.data.name);
                    localStorage.setItem('useremail', resJson.data.email);
                    localStorage.setItem('usertype', 'instructor');
                    localStorage.setItem('tid', resJson.data.tid);
                    localStorage.setItem('jwttoken', resJson.jwttoken);
                    window.location.href = `${BASEURL}instructor-dashboard`;
                    // setisLogin(true);
                }
                setMessage(resJson.message);
            } else {
                setMessage(resJson.message);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();
        setMessage('Processing Request...');
        try {
            const res = await fetch(`${BASEAPIURL}/admin/instructure/resetPassword?v=${temp}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    email: Username,
                    password: Password,
                }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                setMsgcolor('red');
                if (resJson.status === 1) {
                    setMsgcolor('green');
                    setUsername('');
                }
                setMessage(resJson.message);
            } else {
                setMessage(resJson.message);
            }
        } catch (err) {
            setMsgcolor('red');
            setMessage(
                'Unable to proccess the Request, Please try again later or try with Refreshing the Page.'
            );
            console.log(err);
        }
    };

    useEffect(() => {
        dispatch(UpdateAccountData({ ChartData: [], dataCourse: [], dataBanks: [] }));
    }, []);

    return (
        <>
            {/* <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderInstructor drawer={drawer} action={drawerAction.toggle} /> */}
            <>
                <div className="container pt-4 pb-4">
                    <div
                        className="p-3 text-center"
                        style={{
                            background: `url(${IMGBASEAPIURL}instructor/headerbg.jpg)`,
                            backgroundRepeat: 'repeat',
                            backgroundSize: '100%',
                        }}
                    >
                        <h2 className="m-3 text-white">Login as Instructor</h2>
                    </div>
                    <div className="row">
                        <div className="col-md-1"></div>

                        <div className="col-md-10">
                            <div className="mt-4 mb-4 ry_instr_shadow">
                                <div className="row">
                                    <div className="col-md-7">
                                        <form
                                            autoComplete="off"
                                            onSubmit={handleResetPassword}
                                            className="m-4 p-4 fpform"
                                            style={{ display: fpVisibility }}
                                        >
                                            <div className="form-group mt-4">
                                                <label>
                                                    <b>Email ID</b>
                                                </label>
                                                <input
                                                    required
                                                    type="email"
                                                    name="email"
                                                    value={Username}
                                                    onChange={(e) => setUsername(e.target.value)}
                                                    placeholder="Enter Email ID"
                                                    className="form-control"
                                                    autoComplete="off"
                                                />
                                            </div>
                                            <div
                                                className="message mb-3"
                                                style={{ color: msgcolor }}
                                            >
                                                {message ? <span>{message}</span> : ''}
                                            </div>
                                            <button
                                                type="submit"
                                                className="btn btn-docsta-instructor"
                                                style={{ width: '100%' }}
                                            >
                                                Reset Password
                                            </button>
                                            <div className="text-right mt-2">
                                                <anew
                                                    href="#"
                                                    className="text-dark"
                                                    onClick={() => {
                                                        setlfVisibility('block');
                                                        setfpVisibility('none');
                                                    }}
                                                >
                                                    <a
                                                        href="/"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setMessage('');
                                                        }}
                                                    >
                                                        <b>Back to Login</b>
                                                    </a>
                                                </anew>
                                            </div>
                                        </form>
                                        <form
                                            className="m-4 p-4"
                                            onSubmit={handleSubmit}
                                            style={{ display: lfVisibility }}
                                        >
                                            <div className="form-group">
                                                <label>
                                                    <b>Email ID</b>
                                                </label>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    placeholder="Enter Email ID"
                                                    className="form-control"
                                                    onChange={(e) => setUsername(e.target.value)}
                                                    value={Username}
                                                    required
                                                />
                                            </div>
                                            <div
                                                className="form-group"
                                                style={{ position: 'relative' }}
                                            >
                                                <label>
                                                    <b>Password</b>
                                                </label>
                                                <input
                                                    type={PasswordType}
                                                    name="password"
                                                    placeholder="Enter Password"
                                                    className="form-control"
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    value={Password}
                                                    required
                                                />
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        right: '10px',
                                                        bottom: '10%',
                                                    }}
                                                >
                                                    <i
                                                        className="fa fa-eye"
                                                        onClick={() => setPasswordType('text')}
                                                        style={{
                                                            display:
                                                                PasswordType === 'text'
                                                                    ? 'none'
                                                                    : '',
                                                        }}
                                                    ></i>
                                                    <i
                                                        className="fa fa-eye-slash"
                                                        onClick={() => setPasswordType('password')}
                                                        style={{
                                                            display:
                                                                PasswordType === 'password'
                                                                    ? 'none'
                                                                    : '',
                                                        }}
                                                    ></i>
                                                </div>
                                            </div>
                                            <div className="text-right mt-2 d-none">
                                                <input
                                                    type="checkbox"
                                                    value="1"
                                                    name="remembeme"
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setRemembeme(1);
                                                        } else {
                                                            setRemembeme(0);
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div
                                                className="message mb-3"
                                                style={{ color: msgcolor }}
                                            >
                                                {message ? <span>{message}</span> : ''}
                                            </div>
                                            <button
                                                type="submit"
                                                className="btn btn-docsta-instructor"
                                            >
                                                Login
                                            </button>
                                            <div className="text-right mt-2">
                                                <a
                                                    href="/"
                                                    className="text-dark"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setlfVisibility('none');
                                                        setfpVisibility('block');
                                                    }}
                                                >
                                                    <b>Forgot Password?</b>
                                                </a>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="col-md-5 p-4 text-center">
                                        <img
                                            src={`${IMGBASEAPIURL}instructor/loginimg.png`}
                                            alt="Docsta Learn Insinstructor Login"
                                            style={{ maxWidth: '250px' }}
                                            className="mt-4 mb-4"
                                        />
                                    </div>
                                </div>
                                <p className="text-center mt-1">
                                    If you don't have an Instructor Account, You can create one with
                                    the below link.
                                    <br />
                                    <Link to="/instructor-register" className="mb-4  mt-3">
                                        <button type="submit" className="btn btn-info">
                                            Become an Instructor
                                        </button>
                                    </Link>
                                </p>
                            </div>
                        </div>

                        <div className="col-md-1"></div>
                    </div>
                </div>
            </>
            {/* <Footer className="appie-footer-about-area" />
            <BackToTop /> */}
        </>
    );
}

export default InstructorLogin;
