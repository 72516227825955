import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import HTMLReactParser from 'html-react-parser';
// import Parser from 'html-react-parser';
import 'react-phone-number-input/style.css';
// import PhoneInput from 'react-phone-number-input';
import useToggle from '../../../../Hooks/useToggle';
import BackToTop from '../../../BackToTop';
import Footer from '../../../Helper/FooterInstitute';
import Drawer from '../../../Mobile/DrawerInstitute';
// import Header from '../Helper/Header';
import HeaderInstructor from '../../InstructorIndex_Insti';
import { IMGBASEAPIURL, BASEAPIURL, UPLOADNODEURL, BASEURL } from '../../../../config';
import '../../instructor.css';
// import Editor from '../../../htmleditor/EditorTinyMCE';
import '../../../Helper/rypopup.css';
import LoginPage from '../Login';

function InstructorCreateFreeTips() {
    const [Loader, SetLoader] = useState(false);
    const [PlayAudio, SetPlayAudio] = useState('');
    // const [file, setFile] = useState();
    const [SeeMore, SetSeeMore] = useState(0);
    // const [fileName, setFileName] = useState('');
    const [ImageUpload, setImageUpload] = useState(false);
    const [FreeTipAutoIp, setFreeTipAutoIp] = useState(localStorage.getItem('edit_freetip_id'));
    const [step1, setStep1] = useState('active');
    const [step2, setStep2] = useState('');
    const [step3, setStep3] = useState('');
    const [Category, setCategory] = useState([]);
    const [SubCategory, setSubCategory] = useState([]);
    const [drawer, drawerAction] = useToggle(false);
    const [PerDetDiv, setPerDetDiv] = useState('block');
    const [AddiDet1Div, setAddiDet1Div] = useState('None');
    const [AddiDet2Div, setAddiDet2Div] = useState('None');
    const [ConfirmationDiv, setConfirmationDiv] = useState('None');
    const [CVfile, setCVfile] = useState('');
    const [Msgcolor, setMsgcolor] = useState('');
    // const [Email, setEmail] = useState();
    const [APIMessage, setMessage] = useState();
    const [classforform, setclassforform] = useState('col-md-12');

    // const form = document.querySelector('#Inst-Regi-Form');
    // const obj = serialize(form, { hash: true, empty: true });
    const [regformdata, setForm] = useState({
        freetipautoip: 0,
        createdID: localStorage.getItem('tid'),
        createdBy: 'instructor',
        title: '',
        FreeTipAutoIp: 0,
        image: '',
        description: '',
        agegroup: '',
        language: '',
        listner: 0,
        category: 0,
        subcategory: 0,
    });
    const handleForm = (name, value) => {
        setForm({ ...regformdata, [name]: value });
    };
    function ShowFormDiv(type) {
        setMessage('');
        let AllOkay = 1;

        if (regformdata.description.trim() === '') {
            AllOkay = 0;
            setMsgcolor('red');
            setMessage('Please Write a Description.');
        }

        if (
            typeof regformdata.category === 'undefined' ||
            regformdata.category === 0 ||
            regformdata.category === '0'
        ) {
            AllOkay = 0;
            setMsgcolor('red');
            setMessage('Please Select the Category.');
        }

        if (
            typeof regformdata.title === 'undefined' ||
            regformdata.title === 0 ||
            regformdata.title === ''
        ) {
            AllOkay = 0;
            setMsgcolor('red');
            setMessage('Please Enter the Topic Name.');
        }

        if (AllOkay === 1) {
            setPerDetDiv('none');
            setAddiDet1Div('none');
            setAddiDet2Div('none');
            setConfirmationDiv('none');
            setclassforform('col-md-12');
            setStep1(' ');
            setStep2(' ');
            setStep3(' ');
            switch (type) {
                case 'addi2':
                    setStep2('active');
                    setAddiDet2Div('block');
                    setclassforform('col-md-12');
                    break;
                case 'addi1':
                    setStep2('active');
                    setAddiDet1Div('block');
                    break;
                case 'confirm':
                    setStep3('active');
                    setclassforform('col-md-12 text-center');
                    setConfirmationDiv('block');
                    break;
                default:
                    setStep1('active');
                    setPerDetDiv('block');
                    break;
            }
        }
    }

    // for file uploade will be here

    async function fetchAudioDuration(event) {
        const file = event.target.files[0];
        if (!file) return; // No file selected

        const audio = new Audio();
        audio.src = URL.createObjectURL(file);

        audio.onloadedmetadata = () => {
            setForm({
                ...regformdata,
                duration: audio.duration ? Number(audio.duration).toFixed(0) : '',
            });
            // console.log('Duration:', duration, 'seconds');
        };
    }

    async function uploadFile(file, fileName, run) {
        // e.preventDefault();
        setImageUpload(false);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('fileName', fileName);
        const url = `${BASEAPIURL}/admin/instructure/uploadcv`;
        try {
            const res = await axios.post(url, formData);
            // console.log(res);
            setCVfile(res.data.filename);
            // await fetchAudioDuration(res.data.filename);
            setAudioList((temp) => [
                ...temp,
                {
                    index: run,
                    title: '',
                    audio: res.data.filename,
                },
            ]);
            SetLoader(false);
        } catch (ex) {
            SetLoader(false);
            alert(ex.message);
        }
    }

    async function uploadImage(file, fileName) {
        // uploadImage(e.target.files[0], e.target.files[0].name);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('fileName', fileName);
        const url = `${BASEAPIURL}/admin/instructure/uploadcv`;
        try {
            const res = await axios.post(url, formData);
            if (res.data && res.data.filename) {
                setForm({ ...regformdata, image: res.data.filename });
            }
            SetLoader(false);
        } catch (ex) {
            SetLoader(false);
            alert(ex.message);
        }
    }

    const saveFile = (e) => {
        // RefreshAudioList();
        SetLoader(true);
        setTimeout(() => {
            const temp = Number(AudioList.length) + 1;
            // handleAddAudioList(temp);
            uploadFile(e.target.files[0], e.target.files[0].name, temp);
        }, 500);
    };
    // for file uploade will be here

    const FetchCategory = async () => {
        // e.preventDefault();
        try {
            const res = await fetch(`${BASEAPIURL}/admin/freetipscategory/getAll`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    SortStatus: 1,
                    parent: 0,
                    sortby: 'a2z',
                    recordsPerPage: 1000,
                    selectOnly: 1,
                }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                setCategory(resJson.data);
            } else {
                console.log(resJson.message);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const FetchSubCategory = async (e, parentid) => {
        // e.preventDefault();
        try {
            const res = await fetch(`${BASEAPIURL}/admin/freetipscategory/getAll`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    parent: parentid,
                }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                setSubCategory(resJson.data);
            } else {
                console.log(resJson.message);
            }
        } catch (err) {
            console.log(err);
        }
    };

    async function RegistrerInstructor() {
        try {
            setMsgcolor('green');
            const url = `${BASEAPIURL}/admin/freetips/create`;
            const config = {
                headers: {
                    'content-type': 'application/json', // multipart/form-data
                },
            };
            axios.post(url, regformdata, config).then((response) => {
                ShowFormDiv('confirm');
                setMessage(response.data.message);
            });
        } catch (err) {
            setMsgcolor('red');
            ShowFormDiv('confirm');
            setMessage('Something went wrong, Please try again later.');
        }
    }
    const PrimaryIndex = '_id';
    async function DeleteAudioFile(fileid) {
        // alert(fileid);
        try {
            const url = `${BASEAPIURL}/admin/freetips/DeleteAudioFile`;
            const config = {
                headers: {
                    'content-type': 'application/json', // multipart/form-data
                },
            };
            axios.post(url, { fileid }, config).then((response) => {
                console.log(response);
            });
        } catch (err) {
            setMessage('Something went wrong, Please try again later.');
        }
    }

    // for for Multiple Audio List
    const defaultAudioList = [];
    const [AudioList, setAudioList] = useState(defaultAudioList);
    const handleAddAudioList = (index) => {
        // ExpensesTypeSelected.push(1);
        setAudioList((temp) => [
            ...temp,
            {
                index,
                title: '',
                audio: '',
            },
        ]);
    };
    const handleEditPortfolio = (changeId, ind, val) => {
        AudioList[changeId][ind] = val;
    };
    const handleRemoveAudioList = (ind) => {
        // if (ind !== 1) {}
        setAudioList(AudioList.filter((item) => item.index !== ind));
    };
    const RefreshAudioList = () => {
        // const temp = Number(AudioList.length) + 1;
        const temp = 999999;
        handleAddAudioList(temp);
        handleRemoveAudioList(temp);
        handleForm('AudioList', AudioList);
    };
    // for Multiple Audio List
    // for login check here
    const [isLogin, setisLogin] = useState(false);
    // for login check here
    useEffect(() => {
        // audioIndex = 0;
        setFreeTipAutoIp(localStorage.getItem('edit_freetip_id'));
        FetchCategory();
        // get Blog Details
        const FetchBlogDetails = async (freetipautoip) => {
            // e.preventDefault();
            try {
                const res = await fetch(`${BASEAPIURL}/admin/freetips/GetFreeTipDetails`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        freetipautoip,
                        createdID: localStorage.getItem('tid'),
                        createdBy: localStorage.getItem('usertype'),
                    }),
                });
                const resJson = await res.json();
                if (res.status === 200 && resJson.data && resJson.data.length > 0) {
                    setForm(resJson.data[0]);
                    // console.log(resJson.data[0]);
                    // setCVfile(resJson.data[0].image);
                    // setPageContent(resJson.data[0].description);
                    FetchSubCategory(null, resJson.data[0].category);
                    if (resJson.dataAudio && resJson.dataAudio.length > 0) {
                        setAudioList(resJson.dataAudio);
                    }
                } else {
                    setForm({
                        freetipautoip: 0,
                        createdID: localStorage.getItem('tid'),
                        createdBy: localStorage.getItem('usertype'),
                        title: '',
                        FreeTipAutoIp: 0,
                        image: '',
                        description: '',
                        agegroup: '',
                        language: '',
                        listner: 0,
                        category: 0,
                        subcategory: 0,
                    });
                }
            } catch (err) {
                console.log(err);
            }
        };
        FetchBlogDetails(FreeTipAutoIp);
        // get Blog Details

        // for login check here
        const FetchProfile = async () => {
            try {
                const res = await fetch(`${BASEAPIURL}/admin/instructure/myprofile`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Basic ${localStorage.getItem('jwttoken')}`,
                    },
                    body: JSON.stringify({
                        token: localStorage.getItem('token'),
                    }),
                });
                const resJson = await res.json();
                if (res.status === 200) {
                    if (resJson.data !== null) {
                        if (
                            Object.keys(resJson.data).length > 0 &&
                            localStorage.getItem('usertype') === 'instructor'
                        ) {
                            setisLogin(true);
                            localStorage.setItem('tid', resJson.data.tid);
                        } else {
                            setisLogin(false);
                        }
                    } else {
                        setisLogin(false);
                    }
                } else {
                    setisLogin(false);
                    console.log(resJson.message);
                }
            } catch (err) {
                setisLogin(false);
                console.log(err);
            }
        };
        const isAdmin = localStorage.getItem('usertype');
        const isAdmintoken = localStorage.getItem('token');
        if (isAdmin && isAdmin === 'admin' && isAdmintoken) {
            setisLogin(true);
        } else {
            FetchProfile();
        }
        const interval = setInterval(() => {
            if (isAdmin && isAdmin === 'admin' && isAdmintoken) {
                setisLogin(true);
            } else {
                FetchProfile();
            }
        }, 5000);
        return () => clearInterval(interval);
        // for login check here
    }, [FreeTipAutoIp]);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderInstructor drawer={drawer} action={drawerAction.toggle} />
            <div style={{ display: isLogin ? 'none' : '' }}>
                <LoginPage setisLogin={setisLogin} />
            </div>
            <div style={{ display: isLogin ? '' : 'none' }}>
                <div
                    className="ryPopUpBack"
                    style={{ display: PlayAudio !== '' ? '' : 'none' }}
                ></div>
                <div className="ryPopUp" style={{ display: PlayAudio !== '' ? '' : 'none' }}>
                    <anew
                        href="#"
                        className="ryPopUpAct"
                        onClick={() => {
                            SetPlayAudio('');
                        }}
                    >
                        X
                    </anew>
                    <div style={{ margin: '30px 50px' }}>
                        <h3 className="text-center">Playing Audio</h3>
                        <div
                            className="file_inp_cont text-center"
                            style={{
                                background: '#f7faff',
                                border: '1px dashed #4f6a97',
                            }}
                        >
                            {PlayAudio}
                        </div>
                        <anew
                            className="btn btn-docsta-instructor"
                            onClick={() => {
                                SetPlayAudio('');
                            }}
                            style={{
                                width: '150px',
                                margin: '20px auto',
                            }}
                        >
                            Close
                        </anew>
                    </div>
                </div>

                <div className="ryPopUpBack" style={{ display: ImageUpload ? '' : 'none' }}></div>
                <div className="ryPopUp" style={{ display: ImageUpload ? '' : 'none' }}>
                    <anew
                        href="#"
                        className="ryPopUpAct"
                        onClick={() => {
                            setImageUpload(false);
                        }}
                    >
                        X
                    </anew>
                    <div style={{ margin: '30px 50px' }}>
                        <h3 className="text-center">Upload Audio</h3>
                        <div
                            className="file_inp_cont text-center"
                            style={{
                                background: '#f7faff',
                                border: '1px dashed #4f6a97',
                            }}
                        >
                            <input
                                type="file"
                                name="cvfile"
                                className="ryfile"
                                accept="audio/*"
                                onChange={(e) => {
                                    saveFile(e);
                                    fetchAudioDuration(e);
                                }}
                                style={{
                                    width: 'max-content',
                                    margin: '0px auto',
                                    display: 'block',
                                }}
                            />
                            <p>Please upload a high quality noice free Audio.</p>
                        </div>
                        <anew
                            className="btn d-none btn-docsta-instructor"
                            onClick={() => {
                                setImageUpload(false);
                            }}
                            style={{
                                width: '150px',
                                margin: '20px auto',
                            }}
                        >
                            Submit
                        </anew>
                    </div>
                </div>

                <div className="container pt-4 pb-4">
                    <div
                        className="p-3 text-center"
                        style={{
                            background: `url(${IMGBASEAPIURL}instructor/headerbg.jpg)`,
                            backgroundRepeat: 'repeat',
                            backgroundSize: '100%',
                        }}
                    >
                        <h2 className="m-3 text-white">Submit A New Free Life Tip</h2>
                    </div>
                    <div className="row">
                        <div className="col-md-1"></div>

                        <div className="col-md-10">
                            <div className="mt-4 mb-4 ry_instr_shadow">
                                <div className="instr_signup_head d-none d-md-block">
                                    <div className="row">
                                        <div className="col-md-4 text-center">
                                            <h2 className={`mb-0 instr_signup_head_step ${step1}`}>
                                                1
                                            </h2>
                                            <b className="mb-2 d-block">Life Tips Details</b>
                                        </div>
                                        <div className="col-md-4 text-center">
                                            <h2 className={`mb-0 instr_signup_head_step ${step2}`}>
                                                2
                                            </h2>
                                            <b className="mb-2 d-block">Upload Audio</b>
                                        </div>
                                        <div className="col-md-4 text-center">
                                            <h2 className={`mb-0 instr_signup_head_step ${step3}`}>
                                                3
                                            </h2>
                                            <b className="mb-2 d-block">Submit for Review</b>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className={classforform}>
                                        <form
                                            id="Inst-Regi-Form"
                                            encType="multipart/form-data"
                                            className="m-4 p-4"
                                            style={{ display: 'block' }}
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                RegistrerInstructor();
                                            }}
                                        >
                                            <div className="For_Form_title"></div>

                                            <div style={{ display: ConfirmationDiv }}>
                                                <img
                                                    src={`${IMGBASEAPIURL}instructor/inst_reg_done.jpg`}
                                                    alt="Docsta Learn Insinstructor"
                                                    style={{ maxWidth: '100%' }}
                                                    className="mt-1 mb-4"
                                                />
                                                <h3 className="mt-3">
                                                    Your Life tip has been submitted successfully.
                                                </h3>
                                                <div
                                                    className="message mt-3"
                                                    style={{ color: Msgcolor }}
                                                >
                                                    {APIMessage ? <span>{APIMessage}</span> : ''}
                                                </div>
                                                <Link
                                                    to="/instructor/page/mylifetips"
                                                    className="btn btn-docsta-instructor d-inline-block m-3"
                                                    style={{ width: '190px' }}
                                                >
                                                    Back to My Free Tips
                                                </Link>
                                            </div>

                                            <div style={{ display: PerDetDiv }}>
                                                <h4 className="mb-2">Life Tips Details</h4>
                                                <div className="row m-2">
                                                    <div className="col-md-6"></div>
                                                    <div className="col-md-6"></div>
                                                    <div style={{ clear: 'both' }}></div>
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="ry_label">
                                                                Topic Name
                                                            </label>
                                                            <input
                                                                value={regformdata.title}
                                                                type="text"
                                                                name="title"
                                                                className="form-control"
                                                                placeholder="Enter"
                                                                onChange={(e) => {
                                                                    handleForm(
                                                                        e.target.name,
                                                                        e.target.value
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="ry_label">
                                                                Age Group
                                                            </label>
                                                            <select
                                                                name="agegroup"
                                                                className="form-control"
                                                                value={regformdata.agegroup}
                                                                onChangeCapture={(e) => {
                                                                    handleForm(
                                                                        e.target.name,
                                                                        e.target.value
                                                                    );
                                                                }}
                                                            >
                                                                <option value="">
                                                                    Select Age group
                                                                </option>
                                                                <option value="Below 18">
                                                                    Below 18
                                                                </option>
                                                                <option value="18 Plus">
                                                                    18 Plus
                                                                </option>
                                                                <option value="Open For All">
                                                                    Open For All
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div style={{ clear: 'both' }}></div>
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="ry_label">
                                                                Category
                                                            </label>
                                                            <select
                                                                type="text"
                                                                name="category"
                                                                className="form-control"
                                                                value={regformdata.category}
                                                                onChange={(e) => {
                                                                    handleForm(
                                                                        e.target.name,
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                onBlur={(e) => {
                                                                    FetchSubCategory(
                                                                        e,
                                                                        e.target.value
                                                                    );
                                                                }}
                                                            >
                                                                <option key="0" value="0">
                                                                    Select Category
                                                                </option>
                                                                {Category.map((singi) => (
                                                                    <option
                                                                        key={singi.freetipid}
                                                                        value={singi.freetipid}
                                                                    >
                                                                        {singi.title}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="ry_label">
                                                                Sub Category
                                                            </label>
                                                            <select
                                                                value={regformdata.subcategory}
                                                                type="text"
                                                                name="subcategory"
                                                                className="form-control"
                                                                onChangeCapture={(e) => {
                                                                    handleForm(
                                                                        e.target.name,
                                                                        e.target.value
                                                                    );
                                                                }}
                                                            >
                                                                <option key="0" value="0">
                                                                    {SubCategory.length > 0
                                                                        ? 'Select Sub Category'
                                                                        : 'No Sub Category'}
                                                                </option>
                                                                {SubCategory.map((singi) => (
                                                                    <option
                                                                        key={singi.freetipid}
                                                                        value={singi.freetipid}
                                                                    >
                                                                        {singi.title}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div style={{ clear: 'both' }}></div>
                                                </div>

                                                <div className="row mt-2">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="ry_label">
                                                                Language
                                                            </label>
                                                            <input
                                                                value={regformdata.language}
                                                                type="text"
                                                                name="language"
                                                                className="form-control"
                                                                placeholder="Enter"
                                                                onChange={(e) => {
                                                                    handleForm(
                                                                        e.target.name,
                                                                        e.target.value
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="ry_label">
                                                                Description
                                                            </label>
                                                            <input
                                                                value={regformdata.description}
                                                                type="text"
                                                                name="description"
                                                                className="form-control"
                                                                maxLength="200"
                                                                placeholder="Write a Description in less than 200 Words."
                                                                onChange={(e) => {
                                                                    handleForm(
                                                                        e.target.name,
                                                                        e.target.value
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div style={{ clear: 'both' }}></div>
                                                </div>
                                                <div
                                                    className="message mt-3"
                                                    style={{ color: Msgcolor }}
                                                >
                                                    {APIMessage ? <span>{APIMessage}</span> : ''}
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Image</label>
                                                            {regformdata.image ? (
                                                                <img
                                                                    src={`${UPLOADNODEURL}${regformdata.image}`}
                                                                    alt=""
                                                                    style={{
                                                                        width: '150px',
                                                                        margin: '10px auto',
                                                                        display: 'block',
                                                                    }}
                                                                />
                                                            ) : (
                                                                ''
                                                            )}
                                                            <div
                                                                className={`text-center ${Loader ? '' : 'd-none'}`}
                                                            >
                                                                <img
                                                                    src="/loader.gif"
                                                                    alt=""
                                                                    style={{ maxWidth: '90px' }}
                                                                />
                                                            </div>
                                                            <input
                                                                type="file"
                                                                name="cvfile"
                                                                className="form-control"
                                                                accept="image/*"
                                                                onChange={(e) => {
                                                                    if (
                                                                        e.target.files &&
                                                                        e.target.files.length > 0
                                                                    ) {
                                                                        SetLoader(true);
                                                                        uploadImage(
                                                                            e.target.files[0],
                                                                            e.target.files[0].name
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label>&nbsp;</label>
                                                        <br />
                                                        <button
                                                            type="button"
                                                            className="btn btn-docsta-instructor"
                                                            onClick={() => {
                                                                ShowFormDiv('addi1');
                                                            }}
                                                        >
                                                            Proceed
                                                        </button>
                                                        <div className="text-center mt-2">
                                                            Help
                                                            <Link
                                                                to="/instructor-dashboard-help"
                                                                target="_blank"
                                                                style={{
                                                                    color: '#fff',
                                                                    background: 'blue',
                                                                    borderRadius: '50%',
                                                                    textAlign: 'center',
                                                                    fontSize: '12px',
                                                                    width: '20px',
                                                                    height: '20px',
                                                                    lineHeight: '20px',
                                                                    marginLeft: '7px',
                                                                }}
                                                            >
                                                                <i className="fa fa-question"></i>
                                                            </Link>
                                                            <a
                                                                href="https://api.whatsapp.com/send?phone=+971543229664&text=Hello,%20Sending%20you%20message%20from%20https://docstalearn.com/"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <img
                                                                    src={`${BASEURL}images/whatsappus.png`}
                                                                    alt=""
                                                                    style={{
                                                                        width: '25px',
                                                                        height: '25px',
                                                                        marginLeft: '7px',
                                                                    }}
                                                                />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div style={{ clear: 'both' }}></div>
                                                </div>
                                            </div>

                                            <div style={{ display: AddiDet1Div }}>
                                                {/* <h4 className="mb-2">Additional Details</h4> */}

                                                <div className="form-group">
                                                    {/* <label className="ry_label">Blog Images</label> */}
                                                    {AudioList && AudioList.length < 1 ? (
                                                        <a
                                                            href="/"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setImageUpload(true);
                                                            }}
                                                            className="ryuploadAct"
                                                            style={{
                                                                maxWidth: 'max-content',
                                                                background: '#e7effd',
                                                                color: '#000',
                                                                fontWeight: 700,
                                                            }}
                                                        >
                                                            Add a Life Tip Audio&nbsp;
                                                            <i
                                                                className="fa fa-plus"
                                                                style={{
                                                                    borderRadius: '50%',
                                                                    background: '#000',
                                                                    color: '#f5f5f5',
                                                                    width: '25px',
                                                                    height: '25px',
                                                                    lineHeight: '25px',
                                                                    marginLeft: '5px',
                                                                }}
                                                            ></i>
                                                        </a>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                                <div
                                                    className={`text-center ${Loader ? '' : 'd-none'}`}
                                                >
                                                    <img
                                                        src="/loader.gif"
                                                        alt=""
                                                        style={{ maxWidth: '90px' }}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    {AudioList.map((s, changeId) => (
                                                        <div key={s.index}>
                                                            <div className="row mb-3">
                                                                <div className="col-md-10">
                                                                    <b>Audio Title</b>
                                                                    <input
                                                                        type="text"
                                                                        name="audio[]"
                                                                        required="required"
                                                                        className="form-control"
                                                                        value={s.title}
                                                                        onChange={(e) => {
                                                                            handleEditPortfolio(
                                                                                changeId,
                                                                                'title',
                                                                                e.target.value
                                                                            );
                                                                            RefreshAudioList();
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="col-md-1">
                                                                    <b>&nbsp;</b>
                                                                    <br />
                                                                    <span
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                        }}
                                                                        onClick={() => {
                                                                            // SetPlayAudio(<audio src={`${UPLOADNODEURL}${s.audio}`} />);
                                                                            SetPlayAudio(
                                                                                <audio
                                                                                    autoPlay
                                                                                    src={`${UPLOADNODEURL}${s.audio}`}
                                                                                    controls
                                                                                >
                                                                                    <track
                                                                                        kind="captions"
                                                                                        src="/media/examples/friday.vtt"
                                                                                    />
                                                                                    Your browser
                                                                                    does not support
                                                                                    the audio
                                                                                    element.
                                                                                </audio>
                                                                            );
                                                                        }}
                                                                    >
                                                                        <i className="far text-info fa-2x fa-volume"></i>
                                                                    </span>
                                                                </div>
                                                                <div className="col-md-1">
                                                                    <b>&nbsp;</b>
                                                                    <br />
                                                                    {/* {s[PrimaryIndex]} */}
                                                                    <span
                                                                        value={s.title}
                                                                        style={{
                                                                            display:
                                                                                s.index === 1
                                                                                    ? ''
                                                                                    : '',
                                                                        }}
                                                                        className="text-danger"
                                                                        onClick={() => {
                                                                            /* eslint-disable no-restricted-globals */
                                                                            if (
                                                                                confirm(
                                                                                    'Are you sure to Delete Audio File ?'
                                                                                )
                                                                            ) {
                                                                                handleRemoveAudioList(
                                                                                    s.index
                                                                                );
                                                                                if (
                                                                                    s[PrimaryIndex]
                                                                                ) {
                                                                                    DeleteAudioFile(
                                                                                        s[
                                                                                            PrimaryIndex
                                                                                        ]
                                                                                    );
                                                                                }
                                                                            }
                                                                            /* eslint-enable no-restricted-globals */
                                                                        }}
                                                                    >
                                                                        <i className="fa fa-trash"></i>
                                                                    </span>
                                                                </div>
                                                                <div className="col-md-12 mt-3">
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label className="ry_label">
                                                                                    Audio Duration
                                                                                    (In Seconds)
                                                                                </label>
                                                                                <input
                                                                                    value={
                                                                                        regformdata.duration
                                                                                    }
                                                                                    type="number"
                                                                                    name="duration"
                                                                                    className="form-control"
                                                                                    placeholder="Enter"
                                                                                    onChange={(
                                                                                        e
                                                                                    ) => {
                                                                                        handleForm(
                                                                                            e.target
                                                                                                .name,
                                                                                            e.target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label className="ry_label">
                                                                                    Viewers
                                                                                </label>
                                                                                <input
                                                                                    value={
                                                                                        regformdata.listner
                                                                                    }
                                                                                    type="number"
                                                                                    name="listner"
                                                                                    className="form-control"
                                                                                    placeholder="Enter"
                                                                                    onChange={(
                                                                                        e
                                                                                    ) => {
                                                                                        handleForm(
                                                                                            e.target
                                                                                                .name,
                                                                                            e.target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>

                                                <div
                                                    className="message mt-3"
                                                    style={{ color: Msgcolor }}
                                                >
                                                    {APIMessage ? <span>{APIMessage}</span> : ''}
                                                </div>

                                                <button
                                                    type="submit"
                                                    className="btn btn-docsta-instructor"
                                                    onClick={() => {
                                                        // ShowFormDiv('confirm');
                                                        setMessage('');
                                                        handleForm('AudioList', AudioList);
                                                    }}
                                                    style={{
                                                        margin: '20px auto',
                                                    }}
                                                >
                                                    Submit
                                                </button>
                                            </div>

                                            <div style={{ display: AddiDet2Div }}>
                                                <h3>Blog Details</h3>
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <div className="mt-3 mb-3">
                                                            <b>Title</b>
                                                            <br />
                                                            <span>{regformdata.title}</span>
                                                        </div>
                                                        <div className="row">
                                                            {Category.map((singi) => (
                                                                <div
                                                                    key={singi.freetipid}
                                                                    className="col-md-6 mt-3 mb-3"
                                                                    style={{
                                                                        display:
                                                                            Number(
                                                                                regformdata.category
                                                                            ) ===
                                                                            Number(singi.freetipid)
                                                                                ? ''
                                                                                : 'none',
                                                                    }}
                                                                >
                                                                    <b>Category</b>
                                                                    <br />
                                                                    <span>{singi.title}</span>
                                                                </div>
                                                            ))}
                                                            {SubCategory.map((singi) => (
                                                                <div
                                                                    key={singi.freetipid}
                                                                    className="col-md-6 mt-3 mb-3"
                                                                    style={{
                                                                        display:
                                                                            Number(
                                                                                regformdata.subcategory
                                                                            ) ===
                                                                            Number(singi.freetipid)
                                                                                ? ''
                                                                                : 'none',
                                                                    }}
                                                                >
                                                                    <b>Category</b>
                                                                    <br />
                                                                    <span>{singi.title}</span>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <div className="mt-3 mb-3">
                                                            <b>Blog Content</b>
                                                            <div
                                                                style={{
                                                                    overflow:
                                                                        SeeMore === 1
                                                                            ? 'auto'
                                                                            : 'hidden',
                                                                    maxHeight:
                                                                        SeeMore === 1
                                                                            ? 'max-content'
                                                                            : '250px',
                                                                }}
                                                            >
                                                                {HTMLReactParser(
                                                                    regformdata.description
                                                                )}
                                                            </div>
                                                            <div className="text-right">
                                                                <anew
                                                                    href="#"
                                                                    style={{
                                                                        display:
                                                                            SeeMore === 1
                                                                                ? 'none'
                                                                                : '',
                                                                    }}
                                                                    onClick={() => {
                                                                        SetSeeMore(1);
                                                                    }}
                                                                >
                                                                    See More
                                                                </anew>
                                                                <anew
                                                                    href="#"
                                                                    style={{
                                                                        display:
                                                                            SeeMore === 1
                                                                                ? ''
                                                                                : 'none',
                                                                    }}
                                                                    onClick={() => {
                                                                        SetSeeMore(0);
                                                                    }}
                                                                >
                                                                    See Less
                                                                </anew>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        {CVfile ? (
                                                            <img
                                                                src={`${UPLOADNODEURL}${CVfile}`}
                                                                alt=""
                                                                style={{
                                                                    width: '100%',
                                                                    margin: '10px auto',
                                                                    display: 'block',
                                                                }}
                                                            />
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                </div>
                                                <div style={{ textAlign: 'left' }}>
                                                    <anew
                                                        href="#"
                                                        className="btn btn-info mr-3"
                                                        onClick={() => {
                                                            ShowFormDiv('initial');
                                                        }}
                                                        style={{
                                                            margin: '20px auto',
                                                            width: '150px',
                                                        }}
                                                    >
                                                        <i className="fa fa-edit"></i>
                                                        &nbsp;Edit
                                                    </anew>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-docsta-instructor d-inline-block"
                                                        onClick={() => {
                                                            ShowFormDiv('confirm');
                                                        }}
                                                        style={{
                                                            margin: '20px auto',
                                                            width: '150px',
                                                        }}
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <p className="text-center mt-1 d-none">
                                    Already have an Account ?
                                    <br />
                                    <Link to="/instructor/login" className="mb-4  mt-3">
                                        <button type="submit" className="btn btn-info">
                                            Login as Instructor
                                        </button>
                                    </Link>
                                </p>
                            </div>
                        </div>

                        <div className="col-md-1"></div>
                    </div>
                </div>
            </div>
            <Footer className="appie-footer-about-area" />
            <BackToTop />
        </>
    );
}

export default InstructorCreateFreeTips;
