import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BASEAPIURL } from '../config';

const initialState = { videoCourses: [], QuestionBanks: [] };

// Define an async thunk to fetch VideoCourses START data
export const fetchVideoCourses = createAsyncThunk('fetchVideoCourses', async (FilterFrom) => {
    try {
        const headers = { 'Content-Type': 'application/json' };
        //   const response = await fetch('your-api-endpoint'); // Replace with your actual API endpoint
        const response = await fetch(`${BASEAPIURL}/admin/courses/getAll`, {
            method: 'POST',
            headers,
            body: JSON.stringify({
                ...FilterFrom,
                SortStatus: 1,
                recordsPerPage: 50,
                sortby: 'a2z',
            }),
        });
        // maincatid
        let data = await response.json();
        if (FilterFrom.MainCatID && FilterFrom.MainCatID === 'qb') {
            data = [];
            // alert(FilterFrom.MainCatID);
        }
        return data;
    } catch (error) {
        console.error('Error fetching customer data:', error);
        throw error;
    }
});
// Define an async thunk to fetch VideoCourses END data

// Define an async thunk to fetch VideoCourses START data
export const fetchQBCourses = createAsyncThunk('fetchQBCourses', async (FilterFrom) => {
    try {
        const headers = { 'Content-Type': 'application/json' };
        //   const response = await fetch('your-api-endpoint'); // Replace with your actual API endpoint
        const response = await fetch(`${BASEAPIURL}/courseQB/getAll`, {
            method: 'POST',
            headers,
            body: JSON.stringify({
                ...FilterFrom,
                SortStatus: 1,
                recordsPerPage: 50,
                sortby: 'a2z',
            }),
        });
        // MainCatID
        let data = await response.json();
        if (FilterFrom.MainCatID && FilterFrom.MainCatID !== 'qb') {
            data = [];
            // alert(FilterFrom.MainCatID);
        }
        return data;
    } catch (error) {
        console.error('Error fetching customer data:', error);
        throw error;
    }
});
// Define an async thunk to fetch VideoCourses END data

export const CoursesDataShow = createSlice({
    name: 'paymentsDataShow',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // fetch videoCourses data will be start from here
            .addCase(fetchVideoCourses.pending, () => {
                // console.log('loading')
                // console.log(state)
            })
            .addCase(fetchVideoCourses.fulfilled, (state, action2) => {
                // console.log('succeeded')
                state.videoCourses = action2.payload.data;
            })
            .addCase(fetchVideoCourses.rejected, (state, action) => {
                console.log('failed');
                console.log(state);
                console.error(action);
            })
            // fetch videoCourses data will be End from here
            // fetch QuestionBanks data will be start from here
            .addCase(fetchQBCourses.pending, () => {
                // console.log('loading')
                // console.log(state)
            })
            .addCase(fetchQBCourses.fulfilled, (state, action2) => {
                state.QuestionBanks = action2.payload.data;
            })
            .addCase(fetchQBCourses.rejected, (state, action) => {
                console.log('failed');
                console.log(state);
                console.error(action);
            });
        // fetch QuestionBanks data will be End from here
    },
});

// Action creators are generated for each case reducer function

export default CoursesDataShow.reducer;
