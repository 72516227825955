import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import Parser from 'html-react-parser';
import {
    ShowPriceDisplaySmall,
    // ShowPriceDisplayWhite,
    ShowValueInFormat,
    showRatingStar,
} from '../../config';
import {
    MakeFavoriteStd,
    CheckFavoriteStd,
    BuyThisCourseStd,
    AddtoCart,
} from '../Students/functions';
import './CourseListBlock.css';

const PrimaryIndex = '_id';
const CourseListBlock = ({
    singi,
    UPLOADNODEURL,
    // IMGBASEAPIURL,
    setshowShare,
    setShareThisURL,
    BASEURL,
}) => {
    const [isFavoriteStd, setisFavoriteStd] = useState(false);
    const [CourseURL, setCourseURL] = useState('');
    useEffect(() => {
        const CheckFavorite = async () => {
            const temp = await CheckFavoriteStd(
                localStorage.getItem('loggedToken'),
                singi[PrimaryIndex],
                'MainCourse',
                localStorage.getItem('usertype')
            );
            if (temp === 1) {
                setisFavoriteStd(true);
            } else {
                setisFavoriteStd(false);
            }
        };
        CheckFavorite(singi);
        setCourseURL(`course/${singi.slug}`);
    }, [singi]);
    /*
        Used on the Below Pages
            Homge page skill COurse Section
            Homge page Exam COurse Section
            My Favrate students
    */
    function Redirect2Course() {
        window.location.href = `${BASEURL}${CourseURL}`;
    }
    return (
        <>
            <div className="appie-features-content ListCourse">
                <div className="imgBox">
                    <Link to={`/${CourseURL}`} className="d-block">
                        <img
                            src={
                                singi.image === ''
                                    ? `${BASEURL}logo.png`
                                    : `${UPLOADNODEURL}${singi.image}`
                            }
                            alt={singi.title}
                            className="courseimgsj"
                        />
                    </Link>
                    <span
                        className="curpoint"
                        onClick={() => {
                            Redirect2Course();
                        }}
                    >
                        <i className="fa fa-eye" /> {ShowValueInFormat(Number(singi.view))}
                    </span>
                </div>
                <div className="hoverContent1 contentBox">
                    <div
                        style={{ float: 'right' }}
                        className="icon"
                        onClick={(e) => {
                            e.preventDefault();
                            setisFavoriteStd(!isFavoriteStd);
                            MakeFavoriteStd(
                                localStorage.getItem('loggedToken'),
                                singi[PrimaryIndex],
                                'MainCourse',
                                localStorage.getItem('usertype')
                            );
                        }}
                    >
                        {isFavoriteStd ? (
                            <i
                                className="fas fa-heart fa-1x text-danger"
                                title="Remove From My Favourite"
                            />
                        ) : (
                            <i className="far fa-1x fa-heart" title="Add to Favourite" />
                        )}
                    </div>
                    <small
                        className="tagsBox curpoint"
                        onClick={() => {
                            Redirect2Course();
                        }}
                    >
                        <span title="Level">{singi.level}</span>
                        <div title="Language">
                            <b className="tag-highestrated">{singi.language}</b>
                        </div>
                        <div className="d-none d-sm-block1">
                            <b
                                className="tag-newadded"
                                style={{
                                    display: singi.defaultbtn === 'Newly Added' ? '' : 'none',
                                }}
                            >
                                {singi.defaultbtn}
                            </b>
                            <b
                                className="tag-highestrated"
                                style={{
                                    display: singi.defaultbtn === 'Highest Rated' ? '' : 'none',
                                }}
                            >
                                {singi.defaultbtn}
                            </b>
                            <b
                                className="tag-bestseller"
                                style={{ display: singi.defaultbtn === 'Bestseller' ? '' : 'none' }}
                            >
                                {singi.defaultbtn}
                            </b>
                            {singi.defaultbtn !== 'Newly Added' &&
                            singi.defaultbtn !== 'Highest Rated' &&
                            singi.defaultbtn !== '' &&
                            singi.defaultbtn !== '0' &&
                            singi.defaultbtn !== 'Bestseller' ? (
                                <b className="tag-highestrated">{singi.defaultbtn}</b>
                            ) : (
                                ''
                            )}
                        </div>
                    </small>
                    <div style={{ clear: 'both' }}></div>
                    <h6 className="mb-0">
                        <Link className="CLB_course_title" to={`/${CourseURL}`}>
                            {singi.title}
                        </Link>
                    </h6>
                    <small
                        className="d-block curpoint"
                        onClick={() => {
                            Redirect2Course();
                        }}
                        style={{ color: '#6a6f73' }}
                    >
                        {singi.InstructorDisplay}
                    </small>
                    <div
                        className="curpoint curpoint mt-2 mb-2"
                        onClick={() => {
                            Redirect2Course();
                        }}
                    >
                        {showRatingStar(singi.rating)}
                    </div>
                    <div
                        className="curpoint"
                        onClick={() => {
                            Redirect2Course();
                        }}
                    >
                        {Parser(ShowPriceDisplaySmall(singi.campaign, singi.cost, singi.costinr))}
                    </div>
                    <div></div>
                    <div className="shopBox d-none d-sm-flex">
                        {Number(window.GetCurrencyValue(singi.cost, singi.costinr).cost) ===
                        'RY' ? (
                            <a
                                href="/"
                                className="icon D-none"
                                onClick={(e) => {
                                    e.preventDefault();
                                    AddtoCart(
                                        localStorage.getItem('loggedToken'),
                                        singi[PrimaryIndex],
                                        'MainCourse',
                                        localStorage.getItem('usertype')
                                    );
                                }}
                            >
                                <i className="far fa-shopping-cart" />
                            </a>
                        ) : (
                            ''
                        )}
                        <a
                            style={{ lineHeight: '25px' }}
                            href="/"
                            className="buynow-btn"
                            onClick={(e) => {
                                e.preventDefault();
                                BuyThisCourseStd(
                                    localStorage.getItem('loggedToken'),
                                    singi[PrimaryIndex],
                                    'MainCourse',
                                    localStorage.getItem('usertype')
                                );
                            }}
                        >
                            {Number(window.GetCurrencyValue(singi.cost, singi.costinr).cost) ===
                            0 ? (
                                <>
                                    Enroll <anew className="hideOnMobile">Now</anew>
                                </>
                            ) : (
                                <>
                                    Buy <anew className="hideOnMobile">Now</anew>
                                </>
                            )}
                        </a>
                        <a
                            className="icon d-none"
                            href="/"
                            onClick={(e) => {
                                e.preventDefault();
                                setShareThisURL(`${BASEURL}course/${singi.slug}`);
                                setshowShare(true);
                            }}
                        >
                            <i className="far fa-share" />
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
};
CourseListBlock.propTypes = {
    UPLOADNODEURL: PropTypes.string.isRequired,
    // IMGBASEAPIURL: PropTypes.string.isRequired,
    setshowShare: PropTypes.func.isRequired,
    setShareThisURL: PropTypes.func.isRequired,
};
export default CourseListBlock;
