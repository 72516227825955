import React, { useEffect, useState } from 'react';
import DataService from '../../../../../lib/AxiosInstance';
import CourseInvoice from './invoice';

const ViewOrder = ({ id, goBack }) => {
    const [order, setOrder] = useState(null);
    const callApi = async () => {
        let payload = { orderId: id };
        try {
            const response = await DataService.post('/orders/details', payload);
            setOrder(response.data);
            console.log(response.data);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        callApi();
    }, [id]);
    console.log(order);
    return (
        <div className="ry_instr_shadow p-4">
            <div className="d-flex justify-content-between">
                <h3>Order Summary</h3>
                <div style={{ clear: 'both' }}>
                    {order?.cart?.length > 0 && (
                        <CourseInvoice
                            subtotal={order.subtotal}
                            courses={order.cart}
                            location={order.data.paymentCurrency == 'INR' ? 'India' : ''}
                        />
                    )}
                </div>
                <button className="btn btn-secondary" onClick={() => goBack(null)}>
                    back
                </button>
            </div>
            <div className="my-2 row">
                <div className="col-md-6 my-3 w-100" style={{ overflow: 'scroll' }}>
                    <h4>Student Details:</h4>
                    <br />
                    <table className=" w-100">
                        <tr>
                            <td>Student Name:</td>
                            <td>{order?.student?.name}</td>
                        </tr>
                        <tr>
                            <td> Email:</td>
                            <td>{order?.student?.email}</td>
                        </tr>
                        <tr>
                            <td>Mobile:</td>
                            <td>{order?.student?.mobile}</td>
                        </tr>
                    </table>
                </div>
                <div className="col-md-6 my-3 w-100" style={{ overflow: 'scroll' }}>
                    <h4>Order Details:</h4>
                    <br />
                    <table className=" w-100">
                        <tr>
                            <td>Order Placed At:</td>
                            <td>{new Date(order?.data?.createdAt)?.toDateString()}</td>
                        </tr>
                        <tr>
                            <td>Status:</td>
                            <td>{order?.data?.status == 1 ? 'Completed' : 'Pending'}</td>
                        </tr>
                        <tr>
                            <td>Amount:</td>
                            <td>{order?.data?.receivedAmount}</td>
                        </tr>
                    </table>
                </div>
                <div className="col-md-12 my-3 w-100" style={{ overflow: 'scroll' }}>
                    <h4>Items:</h4>
                    <br />
                    <table className="w-100">
                        <tr>
                            <td>S.N</td>
                            <td>Course Name:</td>
                            <td>SAC:</td>
                            <td>Rate:</td>
                            <td>Qty:</td>
                            <td>Total:</td>
                        </tr>
                        {order?.cart.map((item, index) => (
                            <tr>
                                <td>{index + 1}</td>
                                <td>{item?.targetID.title}</td>
                                <td>998433</td>
                                <td>{item?.cost * 0.82}</td>
                                <td>1</td>
                                <td>{item?.cost * 0.82}</td>
                            </tr>
                        ))}
                        <tr>
                            <td className="text-end" colSpan={5} style={{ textAlign: 'end' }}>
                                <span>Freight Charges</span>
                            </td>
                            <td>0</td>
                        </tr>
                        <tr>
                            <td className="text-end" colSpan={5} style={{ textAlign: 'end' }}>
                                <span>GST @ 18%</span>
                            </td>
                            <td>{order?.subtotal * 0.18}</td>
                        </tr>
                        {order?.data.paymentCurrency && (
                            <>
                                <tr>
                                    <td
                                        className="text-end"
                                        colSpan={5}
                                        style={{ textAlign: 'end' }}
                                    >
                                        <span>GST @ 9%</span>
                                    </td>
                                    <td>{Number(order?.subtotal) * 0.09}</td>
                                </tr>
                                <tr>
                                    <td
                                        className="text-end"
                                        colSpan={5}
                                        style={{ textAlign: 'end' }}
                                    >
                                        <span>GST @ 9%</span>
                                    </td>
                                    <td>{Number(order?.subtotal) * 0.09}</td>
                                </tr>
                            </>
                        )}
                        <tr>
                            <td className="text-end" colSpan={5} style={{ textAlign: 'end' }}>
                                <span>Total</span>
                            </td>
                            <td>
                                {order?.data.paymentCurrency == 'INR'
                                    ? Number(order?.subtotal) + order?.subtotal * 0.18
                                    : Number(order?.subtotal)}
                            </td>
                        </tr>
                        <tr>
                            <td className="text-end" colSpan={5} style={{ textAlign: 'end' }}>
                                <span>
                                    <b>Amount Received</b>
                                </span>
                            </td>
                            <td>
                                <b>{order?.data?.receivedAmount}</b>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default ViewOrder;
