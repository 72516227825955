import React, { useEffect, useState } from 'react';
// import Parser from 'html-react-parser';
// import { Link } from 'react-router-dom';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Footer from '../Helper/FooterLearn';
import Drawer from '../Mobile/DrawerLearn';
import HeaderInstructor from '../Helper/HeaderInstitute';
import CourseListBlock from '../Helper/CourseListBlock';
import CourseListBlockQB from '../Helper/CourseListBlockQB';
import { BASEURL, BASEAPIURL, UPLOADNODEURL, GiftPaymentURL, getCurrency } from '../../config';
// import { RemoveCourseFromCart } from '../Students/functions';

const TokenValue = new Date().getTime();

let giftCourse = localStorage.getItem('giftCourse');
function OrderSummary() {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split('T')[0];
    const [drawer, drawerAction] = useToggle(false);
    const [CourseData, setCourseData] = useState([]);
    const [GiftData, setGiftData] = useState({
        token: TokenValue,
        targetID: localStorage.getItem('giftCourse'),
        targetType: localStorage.getItem('giftCourseType'),
        recName: '',
        recEmail: '',
        name: '',
        email: '',
        mobile: '',
        message: '',
        sendOn: formattedDate,

        // rempove this after test
        // recName: 'Rahul Yadav',
        // recEmail: 'rahulitjec@gmail.com',
        // name: 'Shanu Yadav',
        // email: 'rahul@sjain.io',
        // mobile: '918770247522',
        // rempove this after test

        amount: 10000,
        giftID: new Date().getTime(),
        mine: btoa(TokenValue), // base64 value of token
    });

    const giftThisCourse = async () => {
        let amount = 25000;
        if (CourseData && CourseData.length > 0) {
            amount = window.FetchFinalValue(
                CourseData[0].cost,
                CourseData[0].costinr,
                CourseData[0].campaign
            );
        }
        try {
            const res = await fetch(`${BASEAPIURL}/student/giftThisCourse`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
                },
                body: JSON.stringify({ ...GiftData, amount }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                setGiftData({ ...GiftData, giftID: resJson.giftID, amount });
                setTimeout(() => {
                    document.getElementById('giftPaymentForm').submit();
                }, 786);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const FetchCartData = async (CourseMDID) => {
        try {
            const res = await fetch(`${BASEAPIURL}/mainCourse/GetCourseDetail`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
                },
                body: JSON.stringify({ CourseMDID }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                if (resJson.data && resJson.data && resJson.data.length > 0) {
                    setCourseData(resJson.data);
                } else {
                    setCourseData([]);
                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    const FetchQBCartData = async (CourseMDID) => {
        try {
            const res = await fetch(`${BASEAPIURL}/questionBank/GetCourseDetail`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
                },
                body: JSON.stringify({ TheCourseID: CourseMDID }),
            });
            const resJson = await res.json();
            if (res.status === 200) {
                if (resJson.data && resJson.data && resJson.data.length > 0) {
                    setCourseData(resJson.data);
                } else {
                    setCourseData([]);
                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        // setGiftData({
        //     token: TokenValue,
        //     email: '',
        //     mobile: '',
        //     mine: btoa(TokenValue), // base64 value of token
        // });
        giftCourse = localStorage.getItem('giftCourse');
        const giftCourseType = localStorage.getItem('giftCourseType');
        if (giftCourse && giftCourse.trim() !== '') {
            if (giftCourseType === 'questionBank') {
                FetchQBCartData(giftCourse);
            } else {
                FetchCartData(giftCourse);
            }
        } else {
            window.location.href = `${BASEURL}checkout/cart`;
        }
    }, []);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderInstructor drawer={drawer} action={drawerAction.toggle} />
            <>
                <div className="container pt-4 pb-4">
                    <div className="row">
                        <div className=" col-md-9">
                            <h3>Gift This Course</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-8 col-sm-8">
                            <div className="ry_instr_shadow p-3 mt-4 mb-4">
                                <div className="row">
                                    <div className="col-lg-6 col-sm-6">
                                        <div className="form-group">
                                            {/* {GiftData.amountsd} */}
                                            <b>Recipient's Name</b>
                                            <input
                                                type="name"
                                                className="form-control"
                                                name="name"
                                                placeholder="Recipient's Name"
                                                value={GiftData.recName}
                                                onChange={(e) => {
                                                    setGiftData({
                                                        ...GiftData,
                                                        recName: e.target.value,
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-sm-6">
                                        <div className="form-group">
                                            <b>Recipient's Email</b>
                                            <input
                                                type="email"
                                                className="form-control"
                                                name="email"
                                                placeholder="Recipient's Email"
                                                value={GiftData.recEmail}
                                                onChange={(e) => {
                                                    setGiftData({
                                                        ...GiftData,
                                                        recEmail: e.target.value,
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-sm-6">
                                        <div className="form-group">
                                            <b>When do you want to send this gift:</b>
                                            <input
                                                type="date"
                                                className="form-control"
                                                name="sendOn"
                                                value={GiftData.sendOn}
                                                onChange={(e) => {
                                                    setGiftData({
                                                        ...GiftData,
                                                        sendOn: e.target.value,
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-sm-6">
                                        <div className="form-group">
                                            <b>Your Message (optional):</b>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="message"
                                                placeholder="Add your personal message here"
                                                value={GiftData.message}
                                                onChange={(e) => {
                                                    setGiftData({
                                                        ...GiftData,
                                                        message: e.target.value,
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-sm-12">
                                        <div className="form-group">
                                            <b>Your Name</b>
                                            <input
                                                type="name"
                                                className="form-control"
                                                placeholder="Your Name"
                                                value={GiftData.name}
                                                onChange={(e) => {
                                                    setGiftData({
                                                        ...GiftData,
                                                        name: e.target.value,
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-sm-6">
                                        <div className="form-group">
                                            <b>Your Email</b>
                                            <input
                                                type="email"
                                                className="form-control"
                                                placeholder="Your Email"
                                                value={GiftData.email}
                                                onChange={(e) => {
                                                    setGiftData({
                                                        ...GiftData,
                                                        email: e.target.value,
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-sm-6">
                                        <div className="form-group">
                                            <b>Your Mobile</b>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="mobile"
                                                placeholder="Your Mobile"
                                                value={GiftData.mobile}
                                                onChange={(e) => {
                                                    setGiftData({
                                                        ...GiftData,
                                                        mobile: e.target.value,
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {GiftData.email !== '' && GiftData.mobile !== '' ? (
                                        <form
                                            action={GiftPaymentURL}
                                            className="text-center mt-2 col-md-12"
                                            id="giftPaymentForm"
                                            method="POST"
                                        >
                                            <input
                                                type="hidden"
                                                name="token"
                                                value={GiftData.token}
                                            />
                                            <input
                                                type="hidden"
                                                name="email"
                                                value={GiftData.email}
                                            />
                                            <input
                                                type="hidden"
                                                name="mobile"
                                                value={GiftData.mobile}
                                            />
                                            <input
                                                type="hidden"
                                                name="process"
                                                value={btoa(GiftData.amount)}
                                            />
                                            <input
                                                type="hidden"
                                                name="address"
                                                value={CourseData.address}
                                            />
                                            <input
                                                type="hidden"
                                                name="mine"
                                                value={GiftData.mine}
                                            />
                                            <input
                                                type="hidden"
                                                name="currency"
                                                value={getCurrency()}
                                            />
                                            <input
                                                type="hidden"
                                                name="orderID"
                                                value={GiftData.giftID}
                                            />
                                            <input
                                                type="hidden"
                                                name="country"
                                                value={localStorage.getItem('country_name')}
                                            />
                                            <button
                                                type="button"
                                                className="main-btn btn-lg d-inline-block"
                                                onClick={() => {
                                                    giftThisCourse();
                                                }}
                                            >
                                                Pay online to Gift
                                            </button>
                                        </form>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-4">
                            <div className="mt-4 mb-4">
                                {CourseData && CourseData.length > 0 && (
                                    <>
                                        {localStorage.getItem('giftCourseType') ===
                                        'questionBank' ? (
                                            <CourseListBlockQB
                                                singi={CourseData[0]}
                                                UPLOADNODEURL={UPLOADNODEURL}
                                                BASEURL={BASEURL}
                                            />
                                        ) : (
                                            <CourseListBlock
                                                singi={CourseData[0]}
                                                UPLOADNODEURL={UPLOADNODEURL}
                                                BASEURL={BASEURL}
                                            />
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
            <Footer className="appie-footer-about-area" />
            <BackToTop />
        </>
    );
}

export default OrderSummary;
