import React, { useState, useEffect } from 'react';
import { BASEAPIURL } from '../../../../config';
// import BackToTop from '../../../BackToTop';

// for sorting the data
let courseID = '';
let keyword = '';
// for sorting the data

function ProfileMenu({ forpage }) {
    const [APIcall, setAPIcall] = useState(true);
    const [Students, setStudents] = useState([]);
    const [DataBanks, setDataBanks] = useState([]);
    const [DataCourse, setDataCourse] = useState([]);

    const FetchStudents = async () => {
        // setStudents([]);
        setDataBanks([]);
        setDataCourse([]);
        try {
            const res = await fetch(`${BASEAPIURL}/admin/instructure/FetchStudents`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Basic ${localStorage.getItem('jwttoken')}`,
                },
                body: JSON.stringify({ courseID, keyword }),
            });
            const resJson = await res.json();
            setAPIcall(false);
            if (res.status === 200) {
                if (resJson.data && resJson.data.length > 0) {
                    setStudents(resJson.data);
                }
                if (resJson.dataBanks && resJson.dataBanks.length > 0) {
                    setDataBanks(resJson.dataBanks);
                }
                if (resJson.dataCourse && resJson.dataCourse.length > 0) {
                    setDataCourse(resJson.dataCourse);
                }
            }
        } catch (err) {
            setAPIcall(false);
            console.log(err);
        }
    };

    useEffect(() => {
        courseID = '';
        keyword = '';
        FetchStudents('', '', '');
    }, []);

    return (
        <>
            <div className={`ry_instr_shadow p-4 ${APIcall ? 'd-none' : ''}`} forpage={forpage}>
                <h4 className="ry_instr_text">
                    My Student
                    <button
                        type="button"
                        className="btn btn-outline ry-pull-right"
                        style={{ width: 'auto', borderRadius: '10px' }}
                    >
                        <b></b>
                    </button>
                    <div style={{ clear: 'both' }}></div>
                </h4>
                <div className="row mt-3">
                    <div className="col-md-6">
                        <label className="ry_label">
                            <b>Select Course</b>
                        </label>
                        <select
                            name="sortby"
                            className="form-control"
                            value={courseID}
                            onChange={(e) => {
                                courseID = e.target.value;
                                FetchStudents();
                            }}
                        >
                            <option value="">Select Course</option>
                            {DataCourse.map((c) => (
                                <option value={`mainCourse:${c._id}`}>{c.title}</option>
                            ))}
                            {DataBanks.map((c) => (
                                <option value={`questionBank:${c._id}`}>{c.title}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-6">
                        <label className="ry_label">
                            <b>Search</b>
                        </label>
                        <input
                            name="keyword"
                            type="text"
                            className="form-control"
                            placeholder="Example: Student Name"
                            onChange={(e) => {
                                keyword = e.target.value;
                                FetchStudents();
                            }}
                        />
                    </div>
                </div>
                <div className="mt-3 table-responsive">
                    {Students && Students.length > 0 ? (
                        <table className="table table-hover table-borddered table-stripped">
                            <thead className="ry-table-header">
                                <tr style={{ fontSize: '80%' }}>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>DOB</th>
                                    <th>Gender</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Students.map((s) => (
                                    <tr>
                                        <td>{s.stdId}</td>
                                        <td>
                                            <img
                                                src={`${BASEAPIURL}/student/profileimage/${s._id}`}
                                                alt="Inc Profile"
                                                className="mr-2 ry-pull-left"
                                                style={{ maxWidth: '50px' }}
                                            />
                                            <small style={{ display: 'inline-block' }} className="">
                                                <b>{s.name}</b>
                                                <br />
                                                <small style={{ fontSize: '90%' }}>
                                                    <>
                                                        <i className="fa fa-envelope mr-2"></i>
                                                        {s.email}
                                                    </>
                                                </small>
                                            </small>
                                        </td>
                                        {/* <td>
                                            70%
                                            <div style={{ background: '#6CB0FF', height: '7px' }}>
                                                <div
                                                    style={{
                                                        marginLeft: '70%',
                                                        height: '7px',
                                                        background: '#f5f5f5',
                                                    }}
                                                ></div>
                                            </div>
                                        </td> */}
                                        <td>{s.dob}</td>
                                        <td>{s.gender}</td>
                                        {/* <td>
                                            <button type="button" className="alert p-1 alert-info">
                                                Started
                                            </button>
                                        </td> */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <div className="text-danger">There is no Student now</div>
                    )}
                </div>
            </div>
        </>
    );
}

export default ProfileMenu;
