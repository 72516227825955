import React from 'react';
function ContactIconFooter({ className }) {
    return (
        <>
            {Number(localStorage.getItem('screenwidth')) > 100 && (
                <>
                    <a
                        target="_WHATAPP"
                        class=" whatsapp_us"
                        href="https://api.whatsapp.com/send?phone=+918714702941&text=Hi%20there!%20I%27m%20reaching%20out%20from%20your%20website%20(www.docsta.com)%C2%A0with%C2%A0a%C2%A0query"
                    >
                        <div class="relative">
                            <img alt="whatsapp" src="/assets/WhatsApp_icon.png" />
                        </div>
                    </a>
                    <a href="tel:+918714702941">
                        <span target="_WHATAPP" className="call_us h-14 w-14 ">
                            <div class="relative h-14 w-14 ">
                                <img
                                    alt="phone"
                                    className="h-14 w-14 bottom-0"
                                    src="/assets/phone-icon.png"
                                />
                            </div>
                        </span>
                    </a>
                </>
            )}
        </>
    );
}

export default ContactIconFooter;
