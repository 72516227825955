// src/InvoicePDF.js

import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer';

// Register font
// Font.register({
//   family: 'Roboto',
//   src: 'https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu4mxK.woff',
// });

// Create styles
const styles = StyleSheet.create({
    page: {
        fontSize: '12px',
        // fontFamily: 'Roboto',
        backgroundColor: '#ffffff',
        padding: 40,
    },
    section: {
        margin: 10,
        padding: 10,
    },
    titleContainer: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 15,
    },
    title: {
        fontSize: 24,
        textAlign: 'end',
        textTransform: 'uppercase',
        fontWeight: '800',
    },
    logo: {
        width: 100,
        marginBottom: 10,
    },
    header: {
        width: '100%',
        marginBottom: 10,
    },
    companyInfo: {
        width: '45%',
        lineHeight: '1.3px',
        backgroundColor: 'lightgray',
        padding: '10px',
        border: '1px',
    },
    companyInfoText: {
        fontSize: '10px',
    },
    tableContainer: {
        width: '50%',
        marginRight: '10px',
        textAlign: 'left',
        borderTop: '1px',
    },
    termsContainer: {
        marginTop: 20,
    },
    termsTitle: {
        marginBottom: 10,
        fontWeight: 'bold',
    },
    footer: {
        position: 'absolute',
        bottom: 40,
        left: 0,
        right: 0,
        margin: 'auto',
        textAlign: 'center',
        fontSize: '20px',
        marginTop: 50,
    },
    tableRow: {
        margin: 0,
        flexDirection: 'row',
        border: '1px',
        borderTop: 0,
    },
    tableCell: {
        textAlign: 'left',
        padding: '5px',
        margin: 0,
        fontSize: '10px',
        width: '50%',
    },
    tableCellRight: {
        textAlign: 'left',
        padding: '5px',
        margin: 0,
        fontSize: '10px',
        width: '50%',
        borderRight: '1px',
    },
});

// Create Document Component
const InvoicePDF = ({ courses, location, subtotal }) => {
    let finalTotal = 0;
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                {/* Header */}
                <View style={styles.header}>
                    <View style={styles.titleContainer}>
                        <Image src="/logo.png" style={styles.logo} />
                        <Text style={styles.title}>TAX INVOICE</Text>
                    </View>
                    <View style={{ borderBottom: '1px solid #000000' }} />
                </View>

                {/* Company Info and Invoice Details */}
                <View
                    style={{
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                    }}
                >
                    <View style={styles.tableContainer}>
                        <View style={styles.tableRow}>
                            <Text style={styles.tableCellRight}>Invoice # </Text>
                            <Text style={styles.tableCell}>545 / 2017-18</Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={styles.tableCellRight}>Invoice Date: </Text>
                            <Text style={styles.tableCell}>12-06-2017</Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={styles.tableCellRight}>Due Date: </Text>
                            <Text style={styles.tableCell}>12-07-2017</Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={styles.tableCellRight}>GSTN: </Text>
                            <Text style={styles.tableCell}>082647566ASD</Text>
                        </View>
                    </View>
                    <View style={styles.companyInfo}>
                        <Text style={{ fontWeight: 800, textDecoration: 'underline' }}>
                            Company Address
                        </Text>
                        <View style={{ margin: '5px' }}></View>
                        <Text style={styles.companyInfoText}>
                            TC 15/148 (16), First Floor, Nakshatra,{' '}
                        </Text>
                        <Text style={styles.companyInfoText}>
                            Opposite MG College, Above CBC Honda,{' '}
                        </Text>
                        <Text style={styles.companyInfoText}>
                            Kesavadasapuram,Thiruvananthapuram,
                        </Text>
                        <Text style={styles.companyInfoText}>Kerala 695004</Text>
                    </View>
                </View>

                {/* Invoice Items */}
                <View style={{ marginTop: 20 }}>
                    <Text>
                        <b>Invoice Items</b>
                    </Text>
                    <View style={{ marginTop: 10 }}>
                        <View
                            style={{
                                flexDirection: 'row',
                                borderBottom: '1px solid #000000',
                                backgroundColor: 'black',
                                color: 'white',
                                padding: '5px',
                            }}
                        >
                            <Text style={{ padding: '5px', width: '10%' }}>S.N.</Text>
                            <Text style={{ padding: '5px', width: '40%' }}>Description</Text>
                            <Text style={{ padding: '5px', width: '10%' }}>SAC</Text>
                            <Text style={{ padding: '5px', width: '10%' }}>Qty</Text>
                            <Text style={{ padding: '5px', width: '20%' }}>Rate</Text>
                            <Text style={{ padding: '5px', width: '20%' }}>Total</Text>
                        </View>
                        {/* Sample invoice items */}
                        {courses &&
                            courses.length > 0 &&
                            courses?.map((course) => {
                                const coursePrice = Number(course.cost);
                                const courseTotal = Number(course.cost);
                                finalTotal += coursePrice;
                                return (
                                    <View style={{ flexDirection: 'row', borderBottom: '1px' }}>
                                        <Text
                                            style={{
                                                backgroundColor: '#e8e8e8',
                                                padding: '5px',
                                                width: '10%',
                                            }}
                                        >
                                            01
                                        </Text>
                                        <Text
                                            style={{
                                                backgroundColor: '#e8e8e8',
                                                padding: '5px',
                                                width: '40%',
                                            }}
                                        >
                                            {course.title}
                                        </Text>
                                        <Text
                                            style={{
                                                backgroundColor: '#e8e8e8',
                                                padding: '5px',
                                                width: '10%',
                                            }}
                                        >
                                            998433
                                        </Text>
                                        <Text
                                            style={{
                                                backgroundColor: '#e8e8e8',
                                                padding: '5px',
                                                width: '10%',
                                            }}
                                        >
                                            1
                                        </Text>
                                        <Text
                                            style={{
                                                backgroundColor: '#e8e8e8',
                                                padding: '5px',
                                                width: '20%',
                                            }}
                                        >
                                            {Number(coursePrice * 0.82)?.toFixed(2)}
                                        </Text>
                                        <Text
                                            style={{
                                                backgroundColor: '#e8e8e8',
                                                padding: '5px',
                                                width: '20%',
                                                textAlign: 'right',
                                            }}
                                        >
                                            {Number(courseTotal * 0.82)?.toFixed(2)}
                                        </Text>
                                    </View>
                                );
                            })}
                    </View>
                </View>

                {/* Total, GST, Freight Charges */}
                <View style={{ flexDirection: 'row' }}>
                    <View style={{ width: '80%' }}>
                        <Text
                            style={{
                                backgroundColor: '#e8e8e8',
                                padding: '5px',
                                textAlign: 'right',
                            }}
                        >
                            Freight Charges
                        </Text>
                    </View>
                    <Text
                        style={{
                            backgroundColor: '#e8e8e8',
                            padding: '5px',
                            width: '20%',
                            textAlign: 'right',
                        }}
                    >
                        0
                    </Text>
                </View>
                <View style={{ flexDirection: 'row' }}>
                    <Text
                        style={{
                            backgroundColor: '#e8e8e8',
                            padding: '5px',
                            width: '80%',
                            textAlign: 'right',
                        }}
                    >
                        IGST @ 18 %
                    </Text>
                    <Text
                        style={{
                            backgroundColor: '#e8e8e8',
                            padding: '5px',
                            width: '20%',
                            textAlign: 'right',
                        }}
                    >
                        {Number(subtotal * 0.18)?.toFixed(2)}
                    </Text>
                </View>
                {location == 'India' && (
                    <>
                        <View style={{ flexDirection: 'row' }}>
                            <Text
                                style={{
                                    backgroundColor: '#e8e8e8',
                                    padding: '5px',
                                    width: '80%',
                                    textAlign: 'right',
                                }}
                            >
                                SGST @ 9 %
                            </Text>
                            <Text
                                style={{
                                    backgroundColor: '#e8e8e8',
                                    padding: '5px',
                                    width: '20%',
                                    textAlign: 'right',
                                }}
                            >
                                {Number(subtotal * 0.09)?.toFixed(2)}
                            </Text>
                        </View>
                        <View style={{ flexDirection: 'row' }}>
                            <Text
                                style={{
                                    backgroundColor: '#e8e8e8',
                                    padding: '5px',
                                    width: '80%',
                                    textAlign: 'right',
                                }}
                            >
                                CGST @ 9 %
                            </Text>
                            <Text
                                style={{
                                    backgroundColor: '#e8e8e8',
                                    padding: '5px',
                                    width: '20%',
                                    textAlign: 'right',
                                }}
                            >
                                {Number(subtotal * 0.09)?.toFixed(2)}
                            </Text>
                        </View>
                    </>
                )}
                <View
                    style={{
                        flexDirection: 'row',
                        border: '1px solid black',
                        backgroundColor: 'lightgray',
                    }}
                >
                    <Text style={{ padding: '5px', width: '80%', textAlign: 'right' }}>Total</Text>
                    <Text style={{ padding: '5px', width: '20%', textAlign: 'right' }}>
                        {location == 'India'
                            ? Number(subtotal) + Number(subtotal) * 0.18
                            : Number(subtotal)?.toFixed(2)}
                    </Text>
                </View>
                {/* <View style={{ flexDirection: 'row', marginTop: 10 }}>
        <View style={{ width: '80%' }}>
          <Text style={{ textAlign: 'right', marginBottom: '5px' }}>
            IGST @ 18 %
          </Text>
          <Text style={{ textAlign: 'right', marginBottom: '5px' }}>
            SGST @ 9 %
          </Text>
          <Text style={{ textAlign: 'right', marginBottom: '5px' }}>
            CGST @ 9 %
          </Text>
          <Text style={{ textAlign: 'right', marginBottom: '5px' }}>
            Total Due
          </Text>
        </View>
        <Text style={{ width: '20%', textAlign: 'center' }}>0.00</Text>
      </View> */}

                <View
                    style={{
                        marginTop: '100px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                    }}
                >
                    <View>
                        <Image src="/logo.png" style={styles.logo} />
                        <Text style={{ borderTop: '1px', width: 'auto', padding: '2px' }}>
                            Authorized Signatory
                        </Text>
                    </View>
                </View>
                {/* Footer */}
                <View style={styles.footer}>
                    <Text>Thank you for your business!</Text>
                </View>
            </Page>
        </Document>
    );
};

export default InvoicePDF;
