import React, { Suspense } from 'react';
// import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo-learn.png';

const ProfileMenu = React.lazy(() => import('../administrator/pages/Dashboard/ProfileMenu'));

function Drawer({ drawer, action }) {
    return (
        <>
            <div
                onClick={(e) => action(e)}
                className={`off_canvars_overlay ${drawer ? 'active' : ''}`}
            ></div>
            <div className="offcanvas_menu">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className={`offcanvas_menu_wrapper ${drawer ? 'active' : ''}`}>
                                <div className="canvas_close">
                                    <a
                                        href="/"
                                        id="closedrawer"
                                        onClick={(e) => {
                                            action(e);
                                            e.preventDefault();
                                        }}
                                    >
                                        <i className="far fa-times"></i>
                                    </a>
                                </div>
                                <div className="offcanvas-brand text-center mb-40">
                                    <img src={logo} alt="" />
                                </div>
                                <div id="menu" className="text-left">
                                    <Suspense fallback={<div>Loading...</div>}>
                                        <ProfileMenu isDrawer={1} forpage="DraverAdmin" />
                                    </Suspense>
                                </div>
                                <div className="offcanvas-social">
                                    <ul className="text-center">
                                        <li>
                                            <a href="/">
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/">
                                                <i className="fab fa-twitter" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/">
                                                <i className="fab fa-instagram" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/">
                                                <i className="fab fa-dribbble" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="footer-widget-info text-left">
                                    <ul>
                                        <li>
                                            <a href="mailto:support@yourdomian.com">
                                                <i className="fal fa-envelope" />{' '}
                                                support@yourdomian.com
                                            </a>
                                        </li>
                                        {/* <li>
                                            <a href="#">
                                                <i className="fal fa-phone" /> +(0) 1234 56789
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fal fa-map-marker-alt" />
                                                sdfg
                                            </a>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Drawer;
