import React, { useRef, useEffect, useState } from 'react';
import Slider from 'react-slick';
import Parser from 'html-react-parser';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './testimonialsbox.css';
import { IMGBASEAPIURL, UPLOADNODEURL, BASEAPIURL, BASEURL } from '../../config';

function TestimonialsBox() {
    const sliderRef = useRef();
    const testimonial = {
        autoplay: true,
        arrows: true,
        dots: false,
        slidesToShow: 3,
        centerMode: true,
        centerPadding: '0px',
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    const [ShowData, setShowData] = useState([]);
    useEffect(() => {
        const FetchData = async () => {
            try {
                const res = await fetch(`${BASEAPIURL}/admin/testimonials/getAll`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        courseid: 'all',
                        featured: 1,
                        recordsPerPage: 50,
                        sortby: 'latest',
                        SortStatus: 1,
                    }),
                });
                const resJson = await res.json();
                if (res.status === 200) {
                    if (Object.keys(resJson.data).length > 0) {
                        setShowData(resJson.data);
                        // alert(1);
                    }
                } else {
                    console.log(resJson.message);
                }
            } catch (err) {
                console.log(err);
            }
        };
        FetchData();
    }, []);

    function getTestiomonialImage(image, studentId, studentName, i) {
        let randomNum = i;
        if (i > 9) {
            randomNum = Math.floor(Math.random() * 9) + 1;
        }
        // const colors = ['blue','black','purple','green','black','purple','cc0000','black','purple'];
        let theImage = `${BASEURL}images/testimonials/rdpics/${randomNum}.jpg?VERSION=20231122`;
        // Split the string into an array of words
        // const words  = studentName.split(" ");
        // Extract the first letter of the first two words
        // const firstLetterFirstWord = words[0] ? words[0][0] : "";
        // let firstLetterSecondWord = words[1] ? words[1][0] : "";
        // if (firstLetterSecondWord === "") {
        //     firstLetterSecondWord = words[0] ? words[0][1] : "";
        // }
        // let theImage = `https://placehold.co/400x400/${colors[randomNum]}/FFFFFF/png?text=${firstLetterFirstWord.toUpperCase()}${firstLetterSecondWord.toUpperCase()}`
        if (studentId && studentId !== '' && studentId !== '0') {
            theImage = `${BASEAPIURL}/student/profileimage/${studentId}`;
        }
        if (image && image !== '' && image !== '0') {
            theImage = `${UPLOADNODEURL}${image}`;
        }
        return theImage;
    }

    return (
        <>
            <section
                className="pl-3 pr-3 appie-testimonial-area"
                style={{
                    backgroundImage: `url(${IMGBASEAPIURL}institute/bg/testimonial-bg.jpg)`,
                }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Testimonials</h3>
                                <p>What our community is saying</p>
                            </div>
                        </div>
                    </div>
                    <div className="row appie-showcase-slider">
                        <div className="col-lg-12">
                            <Slider ref={sliderRef} {...testimonial}>
                                {ShowData.map((singi, i) => (
                                    <div key={singi._id} ry={singi._id}>
                                        <div className="appie-features-content mt-3">
                                            <div className="contentBox p-0">
                                                <div className="userBox">
                                                    <img
                                                        // src={singi.image !== '' ? `${UPLOADNODEURL}${singi.image}` : `${BASEAPIURL}/student/profileimage/${singi.studentId}`}
                                                        src={getTestiomonialImage(
                                                            singi.image,
                                                            singi.studentId,
                                                            singi.studentName,
                                                            Number(i) + 1
                                                        )}
                                                        alt={singi.studentName}
                                                        className="stdtestimg"
                                                    />
                                                    <div className="userDetail">
                                                        <h5>{singi.studentName}</h5>
                                                        {/* <h5>Sharjah</h5> */}
                                                        <h6>{singi.CourseName}</h6>
                                                    </div>
                                                </div>
                                                <div className="writeupBox">
                                                    {singi.comment ? Parser(singi.comment) : ''}
                                                </div>
                                                <div className="ratingBox mb-0">
                                                    <i className="fas fa-star" />
                                                    <i className="fas fa-star" />
                                                    <i className="fas fa-star" />
                                                    <i className="fas fa-star" />
                                                    <i className="fas fa-star" />
                                                    <b>
                                                        {singi.rating
                                                            ? Number(singi.rating).toFixed(1)
                                                            : 5.0}
                                                        /5.0
                                                    </b>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {/* <slide2 /> */}
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default TestimonialsBox;
