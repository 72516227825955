import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { setCookie } from '../../../../config';

function ProfileMenu({ forpage }) {
    const navigate = useNavigate();
    const [IsInstructorAccoutMenu, setIsInstructorAccoutMenu] = useState(
        Number(localStorage.getItem('screenwidth')) > 999
    );
    function InstructorAccoutMenu(v) {
        // dispatch(UpdateCommonData({ActProfileMenu: v}));
        if (Number(localStorage.getItem('screenwidth')) < 999) {
            setIsInstructorAccoutMenu(!IsInstructorAccoutMenu);
        }
    }
    const [ShowAlert, setShowAlert] = useState(false);
    // const [drawer, drawerAction] = useToggle(false);
    // const [lfVisibility, setlfVisibility] = useState('block');
    function Logout() {
        localStorage.setItem('token', 0);
        localStorage.setItem('usertype', '');
        localStorage.setItem('username', '');
        localStorage.setItem('tid', 0);
        localStorage.setItem('jwttoken', 0);
        setCookie('token', 0, 1);
        setCookie('jwttoken', 0, 1);
        setCookie('username', '', 1);
        navigate.push('/');
    }

    function showMenuHeader(running, Current) {
        let returnValue = '';
        if (running === Current) {
            returnValue = (
                <>
                    <i
                        className="fas ry-pull-right fa-chevron-right"
                        style={{ marginTop: '5px' }}
                    ></i>
                    <div style={{ clear: 'both' }}></div>
                </>
            );
        }
        return returnValue;
    }
    return (
        <>
            <div className="ryPopUpBack" style={{ display: ShowAlert ? '' : 'none' }}></div>
            <div
                className="ryPopUp"
                style={{
                    display: ShowAlert ? '' : 'none',
                    position: 'fixed',
                    maxHeight: 'fit-content',
                }}
            >
                <anew
                    href="#"
                    className="ryPopUpAct"
                    onClick={() => {
                        setShowAlert(false);
                    }}
                >
                    X
                </anew>
                <div style={{ margin: '30px 50px' }}>
                    <h3 className="text-center">Are you sures, You want to Logout? </h3>
                    <div className="mt-4 mb-4"></div>
                    <div className="text-center">
                        <Link
                            to="#"
                            className="btn btn-outline d-inline-block mr-3 text-white"
                            onClick={() => {
                                setShowAlert(false);
                            }}
                            style={{
                                width: '150px',
                                margin: '20px auto',
                                border: '15px',
                                backgroundColor: '#939393',
                            }}
                        >
                            Cancel
                        </Link>
                        <Link
                            to="#"
                            className="btn btn-docsta-instructor d-inline-block"
                            onClick={() => {
                                Logout();
                            }}
                            style={{
                                width: '150px',
                                margin: '20px auto',
                                border: '15px',
                            }}
                        >
                            Submit
                        </Link>
                    </div>
                </div>
            </div>
            {!IsInstructorAccoutMenu ? (
                <button
                    style={{ width: '100%' }}
                    className="btn btn-purple text-center d-block mt-3 mb-3"
                    type="button"
                    onClick={() => {
                        InstructorAccoutMenu();
                    }}
                >
                    My Account
                </button>
            ) : (
                ''
            )}
            <div
                onClick={() => {
                    InstructorAccoutMenu();
                }}
                className="ry_instr_shadow pt-3 pb-3"
                forpage={forpage}
                style={{ display: IsInstructorAccoutMenu ? '' : 'none' }}
            >
                <Link to="/instructor-dashboard" className="ry_instr_menu">
                    <i className="fal fa-star" /> My Dashboard
                </Link>
                <Link to="/instructor/page/mycourses" className="ry_instr_menu">
                    <i className="fal fa-star" /> My Courses
                    {showMenuHeader(forpage, 'mycourses')}
                </Link>
                <Link to="/instructor/page/mystudents" className="ry_instr_menu">
                    <i className="fal fa-star" /> My Students
                    {showMenuHeader(forpage, 'mystudents')}
                </Link>
                <Link to="/instructor/page/myquestionbank" className="ry_instr_menu">
                    <i className="fal fa-info-circle" /> My Question Bank
                    {showMenuHeader(forpage, 'myquestionbank')}
                </Link>
                <Link to="/instructor/page/myqa" className="ry_instr_menu">
                    <i className="fal fa-star" /> My Q&A
                    {showMenuHeader(forpage, 'myqa')}
                </Link>
                <Link to="/instructor/page/mywebinar" className="ry_instr_menu">
                    <i className="fal fa-star" /> Live Classes
                    {showMenuHeader(forpage, 'mywebinar')}
                </Link>
                <Link to="/instructor/page/mylifetips" className="ry_instr_menu">
                    <i className="fal fa-lightbulb" /> My Free Life Tips
                    {showMenuHeader(forpage, 'mylifetips')}
                </Link>
                <Link to="/instructor/page/myrevenue" className="ry_instr_menu">
                    <i className="far fa-file-invoice-dollar"></i>
                    &nbsp;My Revenue
                    {showMenuHeader(forpage, 'myrevenue')}
                </Link>
                <Link to="/instructor/page/myreviews" className="ry_instr_menu">
                    <i className="fal fa-star" /> My Reviews
                    {showMenuHeader(forpage, 'myreviews')}
                </Link>
                <Link to="/instructor/page/myblog" className="ry_instr_menu">
                    <i className="fal fa-window" /> My Blogs
                    {showMenuHeader(forpage, 'myblog')}
                </Link>
                <hr />
                <Link to="/instructor/page/updateprofilesetting" className="ry_instr_menu">
                    <i className="fal fa-cogs" /> Profile Settings
                    {showMenuHeader(forpage, 'updateprofilesetting')}
                </Link>
                <Link to="/instructor/page/payoutsetting" className="ry_instr_menu">
                    <i className="fal fa-credit-card" /> Payout Settings
                    {showMenuHeader(forpage, 'payoutsetting')}
                </Link>
                <Link to="/instructor/page/profilesetting" className="ry_instr_menu">
                    <i className="fal fa-user" /> Profile Overview
                    {showMenuHeader(forpage, 'profilesetting')}
                </Link>
                <Link to="/instructor/page/help" className="ry_instr_menu">
                    <i className="fal fa-info-circle" /> Help
                    {showMenuHeader(forpage, 'help')}
                </Link>
                <anew
                    className="ry_instr_menu"
                    onClick={() => {
                        setShowAlert(true);
                    }}
                >
                    <i className="fal fa-sign-out" /> Logout
                </anew>
            </div>
        </>
    );
}

export default ProfileMenu;
