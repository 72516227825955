import axios from 'axios';
import { BASEAPIURL } from '../config';

const DataService = axios.create({
    baseURL: BASEAPIURL,
    headers: { 'Content-Type': 'application/json' },
});

DataService.interceptors.request.use(
    (config) => {
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default DataService;
