import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { IMGBASEAPIURL } from '../../config';

function PopularInstructors() {
    const sliderRef = useRef();
    const instructors = {
        autoplay: false,
        arrows: true,
        dots: false,
        slidesToShow: 4,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    return (
        <>
            <section className="appie-features-area instructors">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row appie-showcase-slider">
                                <div className="col-lg-12">
                                    <h3 className="courseHead">Popular Instructors</h3>
                                    <Slider ref={sliderRef} {...instructors}>
                                        {/* <slide1 /> */}
                                        <div>
                                            <div className="appie-features-content">
                                                <div className="imgBox">
                                                    <img
                                                        src={`${IMGBASEAPIURL}institute/instructor/img5.jpg`}
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="contentBox">
                                                    <h6>
                                                        <a href="/#">Frank Gordon</a>
                                                    </h6>
                                                    <p>Medical Doctor</p>
                                                    <div className="ratingBox">
                                                        <i className="fas fa-star" />
                                                        <i className="fas fa-star" />
                                                        <i className="fas fa-star" />
                                                        <i className="fas fa-star-half-alt" />
                                                        <i className="far fa-star" />
                                                        <b>3.5/5.0</b>
                                                    </div>
                                                    <div className="linkBox">
                                                        <a href="/#">10 Courses</a>
                                                        <a href="/#">5 Webinars</a>
                                                        <a href="/#">5 Blogs</a>
                                                        <a href="/#">15 Free Life Tips</a>
                                                    </div>
                                                    <div className="tagsBox">
                                                        <b>4,579 Students</b>
                                                        <div className="shareBox">
                                                            <a href="/#" className="mr-10">
                                                                <i className="far fa-heart" />
                                                            </a>
                                                            <a href="/#" className="text-dark">
                                                                <i className="far fa-share-alt" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <slide2 /> */}
                                        <div>
                                            <div className="appie-features-content">
                                                <div className="imgBox">
                                                    <img
                                                        src={`${IMGBASEAPIURL}institute/instructor/img6.jpg`}
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="contentBox">
                                                    <h6>
                                                        <a href="/#">Anthony Wells</a>
                                                    </h6>
                                                    <p>Medical Doctor</p>
                                                    <div className="ratingBox">
                                                        <i className="fas fa-star" />
                                                        <i className="fas fa-star" />
                                                        <i className="fas fa-star" />
                                                        <i className="fas fa-star-half-alt" />
                                                        <i className="far fa-star" />
                                                        <b>3.5/5.0</b>
                                                    </div>
                                                    {/* <div className="linkBox">
                                                        <a href="#">10 Courses</a>
                                                        <a href="#">5 Webinars</a>
                                                        <a href="#">5 Blogs</a>
                                                        <a href="#">15 Free Life Tips</a>
                                                    </div> */}
                                                    <div className="tagsBox">
                                                        <b>4,579 Students</b>
                                                        <div className="shareBox">
                                                            <a href="/#" className="mr-10">
                                                                <i className="far fa-heart" />
                                                            </a>
                                                            <a href="/#" className="text-dark">
                                                                <i className="far fa-share-alt" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <slide3 /> */}
                                        <div>
                                            <div className="appie-features-content">
                                                <div className="imgBox">
                                                    <img
                                                        src={`${IMGBASEAPIURL}institute/instructor/img7.jpg`}
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="contentBox">
                                                    <h6>{/* <a href="#">Carmen Fox</a> */}</h6>
                                                    <p>Medical Doctor</p>
                                                    <div className="ratingBox">
                                                        <i className="fas fa-star" />
                                                        <i className="fas fa-star" />
                                                        <i className="fas fa-star" />
                                                        <i className="fas fa-star-half-alt" />
                                                        <i className="far fa-star" />
                                                        <b>3.5/5.0</b>
                                                    </div>
                                                    <div className="linkBox">
                                                        {/* <a href="#">10 Courses</a>
                                                        <a href="#">5 Webinars</a>
                                                        <a href="#">5 Blogs</a>
                                                        <a href="#">15 Free Life Tips</a> */}
                                                    </div>
                                                    <div className="tagsBox">
                                                        <b>4,579 Students</b>
                                                        <div className="shareBox">
                                                            <a href="/#" className="mr-10">
                                                                <i className="far fa-heart" />
                                                            </a>
                                                            <a href="/#" className="text-dark">
                                                                <i className="far fa-share-alt" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default PopularInstructors;
