import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    // IMGBASEAPIURL,
    BASEAPIURL,
    showRatingStar,
    showfulldatetime,
    // DisplayNoneIfZero,
    // ShowNumberWithComma,
    // DisplayNoneIfZero,
} from '../../../../config';
// import { CheckFavoriteStd } from '../../Students/functions';

const PrimaryIndex = '_id';
let SortBy = 'latest';
let Keyword = '';

function ProfileMenu({ forpage }) {
    // for the adding questiona and Answers
    const [AllQuestions, setAllQuestions] = useState([]);
    const [dataAnswer, setdataAnswer] = useState([]);
    // const [dataCourse, setdataCourse] = useState([]);
    const [AnswerData, setAnswerData] = useState({
        aid: 0,
        questionId: 0,
        TheCourseID: 0,
        givenByID: localStorage.getItem('tid'),
        givenByName: localStorage.getItem('username'),
        givenByType: 'instructor',
        answer: '',
    });

    const handleAnswerData = (name, value) => {
        setAnswerData({ ...AnswerData, [name]: value });
    };

    async function FetchInstructorCourseQuestions() {
        const forTid = {
            tid: localStorage.getItem('tid'),
            SortBy,
            Keyword,
        };
        try {
            const url = `${BASEAPIURL}/student/FetchInstructorCourseReviews`;
            const config = {
                headers: {
                    'content-type': 'application/json', // multipart/form-data
                    Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
                },
            };
            axios.post(url, forTid, config).then((response) => {
                if (response.data.data && response.data.data.length > 0) {
                    setAllQuestions(response.data.data);
                } else {
                    setAllQuestions([]);
                }
                if (response.data.dataAnswer && response.data.dataAnswer.length > 0) {
                    setdataAnswer(response.data.dataAnswer);
                } else {
                    setdataAnswer([]);
                }
                if (response.data.dataCourse && response.data.dataCourse.length > 0) {
                    // setdataCourse(response.data.dataCourse);
                }
            });
        } catch (err) {
            console.log(err);
        }
    }
    async function SubmitAnswerData(e) {
        e.preventDefault();
        if (AnswerData.answer !== '') {
            document.getElementById(`AnswerInp${AnswerData.questionId}`).value = '';
            try {
                const AnswerDataHere = AnswerData;
                setAnswerData({
                    aid: 0,
                    questionId: 0,
                    givenByID: localStorage.getItem('tid'),
                    givenByName: localStorage.getItem('username'),
                    givenByType: 'instructor',
                    answer: '',
                });
                const url = `${BASEAPIURL}/student/AddAnstoCourse`;
                const config = {
                    headers: {
                        'content-type': 'application/json',
                        Authorization: `Basic ${localStorage.getItem('loggedToken')}`,
                    },
                };
                axios.post(url, AnswerDataHere, config).then((response) => {
                    console.log(response);
                    FetchInstructorCourseQuestions();
                });
            } catch (err) {
                console.log(err);
            }
        } else {
            alert('Please Enter your Answer.');
        }
    }
    // for the adding questiona and Answers
    useEffect(() => {
        SortBy = 'latest';
        Keyword = '';
        FetchInstructorCourseQuestions();
    }, []);
    return (
        <>
            <div className="ry_instr_shadow p-4" forpage={forpage}>
                <h4 className="ry_instr_text">
                    My Reviews
                    <div
                        className="ry-pull-right"
                        style={{ width: 'auto', borderRadius: '10px', display: 'flex' }}
                    >
                        <input
                            name="keyword"
                            className="form-control mr-2"
                            placeholder="Search"
                            onChange={(e) => {
                                Keyword = e.target.value;
                                FetchInstructorCourseQuestions();
                            }}
                        />
                        <select
                            name="sortby"
                            className="form-control"
                            onChangeCapture={(e) => {
                                SortBy = e.target.value;
                                FetchInstructorCourseQuestions();
                            }}
                        >
                            <option value="">Sort By</option>
                            <option value="latest">Recently Added</option>
                            <option value="old">Previously Added</option>
                        </select>
                    </div>
                    <div style={{ clear: 'both' }}></div>
                </h4>
                <div className="col-md-12 mt-3">
                    {AllQuestions.length > 0 ? (
                        ''
                    ) : (
                        <div className="alert mt-5 mb-5 alert-info">
                            There is no Review from any Student.
                        </div>
                    )}
                    {AllQuestions.map((s) => (
                        <div className="row mb-3" key={s[PrimaryIndex]}>
                            <div className="col-3 col-md-1 p-0">
                                <img
                                    src={`${BASEAPIURL}/student/profileimage/${s.studentId}`}
                                    alt="RJ"
                                    style={{
                                        width: '70px',
                                        height: '70px',
                                        objectFit: 'cover',
                                        borderRadius: '50%',
                                    }}
                                />
                            </div>
                            <div className="col-9  col-md-11">
                                <h6>
                                    {s.studentName} {showRatingStar(s.rating)}
                                </h6>
                                {showfulldatetime(s.createdAt)}
                                &nbsp;&nbsp;Course: {s.sessionIndex}
                                <div>{s.comment}</div>
                                {dataAnswer.length > 0 ? (
                                    <h6 className="mt-3 mb-2 text-success">Answers</h6>
                                ) : (
                                    ''
                                )}
                                <form
                                    className="row mt-3 d-none"
                                    method="POST"
                                    onSubmit={(e) => {
                                        SubmitAnswerData(e);
                                    }}
                                >
                                    <div className="col-md-9">
                                        <input
                                            name="question"
                                            type="text"
                                            className="form-control"
                                            required
                                            id={`AnswerInp${s[PrimaryIndex]}`}
                                            defaultValue=""
                                            onClick={() => {
                                                AnswerData.TheCourseID = s.TheCourseID;
                                                handleAnswerData('questionId', s[PrimaryIndex]);
                                            }}
                                            onChange={(e) => {
                                                handleAnswerData('answer', e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <button type="submit" className="btn btn-success">
                                            Add Answer
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <hr className="col-12" />
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default ProfileMenu;
