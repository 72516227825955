import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Error from './components/Error';

// Redirect URL Here
import QuestionBankRedirect from './components/redirectURL/questionBanks';
import BlogRed from './components/redirectURL/BlogRed';
import TermAndConditions from './components/redirectURL/termandconditions';
import PrivacyPolicy from './components/redirectURL/privacyPolicy';
import RefundsAndCancellations from './components/redirectURL/refundsAndCancellations';
import CourseDetailQBRed from './components/redirectURL/CourseDetailQBRed';
import ExamCourses from './components/redirectURL/examCourses';
import SkillCourses from './components/redirectURL/SkillCourses';
import CategoryCourses from './components/redirectURL/CategoryCourses';
// Redirect URL Here

import Loader from './components/Helper/Loader';
import ScrollToTop from './components/Helper/ScrollToTop';
import HomeMain from './components/Home';
import InstituteHome from './components/Institute';
import Calendar from './components/Institute/Calendar';
import CategorySingle from './components/Institute/CategorySingle';
import AllCourses from './components/Learn/AllCourses';
import AllQuestionBanks from './components/Learn/AllQuestionBanks';
import AllWebinars from './components/Institute/AllWebinars';
import AllVideosCourses from './components/Institute/AllVideosCourses';
import AllQuestionsBanks from './components/Institute/AllQuestionsBanks';
// import CourseDetail from './components/Institute/CourseDetail';
import CourseDetailNew from './components/Learn/CourseDetail';
import CourseDetailQBNew from './components/Learn/CourseDetailQB';
import CourseStudent from './components/Learn/CourseStudent';
import CourseStudentQB from './components/Learn/CourseStudentQB';
import WebinarDetail from './components/Institute/WebinarDetail';
import VideoCourseDetail from './components/Institute/VideoCourseDetail';
import QBCourseDetail from './components/Institute/QBCourseDetail';
import QBCourseDetailPlay from './components/Institute/QBCourseDetailPlay';
import VideoCoursePlay from './components/Institute/VideoCoursePlay';
import InstructorsList from './components/Institute/InstructorsList';
import CareerCounseling from './components/Institute/CareerCounseling';
import CounselingTeamList from './components/Institute/CounselingTeamList';
import AboutUs from './components/Institute/AboutUs';

import AdvisoryBoardList from './components/Institute/AdvisoryBoardList';
import CorporateLearning from './components/Institute/CorporateLearning';
import Blog from './components/Institute/Blog';
import BlogSingle from './components/Institute/Blog/BlogSingle';
import Gallery from './components/Institute/Galleries';
import ContactUs from './components/Institute/ContactUs';
import StudentDeleteAccount from './components/Students/deleteAccount';
import FreeLifeTips2 from './components/Learn/FreeLifeTips';
import FreeLifeTips from './components/Learn/FreeLifeTipsNew';

import Information from './components/Institute/Information';
import Faq from './components/Institute/Faq';
import LearnHome from './components/Learn';

// Instructor Panel
import Instructorprofile from './components/Instructor/profile';
import Instructor from './components/Instructor';
import InstructorRegister from './components/Instructor/pages/Register';
import CreateBlog from './components/Instructor/pages/createblog';
import CreateFreeTip from './components/Instructor/pages/CreateFreeTip';
import CreateWebinar from './components/Instructor/pages/createwebinar';
import CreateCourse from './components/Instructor/pages/createcourse';
import CreateQB from './components/Instructor/pages/createQB';
import InstructorDashboard from './components/Instructor/pages/Dashboard';

// import InstructorLogin from './components/Instructor/pages/Login';
import BookaClass from './components/Institute/pages/bookaclass';
import RequestasTeam from './components/Institute/pages/bookteamlearn';
import CareerCounselling from './components/Institute/pages/careercounselling';
// Instructor Panel

// administrator Login
import AdminLogin from './components/administrator/pages/Login';
import AdminDashboard from './components/administrator/pages/Dashboard';
// administrator Login

// Student Panel
import UserRegister from './components/Students/pages/Register';
// import UserLogin from './components/Students/pages/Login';
import StudentDashboard from './components/Students/pages/Dashboard';

// CheckOut

import Ordersummary from './components/Checktout/OrderSummary';
// import MakePayment from './components/Checktout/MakePayment';
import GiftCourse from './components/Checktout/GiftCourse';
import Invoice from './components/Invoice/Invoice';
import AdminLayout from './components/layout/admin';
import ListOrders from './components/administrator/pages/Dashboard/orders/listing';
import ViewOrder from './components/administrator/pages/Dashboard/orders/view';

function RoutesHere() {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 400);
    });
    return (
        <>
            {loading && (
                <div className={`appie-loader ${loading ? 'active' : ''}`}>
                    <Loader />
                </div>
            )}
            <div className={`appie-visible ${loading === false ? 'active' : ''}`}>
                {/* <Router basename="/docsta/learn"> */}
                <Router>
                    <ScrollToTop>
                        <Routes>
                            <Route exact path="/" element={<LearnHome />} />

                            <Route exact path="/invoice" element={<Invoice />} />
                            <Route exact path="/check" element={<HomeMain />} />
                            <Route exact path="/institute-old" element={<InstituteHome />} />
                            <Route exact path="/calendar" element={<Calendar />} />
                            <Route exact path="/category-single" element={<CategorySingle />} />
                            {/*
                            <Route
                                exact
                                path="/category-:CatTitle-:CatID"
                                element={<CategoryDetail}
                            /> 
                             */}
                            <Route exact path="/courses" element={<AllCourses />} />
                            <Route exact path="/questionBanks" element={<QuestionBankRedirect />} />
                            <Route exact path="/question-bank" element={<AllQuestionBanks />} />
                            <Route exact path="/courses/:MainCatID" element={<AllCourses />} />
                            <Route exact path="/video-courses" element={<AllVideosCourses />} />
                            <Route exact path="/question-banks" element={<AllQuestionsBanks />} />
                            <Route exact path="/webinars" element={<AllWebinars />} />
                            <Route exact path="/category/:slug" element={<CategoryCourses />} />
                            <Route exact path="/courses-:TypeofCourse" element={<AllCourses />} />
                            <Route exact path="/course-:CourseSlug" element={<CourseDetailNew />} />
                            <Route exact path="/course/:CourseSlug" element={<CourseDetailNew />} />
                            <Route
                                exact
                                path="/instructor/profile/:Slug"
                                element={<Instructorprofile />}
                            />
                            <Route
                                exact
                                path="/question-bank/:CourseSlug"
                                element={<CourseDetailQBNew />}
                            />
                            <Route
                                exact
                                path="/questionBank/:CourseSlug"
                                element={<CourseDetailQBRed />}
                            />
                            <Route
                                exact
                                path="/courseStudent/:CourseSlug"
                                element={<CourseStudent />}
                            />
                            <Route
                                exact
                                path="/courseQBStudent/:CourseSlug"
                                element={<CourseStudentQB />}
                            />
                            <Route exact path="/webinar/:CourseSlug" element={<WebinarDetail />} />
                            <Route
                                exact
                                path="/videocourse/:CourseSlug"
                                element={<VideoCourseDetail />}
                            />
                            <Route
                                exact
                                path="/videocourse/play/:CourseSlug"
                                element={<VideoCoursePlay />}
                            />
                            <Route
                                exact
                                path="/questionbank/:CourseSlug"
                                element={<QBCourseDetail />}
                            />
                            <Route
                                exact
                                path="/questionbank/start/:CourseSlug"
                                element={<QBCourseDetailPlay />}
                            />
                            {/* <Route
                                exact
                                path="/course-:CourseTitle-:CourseID"
                                element={<CourseDetail}
                            /> */}
                            {/* <Route exact path="/course-:CourseID" element={<CourseDetail/>} /> */}
                            <Route exact path="/instructors-list" element={<InstructorsList />} />
                            <Route exact path="/career-counseling" element={<CareerCounseling />} />
                            <Route exact path="/counseling-team" element={<CounselingTeamList />} />
                            <Route exact path="/about-us" element={<AboutUs />} />
                            {/* <Route
                                path="/about-us"
                                element={<
                                    <React.Suspense fallback={<>...</>}>
                                        <AboutUs />
                                    </React.Suspense>
                                }
                            ></Route> */}
                            <Route exact path="/advisory-board" element={<AdvisoryBoardList />} />
                            <Route exact path="/mentors" element={<AdvisoryBoardList />} />
                            <Route
                                exact
                                path="/corporate-learning"
                                element={<CorporateLearning />}
                            />
                            <Route exact path="/blogs" element={<BlogRed />} />
                            <Route exact path="/blog" element={<Blog />} />
                            <Route exact path="/blogs/:CatID" element={<Blog />} />
                            <Route exact path="/blog/:BlogID" element={<BlogSingle />} />
                            <Route exact path="/gallery" element={<Gallery />} />
                            <Route exact path="/contact-us" element={<ContactUs />} />
                            <Route
                                exact
                                path="/user/delete-my-account"
                                element={<StudentDeleteAccount />}
                            />
                            <Route exact path="/free-life-tips" element={<FreeLifeTips />} />
                            <Route exact path="/free-life-tips-old" element={<FreeLifeTips2 />} />
                            <Route exact path="/free-audio-tips" element={<FreeLifeTips />} />

                            <Route exact path="/information/:InfoTitle" element={<Information />} />
                            <Route
                                exact
                                path="/terms-and-conditions"
                                element={<TermAndConditions />}
                            />
                            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
                            <Route
                                exact
                                path="/refunds-and-cancellations"
                                element={<RefundsAndCancellations />}
                            />
                            <Route exact path="/skill-courses" element={<SkillCourses />} />
                            <Route exact path="/exam-courses" element={<ExamCourses />} />
                            <Route exact path="/faq" element={<Faq />} />
                            <Route exact path="/bookaclass" element={<BookaClass />} />
                            <Route exact path="/request-as-team" element={<RequestasTeam />} />
                            <Route
                                exact
                                path="/career-counselling"
                                element={<CareerCounselling />}
                            />
                            <Route exact path="/learn" element={<LearnHome />} />
                            <Route exact path="/instructor" element={<Instructor />} />
                            <Route exact path="/instructor" element={<Instructor />} />
                            <Route exact path="/create-webinar" element={<CreateWebinar />} />
                            <Route exact path="/create-course" element={<CreateCourse />} />
                            <Route exact path="/create-qb" element={<CreateQB />} />
                            <Route exact path="/create-blog" element={<CreateBlog />} />
                            <Route exact path="/create-freetip" element={<CreateFreeTip />} />
                            <Route
                                exact
                                path="/instructor-register"
                                element={<InstructorRegister />}
                            />
                            <Route exact path="/user/register" element={<UserRegister />} />
                            <Route exact path="/checkout/cart" element={<Ordersummary />} />
                            {/* <Route exact path="/checkout/payment" element={<MakePayment />} /> */}
                            <Route exact path="/checkout/giftCourse" element={<GiftCourse />} />
                            <Route
                                exact
                                path="/user/register/:regToken"
                                element={<UserRegister />}
                            />
                            <Route exact path="/user/login" element={<StudentDashboard />} />
                            <Route
                                exact
                                path="/instructor-login"
                                element={<InstructorDashboard />}
                            />
                            <Route exact path="/admin-login" element={<AdminLogin />} />
                            <Route exact path="/user/dashboard" element={<StudentDashboard />} />
                            <Route
                                exact
                                path="/user/page/:PageName"
                                element={<StudentDashboard />}
                            />
                            <Route
                                exact
                                path="/instructor-dashboard"
                                element={<InstructorDashboard />}
                            />
                            <Route
                                exact
                                path="/instructor-dashboard-:PageName"
                                element={<InstructorDashboard />}
                            />
                            <Route
                                exact
                                path="/instructor/page/:PageName"
                                element={<InstructorDashboard />}
                            />
                            <Route exact path="/admin-dashboard" element={<AdminDashboard />} />
                            <Route
                                exact
                                path="/admin/page/:PageName"
                                element={<AdminDashboard />}
                            />
                            <Route
                                exact
                                path="/admin/orders"
                                element={
                                    <AdminLayout>
                                        <ListOrders />
                                    </AdminLayout>
                                }
                            />
                            <Route
                                exact
                                path="/admin/orders/:id"
                                element={
                                    <AdminLayout>
                                        <ViewOrder />
                                    </AdminLayout>
                                }
                            />
                            <Route path="admin-dashboard-:PageName" element={<AdminDashboard />} />
                            <Route exact path="/error" element={<Error />} />
                            <Route path="*" element={<Error />} />
                            <Route path="institute" element={<Navigate to="/" />} />
                        </Routes>
                        {/* <Navigate from="/institute" to="/" />  <Route path="*" element={<Navigate to="/Error" />} /> */}
                    </ScrollToTop>
                </Router>
            </div>
        </>
    );
}

export default RoutesHere;
