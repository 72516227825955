import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASEAPIURL, UPLOADNODEURL } from '../../../../config';
import Loader from '../../../Helper/Loader';

function Resource2Courses({ SessionResources, setSessionResources, SessionIndex }) {
    // for for Multiple Video List
    const [Action, setAction] = useState(1);
    const [VideosList, setVideosList] = useState(SessionResources);

    function nextResourceIndex() {
        // let t = Number(VideosList.length); t = VideosList[t - 1] ? VideosList[t - 1].index : 0;
        let maxIndex = 1;
        VideosList.forEach((item) => {
            if (item.index > maxIndex) {
                maxIndex = item.index;
            }
        });
        return Number(maxIndex) + 1;
    }
    const handleAddVideosList = (index) => {
        // ExpensesTypeSelected.push(1);
        setVideosList((temp) => [
            ...temp,
            {
                index,
                status: 1,
                url: '',
                sessionIndex: SessionIndex,
            },
        ]);
    };
    const handleEditVideosList = (changeVidId, ind, val) => {
        VideosList[changeVidId][ind] = val;
    };
    const handleRemoveVideosList = (ind) => {
        // if (ind !== 1) { }
        setVideosList(VideosList.filter((item) => item.index !== ind));
        // setSessionResources(VideosList.filter((item) => item.index !== ind));
        setAction(Action + 1);
    };
    const RefreshVideosList = () => {
        const temp = 999999;
        handleAddVideosList(temp);
        handleRemoveVideosList(temp);
        setSessionResources(VideosList);
    };
    // for Multiple Video List
    useEffect(() => {
        console.log('resources');
    }, []);
    // for file uploade will be here
    async function uploadFile(file, fileName, index) {
        // e.preventDefault();
        const formData = new FormData();
        formData.append('file', file);
        formData.append('fileName', fileName);
        const url = `${BASEAPIURL}/admin/instructure/uploadcv`;
        try {
            const res = await axios.post(url, formData);
            setVideosList((temp) => [
                ...temp,
                {
                    index,
                    status: 1,
                    name: fileName,
                    url: res.data.filename,
                    sessionIndex: SessionIndex,
                },
            ]);
            setSessionResources((temp) => [
                ...temp,
                {
                    index,
                    status: 1,
                    name: fileName,
                    url: res.data.filename,
                    sessionIndex: SessionIndex,
                },
            ]);
            setAction(Action + 1);
        } catch (ex) {
            console.log(ex);
        }
    }
    const saveFile = (e) => {
        const t = nextResourceIndex();
        // handleAddVideosList();
        setTimeout(() => {
            if (e.target.files && e.target.files.length > 0) {
                uploadFile(e.target.files[0], e.target.files[0].name, Number(t) + 1);
            }
        }, 500);
    };
    // for file uploade will be here

    return (
        <div style={{ minWidth: '300px', marginTop: '60px' }}>
            {Action === 0 ? (
                <Loader />
            ) : (
                <div>
                    {Action &&
                        VideosList.map((s, changeVidId) => (
                            <div className="form-group d-block" key={`${SessionIndex}_${s.index}`}>
                                {Number(s.sessionIndex) === Number(SessionIndex) &&
                                s.status !== 2 ? (
                                    <>
                                        <label className="d-block">
                                            Resources Title
                                            <div className="float-right">
                                                <a
                                                    target="_BLANK"
                                                    rel="noopener noreferrer"
                                                    href={`${UPLOADNODEURL}${s.url}`}
                                                >
                                                    <i className="fa fa-eye"></i>
                                                </a>
                                                &nbsp;&nbsp;
                                                <a
                                                    href="/"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        // eslint-disable-next-line no-restricted-globals
                                                        const temp = confirm(`are you sure to delete ${s.name}`) === true; 
                                                        if (temp) {
                                                            setAction(Action + 1);
                                                            handleEditVideosList(
                                                                changeVidId,
                                                                'status',
                                                                2
                                                            );
                                                            RefreshVideosList();
                                                        }
                                                        // eslint-enable-next-line no-restricted-globals
                                                        // handleRemoveVideosList(s.index);
                                                    }}
                                                >
                                                    <i className="fa fa-trash text-danger"></i>
                                                </a>
                                            </div>
                                            <div className="clearBoth"></div>
                                        </label>
                                        <input
                                            type="url"
                                            className="form-control"
                                            name="name"
                                            value={s.name}
                                            placeholder="Enter URL i.e. https://www.youtube.com/watch?v=M5hJSZsOPkA"
                                            onChange={(e) => {
                                                handleEditVideosList(
                                                    changeVidId,
                                                    'name',
                                                    e.target.value
                                                );
                                                RefreshVideosList();
                                            }}
                                        />
                                    </>
                                ) : (
                                    ''
                                )}
                            </div>
                        ))}
                    <div
                        className="text-purple"
                        style={{
                            border: '1px solid #f5f5f5',
                            padding: '10px',
                        }}
                    >
                        {VideosList.length > 0
                            ? 'Uploads Resources file for the Session i.e. PDF, Docs or Image file.'
                            : 'Uploads Resources file for the Session i.e. PDF, Docs or Image file.'}
                        <br />
                        <input
                            type="file"
                            name="cvfile"
                            className="ryfile mt-3"
                            onChange={(e) => {
                                saveFile(e);
                                setAction(0);
                            }}
                            style={{
                                width: 'max-content',
                                margin: '0px auto',
                                display: 'block',
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default Resource2Courses;
