import React, { useState, useEffect } from 'react';
import UploadChunk from '../../pages/Video2R2/uploadChunk';
import VideoPlayer from '../../../videoPlayer/player';
import { BASEAPIURL } from '../../../../config';
import axios from 'axios';

const min = 10000;
const max = 99999;

function Video2Courses({
    SessionVideos,
    setSessionVideos,
    SessionIndex,
    SetUploadVideo2Courses,
    handleForm,
}) {
    const [Action, setAction] = useState(1);
    const [NewVideoID, setNewVideoID] = useState(1);
    const [t1, setT1] = useState(localStorage.getItem('tid'));
    const [t2, setT2] = useState(Math.floor(Math.random() * (max - min + 1)) + min);
    const [t3, setT3] = useState(Math.floor(Math.random() * (max - min + 1)) + min);

    function setVideoIDOnList(videoID) {
        setNewVideoID(videoID);
        let isSessionVideo = 0;
        SessionVideos.map((s, i) => {
            if (Number(s.sessionIndex) === Number(SessionIndex)) {
                isSessionVideo = 1;
                handleEditVideosList(i, 'url', videoID);
            }
            return '';
        });
        if (isSessionVideo === 0) {
            let t = Number(SessionVideos.length);
            t = SessionVideos[t - 1] ? SessionVideos[t - 1].index : 0;
            setSessionVideos((temp) => [
                ...temp,
                {
                    t: t + 1,
                    url: videoID,
                    sessionIndex: SessionIndex,
                },
            ]);
            // handleForm('SessionVideos', SessionVideos);
        }
    }
    // const PrimaryIndex = '_id';
    // for for Multiple Video List
    const handleAddVideosList = (index) => {
        // ExpensesTypeSelected.push(1);
        setSessionVideos((temp) => [
            ...temp,
            {
                index,
                url: NewVideoID,
                sessionIndex: SessionIndex,
            },
        ]);
        // handleForm('SessionVideos', SessionVideos);
    };
    const handleEditVideosList = (changeVidId, ind, val) => {
        // eslint-disable-next-line no-param-reassign
        SessionVideos[changeVidId][ind] = val;
    };
    const handleRemoveVideosList = (ind) => {
        if (ind !== 1) {
            setSessionVideos(SessionVideos.filter((item) => item.index !== ind));
        }
    };
    const RefreshVideosList = () => {
        const temp = 999999;
        handleAddVideosList(temp);
        handleRemoveVideosList(temp);
        setSessionVideos(SessionVideos);
        handleForm('SessionVideos', SessionVideos);
    };
    // for Multiple Video List
    useEffect(() => {
        setT1(localStorage.getItem('tid'));
        setT2(Math.floor(Math.random() * (max - min + 1)) + min);
        setT3(Math.floor(Math.random() * (max - min + 1)) + min);
        setAction(2);
    }, []);

    async function DeleteVideo(fileid) {
        // alert(fileid);
        setAction(Action + 1);
        const filteredArray = SessionVideos.filter((video) => video._id !== fileid);
        const elementById = document.getElementById(`Video2Course${fileid}`);
        if (elementById) {
            elementById.remove();
        }
        setSessionVideos(filteredArray);
        try {
            const url = `${BASEAPIURL}/mainCourse/DeleteVideo`;
            const config = {
                headers: {
                    'content-type': 'application/json', // multipart/form-data
                },
            };
            axios.post(url, { fileid }, config).then((response) => {
                console.log(response);
            });
        } catch (err) {
            console.log(err);
            alert('Something went wrong, Please try again later.');
        }
    }

    return (
        <div style={{ minWidth: '300px' }}>
            {NewVideoID !== 1 ? (
                <>
                    <VideoPlayer
                        DefaultVideoTitle="Preview"
                        DefaultVideoId={NewVideoID}
                        VIDPlaying={true}
                        responsive={true}
                    />
                </>
            ) : (
                ''
            )}
            <div className="d-none" style={{ textAlign: 'right' }}>
                <span
                    className="btn btn-sm text-white btn-danger"
                    onClick={() => {
                        RefreshVideosList();
                        // setSessionVideos(SessionVideos);
                        SetUploadVideo2Courses('');
                        // handleForm('SessionVideos', SessionVideos);
                    }}
                >
                    X
                </span>
            </div>

            {NewVideoID && Action ? (
                <>
                    {Action &&
                        SessionVideos.map((s, changeVidId) => (
                            // .sort((a, b) => a.index - b.index).
                            <div className="form-group" key={`${SessionIndex}_${s._id}`}>
                                {Number(s.sessionIndex) === Number(SessionIndex) ? (
                                    <div id={`Video2Course${s._id}`}>
                                        <div>
                                            {/* {s._id} */}
                                            Video ID: <b>{s.url}</b>
                                            <span
                                                onClick={(e) => {
                                                    /* eslint-disable no-restricted-globals */
                                                    if (
                                                        confirm(
                                                            'Are you sure to Delete This Video ID the Session?'
                                                        )
                                                    ) {
                                                        DeleteVideo(s._id);
                                                    }
                                                    /* eslint-enable no-restricted-globals */
                                                }}
                                            >
                                                <i className="fa fa-trash text-danger"></i>
                                            </span>
                                        </div>
                                        {NewVideoID === 1 ? (
                                            <>
                                                <VideoPlayer
                                                    DefaultVideoTitle="Preview"
                                                    DefaultVideoId={s.url}
                                                    VIDPlaying={true}
                                                    responsive={true}
                                                />
                                            </>
                                        ) : (
                                            ''
                                        )}
                                        <input
                                            type="checkbox"
                                            name="name"
                                            checked={Number(s.isFree) === 1}
                                            placeholder="Enter URL i.e. https://www.youtube.com/watch?v=M5hJSZsOPkA"
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    handleEditVideosList(changeVidId, 'isFree', 1);
                                                } else {
                                                    handleEditVideosList(changeVidId, 'isFree', 0);
                                                }
                                                RefreshVideosList();
                                            }}
                                        />
                                        Show Video in Preview Course as well.
                                        <h6 className="text-success mt-3 mb-0">
                                            View Counter <b>{s.viewCount ? s.viewCount : 'NA'}</b>
                                        </h6>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        ))}
                </>
            ) : (
                ''
            )}
            <UploadChunk t1={t1} t2={t2} t3={t3} setVideoIDOnList={setVideoIDOnList} />
        </div>
    );
}

export default Video2Courses;
